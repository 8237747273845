import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CiSearch } from "react-icons/ci";
import { Table, } from "react-bootstrap";
import { useEffect } from 'react';
import { IoAdd } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";


function DeviceRenew(props) {


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ marginLeft: "10%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Device Renew
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="d-flex">
          <span style={{ marginTop: '7px' }}>Renew plan:</span>
          <select class="form-select" aria-label="Default select example" style={{ width: '15%', marginLeft: '8px' }}>
            <option selected>Renew(1 Year)</option>
            <option value="1">Renew(2 Year)</option>
            <option value="2">Renew(6 Months)</option>
            <option value="3">Renew(3 Months)</option>
          </select>
          <span style={{ marginLeft: '10px', marginTop: '7px' }}>Account:</span>

          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search..." style={{ marginLeft: '8px', width: '20%' }} />
          <button type="button" class="btn btn-primary btn-sm" style={{ width: '40px' }}><CiSearch style={{ fontSize: '15px' }} /></button>

          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="IMEI" style={{ marginLeft: '8px', width: '20%' }} />

          
          <button type="button" class="btn btn-primary btn-sm" style={{ width: '40px' }}><IoAdd style={{ fontSize: '15px' }} /></button>
          <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '8%' }}> Search </button>
          <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '8%' }} > Reset </button>
        </div>
        <div className="table-responsive" style={{ marginTop: '20px' }}>
          <Table striped hover size="sm">
            <thead>
              <tr>
                <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                <th>Account</th>
                <th>IMEI</th>
                <th>Device Name</th>
                <th>Subscription Expiration</th>
                <th>Service Type</th>
                <th>Unit Price</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ marginLeft: '50px' }}>1</td>
                <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                <td>demo@gmail.com</td>
                <td>8767564356765</td>
                <td>Wagon R</td>
                <td>Expired</td>
                <td>Renew(10 Year)</td>
                <td>300</td>
                <td><RiDeleteBin6Line /></td>
              </tr>
            </tbody>
          </Table>
          <div class="d-flex">
            <span>Remarks:</span>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Remark" style={{marginLeft:'40px'}}/>
          </div>

        </div>
      </Modal.Body>
      <div>
      <Modal.Footer>
      <h6>Total: 0 coins</h6>
                <Button onClick={props.onHide} class="btn btn-secondary">Cancel</Button>
                <Button onClick={props.onHide}>Confirm</Button>

            </Modal.Footer>
      </div>

    </Modal>
  );
}



export default DeviceRenew;