import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';

const UserInterfaceSetting = (props) => {
  //declaration of the state variables
  const [canEdit, setCanEdit] = useState(false);
  //states for holding the form data
  const [icon, setIcon] = useState(props.deviceData?.device?.icon);
  const [mapIcon, setMapIcon] = useState(props.deviceData?.device?.map_icon);
  const [mapArrow, setMapArrow] = useState(props.deviceData?.device?.map_arrows);
  const [tailColor, setTailColor] = useState(props.deviceData?.device?.tail_color);
  const [tailPoints, setTailPoints] = useState(props.deviceData?.device?.tail_points);
  const [historyEnable, setHistoryEnable] = useState(props.deviceData?.device?.history?.enabled);


  const [plateNumber, setPlateNumber] = useState(props.deviceData?.device?.plate_number);
  const [deviceType, setDeviceType] = useState(props.deviceData?.device?.device);
  const [managerId, setManagerId] = useState(props.deviceData?.device?.manager_id[0]);
  const [vin, setVin] = useState(props.deviceData?.device?.vin)
  const [driver, setDriver] = useState('to do later');
  const [trailer, setTrailer] = useState('to to later');
  const [deviceModel, setDeviceModel] = useState('');


  return (
    <div>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title"></div>
      </Card.Header>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Form.Check
          type="checkbox"
          id={`default-checkbox`}
          label={`Edit`}
          onChange={() => { setCanEdit(!canEdit) }}
        />
      </div>
      <div class="p-2 flex-grow-1 bd-highlight">
        <span class="text-primary" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '' }}>Map Icon Configuration
          <hr style={{ marginTop: '8px', width: '160px' }}></hr>
          <hr style={{ marginTop: '-10px', width: '160px' }}></hr>

        </span>
      </div>

      <form lg="12" style={{ marginTop: '20px' }}>
        <div class="form-row d-flex">
          <div class="form-group col-md-4">
            <label for="inputEmail4" style={{ fontSize: '13px' }}>Icon :</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="icon"
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={icon}
              onChange={(e) => {
                setIcon(e.target.value);
                props.setPatchData({
                  ...props.patchData,
                  icon: e.target.value
                })
              }}
            />
          </div>

          <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
            <label for="inputPassword4" style={{ fontSize: '13px' }}>Map Icon : </label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="map icon"
              disabled={!canEdit ? true : false}
              value={mapIcon}
              onChange={(e) => {
                setMapIcon(e.target.value);
                props.setPatchData({
                  ...props.patchData,
                  map_icon: e.target.value
                })
              }}
              style={{ borderRadius: '5px' }}
            />
          </div>

          <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
            <label for="inputEmail4" style={{ fontSize: '13px' }}>Map Arrows:</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="device's trailer"
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={mapArrow}
              onChange={(e) => {
                setMapArrow(e.target.value);
                props.setPatchData({
                  ...props.patchData,
                  map_arrows: e.target.value
                })
              }}
            />
          </div>
        </div>

        <div class="form-row d-flex">
          <div class="form-group col-md-4">
            <label for="colorpicker" style={{ fontSize: '13px' }}>Tail Color : </label>
            <input type="color" class="form-control" id="colorpicker" placeholder="tail color"
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={tailColor}
              onChange={(e) => {
                setTailColor(e.target.value);
                props.setPatchData({
                  ...props.patchData,
                  tail_color: e.target.value
                })
              }}
            />
          </div>
          <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
            <label for="inputPassword4" style={{ fontSize: '13px' }}>Tail Points</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="device model"
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={tailPoints}
              onChange={(e) => setTailPoints(e.target.value)}
            />

          </div>

          <div class="form-group col-md-4" style={{ marginLeft: '8px' }} >
            <label for="inputEmail4" style={{ fontSize: '13px' }}>History Enabled:</label>
            <Form.Select aria-label="Default select example"
              disabled={!canEdit ? true : false}
              value={historyEnable}
              onChange={(e) => setHistoryEnable(e.target.value)}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Form.Select>
          </div>
        </div>

        {/* <div class="form-row d-flex">
            <div class="form-group col-md-4">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Manager Id</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="Manager Id"
                style={{ borderRadius: '5px' }}
                value={managerId}
                onChange={(e) => setManagerId(e.target.value)}
              />
            </div>
            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}> Vin Number:</label>
              <select class="form-select" aria-label="Default select example"
                style={{ width: '98%' }}

              >
                <option selected>Manager</option>
                <option value="calibration">Calibration</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>
            </div>

            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Fuel/100km:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

            </div>
          </div> */}

        <div class="form-row d-flex">
          <div class="form-group col-md-4">
            <label for="inputEmail4" style={{ fontSize: '13px' }}>Manager Id</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="manager id"
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={managerId}
              onChange={(e) => setManagerId(e.target.value)}
            />
          </div>
          <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
            <label for="inputPassword4" style={{ fontSize: '13px' }}> VIN</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="Vehicle identification no."
              disabled={!canEdit ? true : false}
              style={{ borderRadius: '5px' }}
              value={vin}
              onChange={(e) => {
                setVin(e.target.value);
                props.setPatchData({
                  ...props.patchData,
                  vin: e.target.value
                })
              }}
            />

          </div>

          <div class="form-group col-md-4" style={{ marginLeft: '8px' }} >
            <label for="inputEmail4" style={{ fontSize: '13px' }}> Device IMEI</label>
            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

          </div>
        </div>
        <div>
          <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">
              <span class="text-primary" style={{ marginTop: '35px', fontSize: '15px', }}>Install Information
                <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

              </span>
            </div>
          </div>

          <div class="form-row d-flex">
            <div class="form-group col-md-4">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Installation Time:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}> Install company:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

            </div>

            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Installer:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

            </div>
          </div>

          <div class="form-row d-flex">
            <div class="form-group col-md-4">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Install Address:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}> Install Position:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

            </div>

            <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Install picture:</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

            </div>
          </div>
        </div>
        <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>

          {canEdit ? (
            <button type="button" class="btn btn-primary"
              onClick={props.editDevice}
              // onClick={() => { console.log(props.patchData) }}
            >Save </button>
          ) : null}

          <button type="button" class="btn btn-secondary"
            style={{ marginLeft: '5px' }}
          >Cancel</button>

        </div>



      </form>
    </div>
  )
}

export default UserInterfaceSetting;
