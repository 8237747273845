import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, Pagination } from 'react-bootstrap';
import "./Style.css"


const ObjectSetting = (props) => {
  const [dfle, setDfle] = useState(props.addEventData?.dfle?.enabled);
  const [enableWeekDays, setEnableWeekDays] = useState(false);
  const [enableDayTime, setEnableDayTime] = useState(props.addEventData?.day_time?.enabled);
  const [eventWeekDays, setEventWeekDays] = useState([]);

  const [sundayFrom, setSundayFrom] = useState(props.addEventData?.day_time?.sun?.from);
  const [sundayTo, setSundayTo] = useState(props.addEventData?.day_time?.sun?.to);
  const [mondayFrom, setMondayFrom] = useState(props.addEventData?.day_time?.mon?.from);
  const [mondayTo, setMondayTo] = useState(props.addEventData?.day_time?.mon?.to);
  const [tuesdayFrom, setTuesdayFrom] = useState(props.addEventData?.day_time?.tue?.from)
  const [tuesdayTo, setTuesdayTo] = useState(props.addEventData?.day_time?.tue?.to)
  const [wednesdayFrom, setWednesdayFrom] = useState(props.addEventData?.day_time?.wed?.from)
  const [wednesdayTo, setWednesdayTo] = useState(props.addEventData?.day_time?.wed?.to);
  const [thursdayFrom, setThursdayFrom] = useState(props.addEventData?.day_time?.thu?.from)
  const [thursdayTo, setThursdayTo] = useState(props.addEventData?.day_time?.thu?.to)
  const [fridayFrom, setFridayFrom] = useState(props.addEventData?.day_time?.fri?.from)
  const [fridayTo, setFridayTo] = useState(props.addEventData?.day_time?.fri?.to)
  const [saturdayFrom, setSaturdayFrom] = useState(props.addEventData?.day_time?.sat?.from)
  const [saturdayTo, setSaturdayTo] = useState(props.addEventData?.day_time?.sat?.to)


  useEffect(() => {
    props.setAddEventData({
      ...props.addEventData,
      week_days: eventWeekDays
    })
  }, [eventWeekDays])

  useEffect(() => {

    if (props.addEventData?.week_days) {
      setEventWeekDays(props.addEventData?.week_days)
    } else {
      setEventWeekDays([false, false, false, false, false, false, false])
    }
  }, [])

  return (
    <div>
      <div>
        <div>

          <Card.Header className="d-flex justify-content-between">
            <div className="header-title"></div>
          </Card.Header>

          <div>
            <div>
              <div class="d-flex bd-highlight">
                <div class="p-2 flex-grow-1 bd-highlight">
                  <span class="" style={{ marginTop: '30px', fontSize: '15px', marginLeft: '5px' }}>Time
                    <hr style={{ marginTop: '8px', width: '100px', marginLeft: '8px' }}></hr>
                    <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '8px' }}></hr></span>
                </div>
              </div>
            </div>
            <form lg="12" style={{ marginTop: '10px', marginLeft: '12px' }}>
              <div class="row">
                <div class="col-6 col-md-4">Duration from last event in minutes</div>
                <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                  style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                  checked={props.addEventData?.dfle?.enabled}
                  onChange={() => {
                    props.setAddEventData({
                      ...props.addEventData,
                      dfle: {
                        ...props.addEventData.dfle,
                        enabled: !props.addEventData?.dfle?.enabled
                      }
                    })
                  }}
                />
                </div>
                <div class="col-6 col-md-4"><input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" style={{ marginLeft: '-80%' }} /></div>
              </div>
              <div class="row" style={{ marginTop: '10px' }}>
                <div class="col-6 col-md-4">Week Day</div>
                <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                  style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                  checked={enableWeekDays}
                  onChange={() => setEnableWeekDays(!enableWeekDays)}
                />
                </div>
              </div>
              <>
                <div class="row align-items-start" style={{ marginTop: "10px" }}>
                  <div class="col" style={{}}>
                    Week Days
                  </div>
                  <div class="col d-flex">
                    <span style={{}}>M</span>
                    {" "}<span style={{ marginLeft: '14px' }}>T</span>
                    {" "}<span style={{ marginLeft: '14px' }}>W</span>
                    {" "}<span style={{ marginLeft: '14px' }}>T</span>
                    {" "}<span style={{ marginLeft: '14px' }}>F</span>
                    {" "}<span style={{ marginLeft: '14px' }}>S</span>
                    {" "}<span style={{ marginLeft: '14px' }}>S</span>

                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: "10px" }}>
                  <div class="col" style={{}}>
                    Checks
                  </div>
                  <div class="col d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      checked={eventWeekDays && eventWeekDays[0]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[0] = !updatedWeekDays[0];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    />
                    {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[1]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[1] = !updatedWeekDays[1];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[2]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[2] = !updatedWeekDays[2];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[3]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[3] = !updatedWeekDays[3];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[4]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[4] = !updatedWeekDays[4];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[5]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[5] = !updatedWeekDays[5];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      style={{ marginLeft: '5px' }}
                      checked={eventWeekDays && eventWeekDays[6]}
                      onChange={() => {
                        const updatedWeekDays = [...eventWeekDays];
                        updatedWeekDays[6] = !updatedWeekDays[6];
                        setEventWeekDays(updatedWeekDays);
                      }}
                    /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span> */}
                  </div>
                </div>
              </>
              <div class="row" style={{ marginTop: '10px' }}>
                <div class="col-6 col-md-4">Day Time</div>
                <div class="col-6 col-md-4">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                    checked={enableDayTime}
                    onChange={() => {
                      setEnableDayTime(!enableDayTime)
                      props.setAddEventData({
                        ...props.addEventData,
                        day_time: {
                          ...props.addEventData?.day_time,
                          enable: !enableDayTime
                        }
                      })
                    }}
                  />
                </div>
                <div class="col-6 col-md-4"></div>
              </div>
              {enableDayTime ? (
                <>
                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Sunday</div>
                    <div class="col-6 col-md-3">
                      {/* <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} /> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" type="time" aria-label="Default select example" style={{ width: '65%', marginLeft: '-35%' }}
                        value={sundayFrom}
                        onChange={(e) => {
                          setSundayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              sun: {
                                ...props.addEventData?.day_time?.sun,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>
                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={sundayTo}
                        onChange={(e) => {
                          setSundayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              sun: {
                                ...props.addEventData?.day_time?.sun,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>

                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Monday</div>
                    <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={mondayFrom}
                        onChange={(e) => {
                          setMondayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              mon: {
                                ...props.addEventData?.day_time?.mon,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>


                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={mondayTo}
                        onChange={(e) => {
                          setMondayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              mon: {
                                ...props.addEventData?.day_time?.mon,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>

                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Tuesday</div>
                    <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={tuesdayFrom}
                        onChange={(e) => {
                          setTuesdayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              tue: {
                                ...props.addEventData?.day_time?.tue,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>


                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={tuesdayTo}
                        onChange={(e) => {
                          setTuesdayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              tue: {
                                ...props.addEventData?.day_time?.tue,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>
                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Wednesday</div>
                    <div class="col-6 col-md-3"> <input class="form-check-input" type="checkbox" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={wednesdayFrom}
                        onChange={(e) => {
                          setWednesdayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              wed: {
                                ...props.addEventData?.day_time?.wed,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>


                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={wednesdayTo}
                        onChange={(e) => {
                          setWednesdayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              wed: {
                                ...props.addEventData?.day_time?.wed,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>
                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Thursday</div>
                    <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={thursdayFrom}
                        onChange={(e) => {
                          setThursdayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              thu: {
                                ...props.addEventData?.day_time?.thu,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>


                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={thursdayTo}
                        onChange={(e) => {
                          setThursdayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              thu: {
                                ...props.addEventData?.day_time?.thu,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>
                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Friday</div>
                    <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={fridayFrom}
                        onChange={(e) => {
                          setFridayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              fri: {
                                ...props.addEventData?.day_time?.fri,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>


                    </select> */}
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={fridayTo}
                        onChange={(e) => {
                          setFridayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              fri: {
                                ...props.addEventData?.day_time?.fri,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>

                    </select> */}


                    </div>

                  </div>
                  <div class="row" style={{ marginTop: '10px' }}>
                    <div class="col-6 col-md-3">Saturday</div>
                    <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-35%' }}
                        value={saturdayFrom}
                        onChange={(e) => {
                          setSaturdayFrom(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              sat: {
                                ...props.addEventData?.day_time?.sat,
                                from: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option> 
                      </select>
                      */}


                    </div>
                    <div class="col-6 col-md-3">
                      <input class="form-select" aria-label="Default select example" type="time" style={{ width: '65%', marginLeft: '-75%' }}
                        value={saturdayTo}
                        onChange={(e) => {
                          setSaturdayTo(e.target.value)
                          props.setAddEventData({
                            ...props.addEventData,
                            day_time: {
                              ...props.addEventData?.day_time,
                              sat: {
                                ...props.addEventData?.day_time?.sat,
                                to: e.target.value.toString()
                              }
                            }
                          })
                        }}
                      />
                      {/* <option selected>00:00</option>
                      <option value="1">00:15</option>
                      <option value="2">00:30</option>
                      <option value="3">00:45</option>
                      <option value="3">01:00</option>
                    </select> */}
                    </div>
                  </div>
                </>
              ) : null}
            </form>




          </div>


        </div>
      </div>
    </div >
  )
}

export default ObjectSetting;
