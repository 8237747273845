import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    generalData : {}
}

const trackingGeneralSlice = createSlice({
    name: "generalTracking",
    initialState,
    reducers: {
        setGeneralData : (state, action) => {
            state.generalData = action.payload
        }
    }
})

export default trackingGeneralSlice.reducer;
export const {setGeneralData} = trackingGeneralSlice.actions;
