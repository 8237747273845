import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination } from 'react-bootstrap';
import "./DeviceStyle.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";

const ObjectSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>


      {
        optionSelected === 'objects' ? (
          <div>
            <div>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title"></div>
              </Card.Header>
              <div>
                <div class="d-flex bd-highlight">


                </div>
              </div>

              <form lg="12" style={{ marginTop: '20px' }}>
                <div class="form-row d-flex">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Imei</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> ICCID</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      
                    >
                      <option selected>Manager</option>
                      <option value="calibration">Calibration</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>
                </div>
                <div class="form-row d-flex" >
                  <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>IMEI</label>
                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                      <option selected>Manager</option>
                      <option value="1">Super Admin</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Sim Number</label>
                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                      <option selected>Manager</option>
                      <option value="1">Super Admin</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>
                </div>

                <div class="form-row d-flex" >
                  <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Activated Time</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Sales Time</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>

                <div class="form-row d-flex" >
                  <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Name</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Group</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div class="form-row d-flex" >
                  <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Model</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Import time</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div class="form-row d-flex" >
                  <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Expiration Date</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Subscription Expiration</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div>
                <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary">Save</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div>



              </form>

            </div>
          </div>





        ) : optionSelected === 'groups' ? (
          <div>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">List of Groups</h4>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive mt-4">
                  <table id="basic-table" className="table table-striped mb-0" role="grid">
                    <thead>
                      <tr>
                        <th>Group Name</th>
                        <th>Objects</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>Group.name</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>Group Objects</h6>
                          </div>
                        </td>
                        <td>Description demo</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
            <Button data-bs-toggle="tooltip" title="Create New" className='m-2'><MdOutlineAddBox /> Create</Button>
            <Button data-bs-toggle="tooltip" title="Refresh" className='m-2'><BiRefresh /></Button>
            <Button data-bs-toggle="tooltip" title="Import" className='m-2'>Import</Button>
            <Button data-bs-toggle="tooltip" title="Export" className='m-2'>Export</Button>
            <Button data-bs-toggle="tooltip" title="Delete" className='m-2'>Delete</Button>
          </div>
        ) : optionSelected === 'drivers' ? (
          <div>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">List of Drivers</h4>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive mt-4">
                  <table id="basic-table" className="table table-striped mb-0" role="grid">
                    <thead>
                      <tr>
                        <th>Drivers Name</th>
                        <th>ID Number</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>Driver Name</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>dummy id</h6>
                          </div>
                        </td>
                        <div className="d-flex align-items-center">
                          <td>dummy description</td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
            <Button data-bs-toggle="tooltip" title="Create New" className='m-2'><MdOutlineAddBox /> Create</Button>
            <Button data-bs-toggle="tooltip" title="Refresh" className='m-2'><BiRefresh /></Button>
            <Button data-bs-toggle="tooltip" title="Import" className='m-2'>Import</Button>
            <Button data-bs-toggle="tooltip" title="Export" className='m-2'>Export</Button>
            <Button data-bs-toggle="tooltip" title="Delete" className='m-2'>Delete</Button>
          </div>
        ) : optionSelected === 'passengers' ? (
          <div>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">List of Passengers</h4>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive mt-4">
                  <table id="basic-table" className="table table-striped mb-0" role="grid">
                    <thead>
                      <tr>
                        <th>Drivers Name</th>
                        <th>ID Number</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>Driver Name</h6>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>dummy id</h6>
                          </div>
                        </td>
                        <div className="d-flex align-items-center">
                          <td>dummy description</td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
            <Button data-bs-toggle="tooltip" title="Create New" className='m-2'><MdOutlineAddBox /> Create</Button>
            <Button data-bs-toggle="tooltip" title="Refresh" className='m-2'><BiRefresh /></Button>
            <Button data-bs-toggle="tooltip" title="Import" className='m-2'>Import</Button>
            <Button data-bs-toggle="tooltip" title="Export" className='m-2'>Export</Button>
            <Button data-bs-toggle="tooltip" title="Delete" className='m-2'>Delete</Button>
          </div>
        ) : optionSelected === 'trailors' ? (
          <div>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">List of Trailors</h4>
                </div>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="table-responsive mt-4">
                  <table id="basic-table" className="table table-striped mb-0" role="grid">
                    <thead>
                      <tr>
                        <th>Drivers Name</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <h6>Driver Name</h6>
                          </div>
                        </td>
                        <div className="d-flex align-items-center">
                          <td>dummy description</td>
                        </div>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
            <Button data-bs-toggle="tooltip" title="Create New" className='m-2'><MdOutlineAddBox /> Create</Button>
            <Button data-bs-toggle="tooltip" title="Refresh" className='m-2'><BiRefresh /></Button>
            <Button data-bs-toggle="tooltip" title="Import" className='m-2'>Import</Button>
            <Button data-bs-toggle="tooltip" title="Export" className='m-2'>Export</Button>
            <Button data-bs-toggle="tooltip" title="Delete" className='m-2'>Delete</Button>
          </div>
        ) : (
          <h4>Unknown</h4>
        )
      }


      <div class="modal fade" id="modalLoginForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style={{ minWidth: '800px', marginLeft: '-20%' }}>
            <div class="modal-header text-start">
              <h6 class="modal-title w-100 font-weight-bold" style={{}}>Import Device</h6>
              <button type="button" class="close bg-primary" data-dismiss="modal" aria-label="Close" style={{ color: 'white', border: 'none' }}>
                <span aria-hidden="true">&times;</span>
              </button>

            </div>
            <div class="modal-body mx-4">
              <form>
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-2 col-form-label" style={{ marginTop: '15px', fontSize: '13px' }}>Device Model</label>
                  <div class="col-sm-10">
                    <select class="form-select" aria-label="Default select example" style={{ width: '77%', marginTop: '10px' }}>
                      <option selected style={{ marginTop: '15px', width: '26%', marginLeft: '', fontSize: '13px', }}>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <label for="inputPassword" class="col-sm-2 col-form-label" style={{ marginTop: '15px', fontSize: '13px' }}>Service type:</label>
                  <div class="col-sm-10">
                    <select class="form-select" aria-label="Default select example" style={{ width: '77%', marginTop: '10px' }}>
                      <option selected style={{ marginTop: '15px', width: '26%', marginLeft: '', fontSize: '13px' }}>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div style={{ fontSize: '13px', marginTop: '13px' }}>unit price: {" "}{" "}<span style={{ marginLeft: '8%', color: 'red', fontSize: '13px' }}>Select price</span></div>

                  <label for="inputPassword" class="col-sm-2 col-form-label" style={{ marginTop: '15px', fontSize: '13px' }}>To Account:</label>
                  <div class="col-sm-10">
                    <select class="form-select" aria-label="Default select example" style={{ width: '77%', marginTop: '10px' }}>
                      <option selected style={{ marginTop: '15px', width: '26%', marginLeft: '', fontSize: '13px' }}>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div style={{ fontSize: '13px' }}>Application: {" "}{" "}<span style={{ marginLeft: '8%', marginTop: '15px', fontSize: '13px' }}>
                    <i class="fa fa-car" aria-hidden="true" style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class="fa fa-bus" aria-hidden="true" style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-ambulance' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-bicycle' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-car-side ' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-shuttle-van' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-truck-pickup' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-truck-monster' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                    <i class='fas fa-shuttle-van' style={{ fontSize: '18px', marginTop: '15px' }}></i>{" "}
                  </span></div>

                  <div class="form-check" style={{ marginLeft: '19%', marginTop: '15px' }}>
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    <label class="form-check-label" for="flexCheckDefault" style={{ fontSize: '13px' }}>
                      Allow to be bound by app account
                    </label>
                  </div>

                  <label for="inputPassword" class="col-sm-2 col-form-label" style={{ marginTop: '10px', fontSize: '13px' }}>IMEI:</label>
                  <div class="col-sm-10" style={{ marginTop: '10px' }} >
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" placeholder="Enter imei" aria-label="Enter imei" aria-describedby="basic-addon2" />
                      <span class="input-group-text bg-primary " id="basic-addon2">Add</span>
                    </div>
                  </div>

                </div>
              </form>
              <button type="button" class="btn btn-primary " style={{ width: '20%', marginTop: '3%', marginBottom: '3%', }}>
                Confirm
              </button>
              <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" style={{ width: '20%', marginTop: '3%', marginBottom: '3%', marginLeft: '10px' }}>
                Cancel
              </button>{" "}
              <span>Total: 0 Mi Coins</span>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default ObjectSetting
