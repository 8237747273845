import React, { useEffect, useState } from 'react'
import { Table, Modal, Form, Pagination, Spinner } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import NewLoader from '../../Speedo/speedo-asset/Loader';


const Passenger = () => {

  //modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  //data
  const [passengersList, setPassengersList] = useState([])

  //pagination
  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState('1');
  const [totalPages, setTotalPages] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState('20');


  //add passenger
  const [addPassengerName, setAddPassengerName] = useState('')
  const [addPassengerAssignId, setAddPassengerAssignId] = useState('')
  const [addPassengerIdn, setAddPassengerIdn] = useState('')
  const [addPassengerAddress, setAddPassengerAddress] = useState('')
  const [addPassengerPhone, setAddPassengerPhone] = useState('')
  const [addPassengerEmail, setAddPassengerEmail] = useState('')
  const [addPassengerDesc, setAddPassengerDesc] = useState('')

  //edit passenger
  const [passengerIdForEdit, setPassengerIdForEdit] = useState('')
  const [editPassengerName, setEditPassengerName] = useState('')
  const [editPassengerAssignId, setEditPassengerAssignId] = useState('')
  const [editPassengerIdn, setEditPassengerIdn] = useState('')
  const [editPassengerAddress, setEditPassengerAddress] = useState('')
  const [editPassengerPhone, setEditPassengerPhone] = useState('')
  const [editPassengerEmail, setEditPassengerEmail] = useState('')
  const [editPassengerDesc, setEditPassengerDesc] = useState('')




  //fetching passengers list
  const funcFetchPassengers = async () => {
    try {
      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/passenger?page=${pageNumber}&rows=${rows}&sort=name&search-field=all&search-value=${searchValue}`, requestOptions)
      const result = await response.json();
      setPassengersList(result?.result)
      console.log(result)
      setTotalPages(result?.pages)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchPassengers()
  }, [pageNumber, rows, searchValue])

  //add passenger
  const funcAddPassenger = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": addPassengerName,
        "assign_id": addPassengerAssignId,
        "idn": addPassengerAssignId,
        "address": addPassengerAddress,
        "phone": addPassengerPhone,
        "email": addPassengerEmail,
        "desc": addPassengerDesc
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/device/passenger", requestOptions)
      const result = await response.json();
      funcFetchPassengers()
      setShowAddModal(false)
      clearAddPassengerData()
    } catch (error) {
      console.log(error)
    }
  }

  const clearAddPassengerData = () => {
    setAddPassengerName('')
    setAddPassengerAddress('')
    setAddPassengerAssignId('')
    setAddPassengerDesc('')
    setAddPassengerEmail('')
    setAddPassengerIdn('')
    setAddPassengerPhone('')
  }


  //fetch passenger data for edit
  const funcFetchPassengerData = async (passengerId) => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/passenger/${passengerId}`, requestOptions)
      const result = await response.json()

      setEditPassengerName(result?.name);
      setEditPassengerAssignId(result?.assign_id)
      setEditPassengerIdn(result?.idn)
      setEditPassengerAddress(result?.address)
      setEditPassengerPhone(result?.address)
      setEditPassengerEmail(result?.email)
      setEditPassengerDesc(result?.desc)

      setShowEditModal(true);
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  //function for editing the passenger
  const funcEditPassenger = async () => {
    try {
      var formdata = new FormData();
      formdata.append("name", editPassengerName);
      formdata.append("assign_id", editPassengerAssignId);
      formdata.append("idn", editPassengerIdn);
      formdata.append("address", editPassengerAddress);
      formdata.append("phone", editPassengerPhone);
      formdata.append("email", editPassengerEmail);
      formdata.append("desc", editPassengerDesc);

      var requestOptions = {
        method: 'PATCH',
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/passenger/${passengerIdForEdit}`, requestOptions)
      const result = await response.json()
      console.log(result)
      setShowEditModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  //function for deleting passenger
  const funcDeletePassenger = async (passengerId) => {
    try {
      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/passenger/${passengerId}`, requestOptions)
      const result = await response.json()
      funcFetchPassengers()
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      <div class="">
        <div class="card-body">
          <div className="input-group rounded" >
            <div className="w-100 " >
              <div class="form-group has-search d-flex"
                style={{ marginTop: '5px', minWidth: '500px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span class="fa fa-search form-control-feedback"></span>

                <input type="text" class="form-control" placeholder="Search"
                  style={{ marginTop: '-15px', height: '30px' }}
                  className='w-25'
                  value={searchValue}
                  onChange={(e) => { setSearchValue(e.target.value) }}
                />

                <Form.Select size="sm" style={{ width: '70px' }} value={rows} onChange={(e) => { setRows(e.target.value) }}>
                  <option value="05">05</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </div>

            {isLoading ? (
              <div className='w-100'>
                <NewLoader />
              </div>
            ) : (
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                    <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Assign. Id</th>
                    <th>Idn</th>
                    <th>Address</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    passengersList?.map((passenger, index) => (
                      <tr key={index}>
                        <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                        <td>{passenger?.name}</td>
                        <td>{passenger?.phone}</td>
                        <td>{passenger?.email}</td>
                        <td>{passenger?.assign_id}</td>
                        <td>{passenger?.idn}</td>
                        <td>{passenger?.address}</td>
                        <td>
                          <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Duplicate" >
                            <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                          </a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Edit" onClick={() => { funcFetchPassengerData(passenger?._id); setPassengerIdForEdit(passenger?._id) }} ><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Delete" onClick={() => {funcDeletePassenger(passenger?._id)}}><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            )}
          </div>
          <div className='w-100 d-flex justify-content-end'>
            <Pagination size="sm">
              <Pagination.Prev
                disabled={pageNumber === '1' ? true : false}
                onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
              />
              {
                (
                  <>
                    {Array.from({ length: totalPages }).map((_, i) => (
                      <Pagination.Item
                        key={i}
                        onClick={() => setPageNumber((i + 1).toString())}
                        active={pageNumber === (i + 1).toString() ? true : false}
                      >{i + 1}
                      </Pagination.Item>
                    ))}
                  </>
                )
              }
              <Pagination.Next
                disabled={pageNumber === totalPages.toString() ? true : false}
                onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
              />
            </Pagination>
          </div>
        </div>

      </div>
      <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
        <button type="button" class="btn btn-primary btn-sm btn-block" onClick={() => setShowAddModal(true)}><FiPlus style={{ fontSize: '15px' }} /> </button>
        <button type="button" class="btn btn- btn-sm btn-block" onClick={funcFetchPassengers}><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
        <button type="button" class="btn btn- btn-sm btn-block"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

      </div>




      {/* add passenger modal */}
      <Modal
        size="sm"
        show={showAddModal}
        backdrop="static"
        onHide={() => { setShowAddModal(false); clearAddPassengerData() }}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Add Object passenger properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div class="row align-items-start">
            <div class="col">
              <div class="" style={{ paddingBottom: '20px' }}>
                <div class="row align-items-start">
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Name
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerName}
                      onChange={(e) => { setAddPassengerName(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    RFID or iButton
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerAssignId}
                      onChange={(e) => { setAddPassengerAssignId(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    ID number
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerIdn}
                      onChange={(e) => { setAddPassengerIdn(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Address
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerAddress}
                      onChange={(e) => { setAddPassengerAddress(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Phone
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerPhone}
                      onChange={(e) => { setAddPassengerPhone(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    E-mail
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerEmail}
                      onChange={(e) => { setAddPassengerEmail(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Description
                  </div>
                  <div class="col">
                    <textarea type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addPassengerDesc}
                      onChange={(e) => { setAddPassengerDesc(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="file" class="btn btn-secondary btn-sm" style={{ minWidth: '100px' }}
              onClick={funcAddPassenger}
            >Save</button>
            <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }}
              onClick={(e) => { setShowAddModal(false); clearAddPassengerData() }}
            >Cancel</button>

          </div>


        </Modal.Body>
      </Modal>


      {/* edit passenger modal */}
      <Modal
        size="sm"
        show={showEditModal}
        backdrop="static"
        onHide={() => setShowEditModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Edit Object passenger properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div class="row align-items-start">
            <div class="col">
              <div class="" style={{ paddingBottom: '20px' }}>
                <div class="row align-items-start">
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Name
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerName}
                      onChange={(e) => { setEditPassengerName(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    RFID or iButton
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerAssignId}
                      onChange={(e) => { setEditPassengerAssignId(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    ID number
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerIdn}
                      onChange={(e) => { setEditPassengerIdn(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Address
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerAddress}
                      onChange={(e) => { setEditPassengerAddress(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Phone
                  </div>
                  <div class="col">
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerPhone}
                      onChange={(e) => { setEditPassengerPhone(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    E-mail
                  </div>
                  <div class="col">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerEmail}
                      onChange={(e) => { setEditPassengerEmail(e.target.value) }}
                    />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                  <div class="col" style={{ marginLeft: '20px' }}>
                    Description
                  </div>
                  <div class="col">
                    <textarea type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editPassengerDesc}
                      onChange={(e) => { setEditPassengerDesc(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="file" class="btn btn-secondary btn-sm" style={{ minWidth: '100px' }} onClick={funcEditPassenger}>Save</button>
            <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }}>Cancel</button>

          </div>


        </Modal.Body>
      </Modal>
    </div >
  )
}

export default Passenger
