import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Objects from '../Menu/Objects';
import Groups from '../Menu/Groups';
import Driver from '../Menu/Driver';
import Passenger from '../Menu/Passenger';
import Trailers from '../Menu/Trailers';



//importing of the object menu components






const Object = () => {

  return (
    <div>
      <Card style={{ height: '20px', marginTop: '-20px' }}>

        <div class="card">
          <div class="card-body" style={{ minHeight: '30px' }}>
            Contact administrator if you want to do any changes with your objects.
          </div>

          <div class="topnav" style={{ marginLeft: '15px' }}>
            <NavLink to="/speedotrack/settings" >Objects</NavLink>
            <NavLink to="/speedotrack/settings/groups" >Groups</NavLink>
            <NavLink to="/speedotrack/settings/driver" >Driver</NavLink>
            <NavLink to="/speedotrack/settings/passenger" >Passenger</NavLink>
            <NavLink to="/speedotrack/settings/trailers" >Trailers</NavLink>

          </div>

          <Routes>
            <Route path='/' element={<Objects />} />
            <Route path='/groups' element={<Groups />} />
            <Route path='/driver' element={<Driver />} />
            <Route path='/passenger' element={<Passenger />} />
            <Route path='/trailers' element={<Trailers />} />
          </Routes>


        </div>
      </Card>




    </div>
  )
}

export default Object
