import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal, Table, Card } from 'react-bootstrap';
import Select from 'react-select';
import { MdOutlineSensors } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import "./Style.css";




function EditDeviceModal(props) {
    const [calibrationX, setCalibrationX] = useState('')
    const [calibrationY, setCalibrationY] = useState('')
    const [checkCalibration, setCheckCalibration] = useState([])

    const parameterList = ['acc', 'alarm', 'batl', 'bats', 'cellid', 'defence', 'defense', 'gpslev', 'gsmlev', 'lac', 'mcc', 'mnc', 'pump', 'track']
    const resultTypeList = ['logic', 'value', 'percentage', 'absolute']

    function generateUniqueRandomNumber() {
        return Math.floor(Math.random() * Date.now());
    }

    const funcAddCalibrationValue = () => {
        let updatedCheckCalibration = [...checkCalibration];
      
        if (calibrationX === '' || !calibrationX) {
          updatedCheckCalibration = [...updatedCheckCalibration, 'paramVal'];
        } else {
          updatedCheckCalibration = updatedCheckCalibration.filter((val) => val !== 'paramVal');
        }
      
        if (calibrationY === '' || !calibrationY) {
          updatedCheckCalibration = [...updatedCheckCalibration, 'calibVal'];
        } else {
          updatedCheckCalibration = updatedCheckCalibration.filter((val) => val !== 'calibVal');
        }
      
        if (updatedCheckCalibration.length === 0) {
          props.setSensorCalibration([
            ...props.sensorCalibration,
            {
              id: `_ucid${generateUniqueRandomNumber()}cx${calibrationX}xy${calibrationY}`,
              x: calibrationX,
              y: calibrationY,
            },
          ]);
      
          setCalibrationX('');
          setCalibrationY('');
        }
      
        setCheckCalibration(updatedCheckCalibration);
      };
      

    const funcRemoveCalibrationValue = (id) => {
        console.log(id)
        props.setSensorCalibration(props.sensorCalibration.filter((value) => (value.id !== id)))
    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            top
            style={{ marginLeft: '10%', paddingBottom: '40px', }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                    <MdOutlineSensors /> {" "}Edit Sensor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <span class="text-primary">Sensor</span> <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                            <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

                            <form class="container" style={{ marginTop: '10px' }}>
                                <div class="form-row d-flex">
                                    <div class="form-group col-md-6">
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Sensor properties</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="enter sensor name"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorName}
                                            onChange={(e) => props.setSensorName(e.target.value)}
                                        />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}> Sensor Type</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="sensor type"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorType}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Parameter Type</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                                            value={props.sensorParam}
                                            onChange={((e) => { props.setSensorParam(e.target.value) })}
                                        >
                                            {parameterList.map((param) => (
                                                <option value={param}>{param}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Result Type</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                                            value={props.sensorResultType}
                                            onChange={(e) => props.setSensorResultType(e.target.value)}
                                        >
                                            {resultTypeList.map((result) => (
                                                <option value={result}>{result}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Units of measurement</label>
                                        <input type="text" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }}
                                            value={props.sensorUnit}
                                            onChange={(e) => props.setSensorUnit(e.target.value)}
                                        />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>If sensor "1" (text)</label>
                                        <input type="text" class="form-control" id="inputPassword4" placeholder="text when sensor value is 1"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorOneText}
                                            onChange={(e) => props.setSensorOneText(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>If sensor "0" (text)</label>
                                        <input type="text" class="form-control" id="inputEmail4" placeholder="sensor zero text"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorZeroText}
                                            onChange={(e) => props.setSensorZeroText(e.target.value)}
                                        />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Formula</label>
                                        <input type="text" class="form-control" id="inputPassword4" placeholder="sensor formule"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorFormula}
                                            onChange={(e) => props.setSensorFormula(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Lowest Value</label>
                                        <input type="text" class="form-control" id="inputEmail4" placeholder="sensor lowest value"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorLowestValue}
                                            onChange={(e) => { props.setSensorLowestValue(e.target.value) }}
                                        />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Highest Value</label>
                                        <input type="text" class="form-control" id="inputPassword4" placeholder="sensor highest value"
                                            style={{ borderRadius: '5px' }}
                                            value={props.sensorHighestValue}
                                            onChange={(e) => props.setSensorHighestValue(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <span class="text-primary" >Sensor result preview</span>
                                <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                                <hr style={{ marginTop: '-10px', width: '100px' }}></hr>
                                <div class="form-row d-flex" style={{ marginTop: '10px' }}>
                                    <div class="form-group col-md-5" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Current Value</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>

                                    <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}><MdDoubleArrow style={{ marginTop: '-5px' }} />
                                    </button>
                                    <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Result</label>
                                        <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                </div>



                            </form>
                        </div>
                        <div class="col-sm">
                            <div class="form-check" style={{ marginBottom: '15px' }}>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ padding: '10px' }} 
                                checked={props.sensorAccIgnore}
                                onChange={() => props.setSensorAccIgnore(!props.sensorAccIgnore)}
                                />
                                <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: '8px' }}>
                                    Exclude calculations out of the calibration range
                                </label>
                            </div>
                            <span class="text-primary">Calibration</span>
                            <Card style={{ marginTop: '10px', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px', borderRadius: '0px' }}>
                                <div className="table-responsive scroll" style={{ marginTop: '5px' }}>
                                    <Table striped hover size="sm" >
                                        <thead>
                                            <tr>
                                                <th>Parameter Value</th>
                                                <th>Calibrated Value (L/G, C/F)</th>
                                                <th></th>

                                            </tr>
                                        </thead>
                                        <tbody className='table-responsirve scroll'>
                                            {props.sensorCalibration?.map((value, index) => (
                                                <tr key={index}>
                                                    <td>{value.x}</td>
                                                    <td style={{ marginRight: '10%' }}>{value.y}</td>
                                                    <td>
                                                        <IoIosClose
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => funcRemoveCalibrationValue(value.id)}
                                                        /></td>
                                                </tr>

                                            ))}
                                            {/* <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr> */}




                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                            <div class="form-row d-flex" style={{ marginTop: '-10px' }}>
                                <div class="form-group col-md-5" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Parameter Value</label>
                                    <input type="text" class="form-control" id="inputEmail4" placeholder="value x" style={{ borderRadius: '5px' }}
                                        value={calibrationX}
                                        onChange={(e) => setCalibrationX(e.target.value)}
                                    />
                                    {checkCalibration.includes('paramVal') ? (
                                        <p className='text-danger'>Enter parameter value </p>
                                    ) : null}
                                </div>
                                <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Calibrated Value (L/G, C/F)</label>
                                    <input type="text" class="form-control" id="inputPassword4" placeholder="value y" style={{ borderRadius: '5px' }}
                                        value={calibrationY}
                                        onChange={(e) => setCalibrationY(e.target.value)}
                                    />
                                    {checkCalibration.includes('calibVal') ? (
                                        <p className='text-danger'>Enter calibrated value </p>
                                    ) : null}
                                </div>
                                <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}
                                    onClick={funcAddCalibrationValue}
                                ><FaPlus style={{ marginTop: '-5px' }} /></button>

                            </div>
                        </div>
                        <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                            <button type="button" class="btn btn-primary" onClick={props.createSensor}>Save</button>
                            <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                        </div>

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
export default EditDeviceModal;

