import React from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap';
import { IoIosClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";


const ScheduleModal = (props) => {
    return (
        <div>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ marginLeft: '10%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                        Schedule properties        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="" style={{ marginTop: '15px', marginLeft: '15px' }}>
                        <div class="card-body text-primary" style={{ minHeight: '40px' }}>
                            <div class="row">
                                <div class="col-sm">
                                    Schedule
                                </div>
                                <div class="col-sm">
                                    Time
                                </div>
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: '' }}>
                            <div class="col-sm-8">
                                <div class="row align-items-start">
                                    <div class="col">
                                        Active
                                    </div>
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '-30%' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Name
                                    </div>
                                    <div class="col" >
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Protocol
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>SOS</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Objects
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>Off</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Template
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>Nothing selected</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Gateway
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>Nothing selected</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Type
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>In selected zone</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Command
                                    </div>
                                    <div class="col" >
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-6" style={{ marginLeft: '-17%' }}>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Time
                                    </div>
                                    <div class="col" >
                                        <input type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                    <div class="col" style={{ marginLeft: '' }}>
                                        Monday
                                    </div>
                                    <div class="col d-flex">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                            <option selected>00:00</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>

                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Tuesday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Wednesday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Thursday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Friday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Saturday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: "5px" }}>
                                        <div class="col" style={{}}>
                                            Sunday
                                        </div>
                                        <div class="col d-flex" style={{ marginLeft: '25px' }}>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                                            <select class="form-select" aria-label="Default select example" style={{ marginLeft: '5px' }}>
                                                <option selected>00:00</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>





                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={props.onHide}>Save</Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ScheduleModal
