import React, {useState} from 'react'
import ReactApexChart from 'react-apexcharts';

const DeviceBattery = () => {
  const dates = [
    [1327359600000, 30.95],
    [1327446000000, 31.34],
    // ...more data
  ];
  const [series] = useState([
    {
      name: 'XYZ MOTORS',
      data: dates 
    }
  ]);

  const [options] = useState({
    chart: {
      type: 'area',
      stacked: false,
      height: 300,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      text: '',
      align: 'left'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      },
      title: {
        text: ''
      }
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      }
    }
  });
  
  return (
    <div>
       <div id="chart">
      <ReactApexChart options={options} series={series} type="area" height={130} />
    </div>
    </div>
  )
}

export default DeviceBattery
