import React, { useEffect, useState } from 'react';
import "./Report.css";
import { Routes, Route } from 'react-router-dom';
import Headerpro from '../../../../../components/partials/pro/headerstyle/header-pro';
import Multiselect from 'multiselect-react-dropdown';
import { CiSearch } from "react-icons/ci";
import { Table, Card } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { TbSettingsCog } from "react-icons/tb";
import { TbFilter } from "react-icons/tb";
import { IoMdAdd } from "react-icons/io";
import { FcCheckmark } from "react-icons/fc";
// import { addDays } from 'date-fns';


import GeneralInfoData from './GeneralInfoData';
import GeneralInformationMerged from './GeneralInformationMerged';
import GeneralAnalysis from './GeneralAnalysis';
import ObjectInfo from './ObjectInfo';
import CurrentPosition from './CurrentPosition';
import CurrentPositionOffline from './CurrentPositionOffline';
import DrivesAndStops from './DrivesAndStops';
import DeviceRoute from './DeviceRoute';
import RouteWithStop from './RouteWithStop';
import MileageDaily from './MileageDaily';
import FuelFilling from './FuelFilling';
import SpeedGraph from './SpeedGraph';
import OverSpeed from './OverSpeed';
import UnderSpeed from './UnderSpeed';
import AltitudeGraph from './AltitudeGraph';
import Ignition from './IgnitionGraph'
import FuelLevelGraph from './FuelLevelGraph';
import Temprature from './Temprature';
import SensorGraph from './SensorGraph';
import DriveAndStopsSensor from './DrivesAndStopsSensors'
import DriverBehaviour from './DriverBehaviour';
import DrivesAndStopsWithLogic from './DrivesAndStopsLogic';
import DriverBehaviourRag from './DriverBehaviourRag';
import DriveBehaviourObject from './DriveBehaviourObject';
import Events from './Events';
import TravelSheet from './TravelSheet';
import TravelSheetDayNight from './TravelSheetDayNight';
import Service from './Service';
import FuelThefts from './FuelThefts';
import LogicSensor from './LogicSensor';
import SingleGenerator from './SingleGenerator';
import MultiGenerator from './MultiGenerator';
import Rfid from './Rfid';
import Dtc from './Dtc';
import Expenses from './Expenses';
import ImageGallery from './ImageGallery';
import ZoneInOut from './ZoneInOut';




const TestReport = () => {

    const deviceListData = JSON.parse(localStorage.getItem('loginInfo'));




    const data_item = ['route_start', 'route_end', 'route_length', 'move_duration', 'stop_duration', 'stop_count', 'top_speed', 'avg_speed', 'overspeed_count', 'fuel_consumption', 'avg_fuel_consumption', 'fuel_cost', 'engine_work', 'engine_idle', 'odometer', 'engine_hours', 'driver', 'trailer']
    const sensor_item = ['Air Con', 'Battery Status', 'Battery Voltage', 'Device Battery', 'Door', 'Door Sensor', 'Electricity', 'Engine', 'Engine Status', 'External Battery', 'Fuel Level', 'Generator', 'GPS Level', 'GPS Signal', 'GSM Level', 'Ignition', 'Internal Battery Voltage']

    //declaration of the state variables
    const [deviceOptions, setDeviceOptions] = useState([])
    const [toggleSearch, setToggleSearch] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedDataItems, setSelectedDataItems] = useState(['route_start', 'route_end', 'route_length', 'move_duration', 'stop_duration', 'stop_count', 'top_speed', 'avg_speed', 'overspeed_count', 'fuel_consumption', 'avg_fuel_consumption', 'fuel_cost', 'engine_work', 'engine_idle', 'odometer', 'engine_hours', 'driver', 'trailer'])
    const [selectedSensors, setSelectedSensors] = useState([])
    const [speedLimit, setSpeedLimit] = useState('30')
    const [stopDuration, setStopDuration] = useState('1')
    const [showCordinate, setShowCordinate] = useState(true)
    const [showAddress, setShowAddress] = useState(true)
    const [showZone, setShowZone] = useState(true)



    useEffect(() => {
        const listDevices = deviceListData?.devices?.map((device, index) => (
            {
                name: device.name,
                imei: device.imei
            }
        ))
            setDeviceOptions(listDevices)
    }, [])

    return (
        <div>
            <Headerpro />
            <div className="card" style={{ marginTop: '10px' }}>

                <div className="card-body" style={{ minHeight: '10px' }}>

                    <div className="container" style={{ marginTop: '-35px' }}>
                        <div className="btn-group " role="group" aria-label="Basic example" style={{ marginTop: '8px' }}>
                            <h6 style={{ marginTop: '10px' }}>Running Overview</h6>
                            <button type="button" className="btn btn-soft-primary " style={{ borderRadius: '10px', marginLeft: '15px' }}>Today</button>
                            <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>Yesterday</button>
                            <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>This Week</button>
                            <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>Last Week</button>
                            <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>This Month</button>
                            <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', marginLeft: '5px' }}>Last Month</button>
                        </div>
                        <div className="row" style={{ marginTop: '15px' }}>
                            <div className="col">
                                <label for="exampleInputEmail1">
                                    <h6>Select Device</h6></label>
                                <Multiselect
                                    displayValue="name"
                                    showCheckbox={true}
                                    isObject={true}
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(e) => { }}
                                    onSearch={function noRefCheck() { }}
                                    onSelect={(e) => { }}
                                    options={deviceOptions}
                                    selectedValues={[
                                        {
                                            name: 'Flaves Wagonr',
                                            imei: '350612076585973'
                                        }
                                    ]}
                                    hideSelectedList
                                    placeholder="Selected Data Items"
                                    style={{
                                        multiselectContainer: {
                                            width: '230px'
                                        },
                                    }}
                                />
                            </div>
                            <div className="col" style={{ marginLeft: '-70px' }}> <label for="exampleInputEmail1">
                                <h6>Select Sensor</h6></label>
                                <Multiselect
                                    displayValue="key"
                                    showCheckbox={true}
                                    isObject={false}
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(e) => { }}
                                    onSearch={function noRefCheck() { }}
                                    onSelect={(e) => { }}
                                    options={sensor_item}
                                    hideSelectedList
                                    placeholder="Selected Sensor Items"
                                    style={{
                                        multiselectContainer: {
                                            width: '230px'
                                        },
                                    }}
                                />
                            </div>
                            <div className="col" style={{ marginLeft: '-70px' }}> <label for="exampleInputEmail1">
                                <h6>Data Items</h6></label>
                                <Multiselect
                                    displayValue="key"
                                    showCheckbox={true}
                                    isObject={false}
                                    onKeyPressFn={function noRefCheck() { }}
                                    onRemove={(e) => { setSelectedDataItems(e) }}
                                    onSearch={function noRefCheck() { }}
                                    onSelect={(e) => { setSelectedDataItems(e) }}
                                    options={data_item}
                                    selectedValues={selectedDataItems}
                                    hideSelectedList
                                    placeholder="Selected Data Items"
                                    style={{
                                        multiselectContainer: {
                                            width: '230px'
                                        },
                                    }}
                                />
                            </div>
                            <div className="col" style={{ marginLeft: '-70px' }}>
                                <label for="exampleInputEmail1">
                                    <h6>Time From </h6></label>
                                <input type="date" className="form-control" style={{ width: '80%' }} />
                            </div>
                            <div className="col" >
                                <input type="time" id="appt" name="appt" style={{ marginLeft: '-20px', marginTop: '27px', padding: '5px' }} />

                            </div>






                        </div>

                        <div className="row" style={{ marginTop: '10px' }}>
                            <div className="col">
                                <label for="exampleInputEmail1">
                                    <h6>Stop Duration</h6></label>
                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    value={stopDuration}
                                    onChange={(e) => { setStopDuration(e.target.value) }}
                                />
                            </div>
                            <div className="col" style={{ marginLeft: '-18px' }}>
                                <label for="exampleInputEmail1">
                                    <h6>Speed Limit</h6></label>
                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                    value={speedLimit}
                                    onChange={(e) => { setSpeedLimit(e.target.value) }}
                                />
                            </div>
                            <div className="col" style={{ marginTop: '30px', marginLeft: '-15px' }}> <label for="exampleInputEmail1">
                                <h6>Show Cordinate</h6></label>{" "}
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    checked={showCordinate}
                                    onChange={() => { setShowCordinate(!showCordinate) }}
                                />
                            </div>
                            <div className="col" style={{ marginTop: '30px', marginLeft: '-100px' }}> <label for="exampleInputEmail1">
                                <h6>Show Address</h6></label>{" "}
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    checked={showAddress}
                                    onChange={() => { setShowAddress(!showAddress) }}
                                />
                            </div>
                            <div className="col" style={{ marginTop: '30px', marginLeft: '-100px' }}> <label for="exampleInputEmail1">
                                <h6>Show Zones</h6></label>{" "}
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    checked={showZone}
                                    onChange={() => { setShowZone(!showZone) }}
                                />
                            </div>
                            <div className="col" style={{ marginTop: '30px', marginLeft: '-100px' }}>
                                <button type="button" className="btn btn-soft-primary" style={{ borderRadius: '10px', width: '80%', marginLeft: '-10px', marginTop: '-8px' }}
                                    onClick={() => { setToggleSearch(!toggleSearch) }}
                                ><CiSearch /></button>
                            </div>


                        </div>




                    </div>


                </div>
            </div>




            <Routes>

                {/* Reports General Info */}
                <Route path='/general-info' element={
                    <GeneralInfoData
                        dataItems={data_item}
                        selectedDataItems={selectedDataItems}
                        toggleSearch={toggleSearch}
                        speedLimit={speedLimit}
                        stopDuration={stopDuration}
                    />} />

                {/* Reports General Info Merged */}
                <Route path='/general-info-merged' element={
                    <GeneralInformationMerged
                    />} />

                <Route path='/general-analysis' element={<GeneralAnalysis />} />
                <Route path='/object-info' element={<ObjectInfo />} />
                <Route path='/current-position' element={<CurrentPosition />} />
                <Route path='/current-position-offline' element={<CurrentPositionOffline />} />


                <Route path='/drives-and-stops' element={
                    <DrivesAndStops

                    />} />

                <Route path='/drive-and-stops-sensor' element={
                    <DriveAndStopsSensor

                    />} />

                <Route path='/device-route' element={<DeviceRoute />} />
                <Route path='/route-with-stop' element={<RouteWithStop />} />
                <Route path='/mileaga-daily' element={<MileageDaily />} />
                <Route path='/fuel-filling' element={<FuelFilling />} />
                <Route path='/speed-graph' element={<SpeedGraph />} />
                <Route path='/over-speed' element={<OverSpeed />} />
                <Route path='/under-speed' element={<UnderSpeed />} />
                <Route path='/altitude-graph' element={<AltitudeGraph />} />
                <Route path='/ignition' element={<Ignition />} />
                <Route path='/fuel-level-graph' element={<FuelLevelGraph />} />
                <Route path='/temprature' element={<Temprature />} />
                <Route path='/sensor-graph' element={<SensorGraph />} />
                <Route path='/drives-and-stops-with-logic' element={<DrivesAndStopsWithLogic />} />
                <Route path='/driver-behaviour' element={<DriverBehaviour />} />
                <Route path='/driver-behaviour-rag' element={<DriverBehaviourRag />} />
                <Route path='/driver-behaviour-object' element={<DriveBehaviourObject />} />
                <Route path="/events" element={<Events />} />
                <Route path='/travel-sheet' element={<TravelSheet />} />
                <Route path='/travel-sheet-day' element={<TravelSheetDayNight />} />
                <Route path='/service' element={<Service />} />
                <Route path='/fuel-thefts' element={<FuelThefts />} />
                <Route path='/logic-sensor' element={<LogicSensor />} />
                <Route path='/single-generator' element={<SingleGenerator />} />
                <Route path='/multi-generator' element={<MultiGenerator />} />
                <Route path='/rfid' element={<Rfid />} />
                <Route path='/dtc' element={<Dtc />} />
                <Route path='/expenses' element={<Expenses />} />
                <Route path='/image-gallery' element={<ImageGallery />} />
                <Route path='/zone-in-out' element={<ZoneInOut />} />

            </Routes>


        </div>
    )
}

export default TestReport;
