import React, { useState } from 'react';
import "./Style.css"


import DeleteUsageModal from './DeleteUsageModal';




const EventWebhook = (props) => {
  const [deleteUsage, setDeleteUsage] = useState(false);
  //state for input data
  const [enableWebhook, setEnableWebhook] = useState(props.addEventData?.webhook?.enabled)





  return (
    <div>
      <div>
        <div>



          <div class="row " style={{ marginTop: '10px', marginLeft: '4px', }}>

          </div>
          <div class="p-2 flex-grow-1 bd-highlight">
            <span class="" style={{ marginTop: '30px', fontSize: '15px', marginLeft: '5px' }}>Webhook
              <hr style={{ marginTop: '8px', width: '100px', marginLeft: '8px' }}></hr>
              <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '8px' }}></hr></span>
          </div>

          <form lg="12" style={{ marginTop: '10px', marginLeft: '12px' }}>
            <div class="row">
              <div class="col-6 col-md-4">Send webhook</div>
              <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                checked={enableWebhook}
                onChange={() => {
                  setEnableWebhook(!enableWebhook);
                  props.setAddEventData({
                    ...props.addEventData,
                      webhook: {
                        ...props.addEventData?.webhook?.enable,
                        enabled: enableWebhook ? false : true
                      }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-4"></div>
            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-4">Webhook URL</div>
              <div class="col-6 col-md-4">
                <textarea type="text" placeholder="ex. http://fulladdress_here" style={{ marginLeft: '21%', minWidth: '400px', padding: '20px' }} />
              </div>

            </div>

          </form>




        </div>


      </div>
      <DeleteUsageModal
        show={deleteUsage}
        onHide={() => setDeleteUsage(false)}
      />



    </div>
  )
}

export default EventWebhook;
