import React, { useState } from 'react'
import { Table, Modal, Button } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { FcCheckmark } from "react-icons/fc";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";

import "./Style.css";
import EventsModal from '../Events/EventsModal';



const EventSettings = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  return (
    <div>
      <div class="card" style={{ marginTop: "-18px" }}>
        <div class="card-body">
          <div className="input-group rounded" >
            <div class="form-group has-search" style={{ marginTop: '25px', minWidth: '1190px', }}>
              <span class="fa fa-search form-control-feedback"></span>
              <input type="text" class="form-control" placeholder="Search" style={{ marginTop: '-15px' }} />
            </div>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                  <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                  <th style={{ paddingLeft: '0px' }} >Active</th>
                  <th>Name</th>
                  <th>System</th>
                  <th>Push notification</th>
                  <th>Email</th>
                  <th>SMS</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ marginLeft: '50px' }}>1</td>
                  <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                  <td><FcCheckmark style={{ marginLeft: '-10px' }} /></td>
                  <td>SpeedoTrack</td>
                  <td>352093084881159</td>
                  <td>352093084881159</td>
                  <td>demo@gmail.com</td>
                  <td>352093084881159</td>


                  <td>
                    <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Duplicate" >
                      <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                    </a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Edit"  ><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

        </div>
        <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
          <button type="button" class="btn btn-primary btn-sm btn-block" title=" Add" onClick={() => setModalShow(true)}><FiPlus style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Refresh "> <HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

        </div>
      </div>

      <EventsModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      




    </div>
  )
}

export default EventSettings
