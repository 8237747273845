import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaCarAlt } from "react-icons/fa";
import { FaCarSide } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { RiPoliceCarFill } from "react-icons/ri";
import { GrCar } from "react-icons/gr";
import Select from 'react-select';
import { MdOutlineSensors } from "react-icons/md";
import CallibrationCustomerEvent from './CalllibrationCustomerEvent';




function EditDeviceModal(props) {
  const [callibrationModal, setCallibrationModal] = useState(false);

  
  

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '-7.5%', paddingBottom: '20px' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          <MdOutlineSensors /> {" "}Edit Events
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <form lg="12">
          <div class="form-row d-flex">
            <div class="form-group col-md-6">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Event Type</label>
              <select class="form-select" aria-label="Default select example" 
              style={{ width: '98%' }}
              onChange={(e) => {
                if(e.target.value === 'calibration'){
                  setCallibrationModal(true)
                }
              }}
              >
                <option selected>Manager</option> 
                <option value="calibration">Calibration</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>
            </div>
          </div>
          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Objects</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                <option selected>Manager</option>
                <option value="1">Super Admin</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Depending on routes</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                <option selected>Manager</option>
                <option value="1">Super Admin</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>
            </div>
          </div>

          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Routes</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                <option selected>Manager</option>
                <option value="1">Super Admin</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Depending on zones</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                <option selected>Manager</option>
                <option value="1">Super Admin</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>            </div>
          </div>

          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Zones</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                <option selected>Manager</option>
                <option value="1">Super Admin</option>
                <option value="2">User</option>
                <option value="3">Sub Admin</option>
              </select>            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Time period (min)</label>
              <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
          </div>
          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Speed limit (kph)</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Active</label>
              <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
            </div>
          </div>

          <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary">Save</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div>
        


        </form>
      </Modal.Body>

      <CallibrationCustomerEvent
        show={callibrationModal}
        onHide={() => setCallibrationModal(false)}
      />

    </Modal>
  );
}
export default EditDeviceModal;

