import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import { getCountries, getStates } from 'country-state-picker';
import "./Style.css"



const ObjectSetting = (props) => {
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [canEdit, setCanEdit] = useState(false);

  //declaration of the state variables for managing the from data
  const [editUserFirstName, setEditUserFirstName] = useState(props.userDetails?.name?.first);
  const [editUserMidName, setEditUserMidName] = useState(props.userDetails?.name?.mid);
  const [editUserLastName, setEditUserLastName] = useState(props.userDetails?.name?.last);
  const [editUserCompany, setEditUserCompany] = useState(props.userDetails?.info?.company);
  const [editUserFirstPhoneNumber, setEditUserFirstPhoneNumber] = useState(props.userDetails?.info?.phone1?.number);
  const [editUserSecondPhoneNumber, setEditUserSecondPhoneNumber] = useState(props.userDetails?.info?.phone2?.number);
  const [editUserCountry, setEdituserCountry] = useState(props.userDetails?.address?.country);
  const [editUserState, setEditUserState] = useState(props.userDetails?.address?.state);
  const [editUserCity, setEditUserCity] = useState(props.userDetails?.address?.city);
  const [editUserPostalCode, setEditUserPostalCode] = useState(props.userDetails?.address?.postal_code);
  const [editUserStreet, setEditUserStreet] = useState(props.userDetails?.address?.street);
  const [editUserBuilding, setEditUserBuilding] = useState(props.userDetails?.address?.building);

  useEffect(() => {
    const countries = getCountries();
    setCountryList(countries)
  }, [])

  useEffect(() => {
    console.log(editUserCountry)
    const val = countryList.find((cObject) => cObject.name === editUserCountry)
    const states = getStates(val?.code)
    console.log(val)
    setStatesList(states)
    // setCountryCode(val?.dial_code)
  }, [editUserCountry])




  return (
    <div>
      <div>

        <Card.Header className="d-flex justify-content-between">
          <div className="header-title"></div>
        </Card.Header>

        <div>
          <div>
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">
                <span class="" style={{ marginLeft: '1.5%', marginTop: '30px', fontSize: '15px', marginLeft: '15px' }}>Contact Information
                  <hr style={{ marginTop: '8px', width: '125px', marginLeft: '20px' }}></hr>
                  <hr style={{ marginTop: '-10px', width: '125px', marginLeft: '20px' }}></hr></span>
              </div>
              <Form.Check
              style={{marginRight: '20px'}}
                type="checkbox"
                id={`default-checkbox`}
                label="Edit"
                checked={canEdit}
                onChange={() => setCanEdit(!canEdit)}
              />
            </div>
          </div>
          <form lg="12" style={{ marginTop: '10px', marginLeft: '12px' }}>
            <div class="form-row d-flex">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Salutation</label>
                <select class="form-select" aria-label="Default select example"
                  style={{ width: '98%' }}
                  disabled={!canEdit}
                  value={editUserFirstName}
                  onChange={(e) => {
                    setEditUserFirstName(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      name: {
                        ...props.patchData.name,
                        first: e.target.value
                      }
                    })
                  }}
                >
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="M/s.">M/s.</option>
                </select>
              </div>
              <div class="form-group col-md-3 " style={{ marginLeft: '8px' }}>
                <label for="inputEmail4" style={{ fontSize: '13px' }}>First Name</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="user's first name"
                  style={{ borderRadius: '5px' }}
                  value={editUserMidName}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserMidName(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      name: {
                        ...props.patchData.name,
                        mid: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-3" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Last Name</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="user's last name"
                  style={{ borderRadius: '5px' }}
                  value={editUserLastName}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserLastName(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      name: {
                        ...props.patchData.name,
                        last: e.target.value
                      }
                    })
                  }}
                />

              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '10px' }}>Company</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="company"
                  style={{ borderRadius: '5px', marginLeft: '10px' }}
                  value={editUserCompany}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserCompany(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      info: {
                        ...props.patchData.info,
                        company: e.target.value
                      }
                    })
                  }}
                />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', }}>Phone Number 1</label>
                <input type="text" class="form-control" id="inputEmail4" placeholder="phone number 1" style={{ borderRadius: '5px' }}
                  value={editUserFirstPhoneNumber}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserFirstPhoneNumber(e.target.value);
                    props.setPatchData((prevPatchData) => ({
                      ...prevPatchData,
                      info: {
                        ...prevPatchData.info,
                        phone1: {
                          ...(props.patchData.info?.phone1 || {}),
                          number: e.target.value
                        }
                      }
                    }));
                  }}
                />
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '10px' }}>Phone Number 2</label>
                <input type="text" class="form-control" id="inputEmail4" placeholder="phone number 2"
                  style={{ borderRadius: '5px', marginLeft: '10px' }}
                  value={editUserSecondPhoneNumber}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserSecondPhoneNumber(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      info: {
                        ...props.patchData.info,
                        phone2: {
                          ...(props.patchData.info?.phone2 || {}),
                          number: e.target.value
                        }
                      }
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-3" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}> Country</label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => {
                    setEdituserCountry(e[0]);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        country: e[0]
                      }
                    })
                  }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => {
                    setEdituserCountry(e[0]);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        country: e[0]
                      }
                    })
                  }}
                  options={(countryList.map((country) => country.name))}
                  placeholder={false}
                  selectionLimit={1}
                  selectedValues={[editUserCountry]}
                  style={{
                    chips: {
                      background: 'none',
                      color: 'grey'
                    },
                    searchBox: {
                      height: '37px',
                      marginLeft: '10px',
                      backgroundColor: !canEdit ? '#e9ecef' : '',
                      border: !canEdit ? 'none' : ''
                    }
                  }}
                />
              </div>
              <div class="form-group col-md-3" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}> State</label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => {
                    setEditUserState(e[0]);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        state: e[0]
                      }
                    })
                  }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => {
                    setEditUserState(e[0]);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        state: e[0]
                      }
                    })
                  }}
                  options={statesList}
                  selectionLimit={1}
                  placeholder={false}
                  selectedValues={[editUserState]}
                  style={{
                    chips: {
                      background: 'none',
                      color: 'grey'
                    },
                    searchBox: {
                      height: '37px',
                      backgroundColor: !canEdit ? '#e9ecef' : '',
                      border: !canEdit ? 'none' : ''
                    }
                  }}
                />
              </div>
            </div>

            <div class="form-row d-flex">
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>City</label>
                <input type="text" class="form-control" id="inputEmail4" placeholder="city"
                  style={{ borderRadius: '5px' }}
                  value={editUserCity}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserCity(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        city: e.target.value
                      }
                    })
                  }}
                />
              </div>

              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '10px' }}>Postal code</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="postal code"
                  style={{ borderRadius: '5px', marginLeft: '10px' }}
                  value={editUserPostalCode}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserPostalCode(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        postal_code: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '18px' }}>Street</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="street"
                  style={{ borderRadius: '5px', marginLeft: '14px' }}
                  value={editUserStreet}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserStreet(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        street: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-3">
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '18px' }}>Building</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="building"
                  style={{ borderRadius: '5px', marginLeft: '18px' }}
                  value={editUserBuilding}
                  disabled={!canEdit}
                  onChange={(e) => {
                    setEditUserBuilding(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      address: {
                        ...props.patchData.address,
                        building: e.target.value
                      }
                    })
                  }}
                />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-12">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Comments</label>
                <textarea type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px', }} />
              </div>

            </div>
            <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
              <button type="button" class="btn btn-primary" onClick={props.editUser}>Save</button>
              <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }} onClick={props.onHide}>Cancel</button>

            </div>

          </form>




        </div>




      </div>


    </div>
  )
}

export default ObjectSetting
