import { useState, useEffect, memo } from 'react'
import { LiaTruckMovingSolid } from "react-icons/lia";
import { TbRouteAltLeft } from "react-icons/tb";
import { LuFuel } from "react-icons/lu";
import { MdAutoGraph } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import { MdOutlineDashboard } from "react-icons/md";
import GeneratedReport from '../../../../views/dashboard/Speedo/report/report-components/GeneratedReport';
import CustomeReport from '../../../../views/dashboard/Speedo/report/report-components/CustomeReport';
import {animateScroll as scroll} from 'react-scroll';


//react-bootstrap
import { Navbar, Container, Nav, Dropdown, Form, Image, Button, Collapse } from 'react-bootstrap'

//router
import { Link, useLocation } from 'react-router-dom'

//component
import CustomToggle from "../../../dropdowns"
import Card from "../../../bootstrap/card"



// logo
import Logo from '../../components/logo'

// Redux Selector / Action
import { useSelector } from 'react-redux';


// Import selectors & action from setting store
import * as SettingSelector from '../../../../store/setting/selectors'

import RadioBtn from '../../../setting/elements/radio-btn'

const Headerpro = memo((props) => {
    const navbarHide = useSelector(SettingSelector.navbar_show); // array
    const themeFontSize = useSelector(SettingSelector.theme_font_size)
    const headerNavbar = useSelector(SettingSelector.header_navbar)
    useEffect(() => {

        if (headerNavbar === 'navs-sticky' || headerNavbar === 'nav-glass') {
            window.onscroll = () => {
                if (document.documentElement.scrollTop > 50) {
                    document.getElementsByTagName('nav')[0].classList.add('menu-sticky')
                } else {
                    document.getElementsByTagName('nav')[0].classList.remove('menu-sticky')
                }
            }
        }

        document.getElementsByTagName('html')[0].classList.add(themeFontSize)


        //offcanvase code
        const result = window.matchMedia("(max-width: 1200px)");
        window.addEventListener('resize',
            () => {
                if (result.matches === true) {
                    if (show1 === true) {
                        document.documentElement.style.setProperty('overflow', 'hidden');
                    }
                    else {
                        document.documentElement.style.removeProperty('overflow')
                    }

                }
                else {
                    document.documentElement.style.removeProperty('overflow')
                }

            }
        )
        if (window.innerWidth <= '1200') {
            if (show1 === true) {
                document.documentElement.style.setProperty('overflow', 'hidden');
            }
            else {
                document.documentElement.style.removeProperty('overflow')
            }

        }
        else {
            document.documentElement.style.removeProperty('overflow')
        }

    })

    const [show, setShow] = useState(true)

    const [show1, setShow1] = useState(false);

    //collapse
    const [open, setOpen] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open10, setOpen10] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [customeShow, setCustomeShow] = useState(false);


    //fullscreen
    const fullscreen = () => {
        if (!document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            }
        }
    }





    const minisidebar = () => {
        document.getElementsByTagName('ASIDE')[0].classList.toggle('sidebar-mini')
    }

    let location = useLocation();

    return (
        <Navbar expand="xl" className={`nav iq-navbar ${headerNavbar} ${navbarHide.join(" ")}`}>
            <Container fluid className="navbar-inner">
                <Link to="/" className="navbar-brand">
                    <Logo color={true} />
                    <h4 className="logo-title d-none d-sm-block">{props.app_name}</h4>
                </Link>
                <div className="sidebar-toggle" data-toggle="sidebar" data-active="true" onClick={minisidebar}>
                    <i className="icon d-flex">
                        <svg width="20px" viewBox="0 0 24 24" className="icon-20" >
                            <path fill="currentColor" d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z" />
                        </svg>
                    </i>
                </div>
                <div className="d-flex align-items-center justify-content-between product-offcanvas">
                    <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
                        <small className="mb-0 text-capitalize">Report</small>
                    </div>
                    <div className={`offcanvas offcanvas-end shadow-none iq-product-menu-responsive ${show1 === true ? 'show' : ''} `} tabIndex="-1" id="offcanvasBottom" style={{ visibility: `${show1 === true ? 'visible' : 'hidden'}` }}>
                        <div className="offcanvas-body">





                            <ul className="iq-nav-menu list-unstyled">

                                <Nav.Item as="li" className="active">

                                    <Nav.Link className="menu-arrow justify-content-start" onClick={() => setOpen(!open)} aria-controls="homeData" aria-expanded={open} role="button" >
                                        <LiaTruckMovingSolid style={{ fontSize: '17px' }} />
                                        <span className="nav-text ms-2" style={{ fontSize: '13px' }}>Running Statistics</span>
                                    </Nav.Link>
                                    <Collapse in={open}>
                                        <ul className="iq-header-sub-menu list-unstyled " id="homeData">
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/general-info' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/general-info">General information</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/general-analysis' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/general-analysis">General Anaysis</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/general-info-merged' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/general-info-merged">General information (merged)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/object-info' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/object-info">Object information</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/current-position' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/current-position">Current position</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/current-position-offline' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/current-position-offline">Current position (offline)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/device-route' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/device-route">Routes</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/route-with-stop' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/route-with-stop">Routes with stops</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/image-gallery' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/image-gallery">Image gallery</Link>
                                            </Nav.Item>


                                        </ul>
                                    </Collapse>
                                </Nav.Item>
                            </ul>


                            <ul className="iq-nav-menu list-unstyled">
                                <Nav.Item as="li" className="">
                                    <Nav.Link className="menu-arrow justify-content-start" onClick={() => setOpen(!open)} aria-controls="homeData" aria-expanded={open} role="button" >
                                        <TbRouteAltLeft style={{ fontSize: '17px' }} />
                                        <span className="nav-text ms-2" style={{ fontSize: '13px' }}>Travel Report</span>
                                    </Nav.Link>
                                    <Collapse in={open}>
                                        <ul className="iq-header-sub-menu list-unstyled " id="homeData">
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/travel-sheet' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/travel-sheet">Travel sheet</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/travel-sheet-day' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/travel-sheet-day">Travel sheet (day/night)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/mileaga-daily' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/mileaga-daily">Mileage (daily)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/over-speed' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/over-speed">Overspeeds</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/under-speed' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/under-speed">Underspeeds</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/zone-in-out' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/zone-in-out">Zone in/out</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/events' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/events">Events</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/service' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/service">Service</Link>
                                            </Nav.Item>


                                        </ul>
                                    </Collapse>
                                </Nav.Item>
                            </ul>


                            <ul className="iq-nav-menu list-unstyled">
                                <Nav.Item as="li" className="">
                                    <Nav.Link className="menu-arrow justify-content-start" onClick={() => setOpen(!open)} aria-controls="homeData" aria-expanded={open} role="button" >
                                        <LuFuel style={{ fontSize: '17px' }} />
                                        <span className="nav-text ms-2" style={{ fontSize: '13px' }}>Fuel Report</span>
                                    </Nav.Link>
                                    <Collapse in={open}>
                                        <ul className="iq-header-sub-menu list-unstyled " id="homeData">
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/fuel-filling' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/fuel-filling">Fuel fillings</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/fuel-thefts' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/fuel-thefts">Fuel thefts</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/logic-sensor' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/logic-sensor">Logic sensors</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/single-generator' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/single-generator">Single Generator</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/multi-generator' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/multi-generator">Multi Generator</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/rfid' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/rfid">RFID and iButton logbook</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/dtc' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/dtc">DTC (Diagnostic Trouble Codes)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/expenses' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/expenses">Expenses</Link>
                                            </Nav.Item>
                                        </ul>
                                    </Collapse>
                                </Nav.Item>
                            </ul>

                            <ul className="iq-nav-menu list-unstyled">
                                <Nav.Item as="li" className="">
                                    <Nav.Link className="menu-arrow justify-content-start" onClick={() => setOpen(!open)} aria-controls="homeData" aria-expanded={open} role="button" >
                                        <MdAutoGraph style={{ fontSize: '17px' }} />
                                        <span className="nav-text ms-2" style={{ fontSize: '13px' }}>
                                            Graphical Report</span>
                                    </Nav.Link>
                                    <Collapse in={open}>
                                        <ul className="iq-header-sub-menu list-unstyled " id="homeData">
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/speed-graph' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/speed-graph">Speed</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/altitude-graph' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/altitude-graph">Altitude</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/ignition' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/ignition">Ignition</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/fuel-level-graph' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/fuel-level-graph">Fuel level</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/temprature' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/temprature">Temperature</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/sensor-graph' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/sensor-graph">Sensor</Link>
                                            </Nav.Item>
                                        </ul>
                                    </Collapse>
                                </Nav.Item>
                            </ul>

                            <ul className="iq-nav-menu list-unstyled">
                                <Nav.Item as="li" className="">
                                    <Nav.Link className="menu-arrow justify-content-start" onClick={() => setOpen(!open)} aria-controls="homeData" aria-expanded={open} role="button" >
                                        <MdOutlineDashboard style={{ fontSize: '17px' }} />
                                        <span className="nav-text ms-2" style={{ fontSize: '13px' }}>
                                            Driver Behavior Report</span>
                                    </Nav.Link>
                                    <Collapse in={open}>
                                        <ul className="iq-header-sub-menu list-unstyled " id="homeData">
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/drives-and-stops' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/drives-and-stops">Drives and stops</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/drive-and-stops-sensor' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/drive-and-stops-sensor">Drives and stops with sensors</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/drives-and-stops-with-logic' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/drives-and-stops-with-logic">Drives and stops with logic sensors</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/driver-behaviour' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/driver-behaviour">Driver behavior </Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/driver-behaviour-rag' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/driver-behaviour-rag">Driver behavior (RAG by driver)</Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Link className={`${location.pathname === '/speedotrack/demo-report/driver-behaviour-object' ? 'active' : ''} nav-link `} to="/speedotrack/demo-report/driver-behaviour-object">Driver behavior (RAG by object)</Link>
                                            </Nav.Item>
                                        </ul>
                                    </Collapse>
                                </Nav.Item>
                            </ul>
                        </div>
                    </div>
                    <div className={`offcanvas-backdrop fade  ${show1 === true ? 'show d-block' : 'd-none'}`} onClick={() => setShow1(false)}></div>
                </div>
                <div className="d-flex align-items-center">
                    <Button id="navbar-toggle" bsPrefix="navbar-toggler" type="button" aria-expanded={show1} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={() => setShow1(!show1)} >
                        <span className="navbar-toggler-icon">
                            <span className="mt-1 navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </span>
                    </Button>
                </div>
                <div className={` navbar-collapse ${show1 === true ? 'collapse show' : 'collapse'}`} id="navbarSupportedContent">
                    <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
                        <button type="button" class="btn btn-primary btn-sm" style={{ borderRadius: '5px' }} onClick={() => setModalShow(true)}>Generated Report</button>
                        <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: '10px', padding: '4px', borderRadius: '5px' }} onClick={()=> setCustomeShow(true)}>Custom Report</button>


                    </ul>
                </div>
                <GeneratedReport
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />

                <CustomeReport
                    show={customeShow}
                    onHide={() => setCustomeShow(false)}
                />
            </Container>
        </Navbar>
    )
})

Headerpro.displayName = "Headerpro"
export default Headerpro
