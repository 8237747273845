import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    route: []
}



const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        setHistoryRoute: (state, action) => {
            state.route = action.payload;
        }
    }
})


//exporting of the funcitons
export default historySlice.reducer;
export const { setHistoryRoute } = historySlice.actions;

