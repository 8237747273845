import React from 'react';
import { Spinner } from 'react-bootstrap';



const NewLoader = () => {
    return (
        <div>
            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Spinner animation="border" variant="primary" />
                    <br />
                    <p>Please wait, while we are fetching data</p>
                </div>
            </div>
        </div>
    )
}

export default NewLoader;
