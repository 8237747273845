import React from 'react'
import { Table, Card } from "react-bootstrap";

const CurrentPositionoffline = () => {
    return (
        <div>
            <div>
                <div class="card">
                    <div class="card-body" style={{ minHeight: '40px' }}>
                        <div class="row">
                            <div class="col">
                                <h6>Current Position (Offline) -  Object:	10H10-9400</h6>
                            </div>
                            <div class="col-md-auto">
                                <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                            </div>
                        </div>
                    </div>
                    <Card style={{ marginTop: '-18px' }}>
                        <Card.Body >
                            <div className="table-responsive" style={{ marginTop: '10px' }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th >Object</th>
                                            <th  >Time</th>
                                            <th>Position</th>
                                            <th>Speed</th>
                                            <th>Altitude</th>
                                            <th>Angle</th>
                                            <th>Status</th>
                                            <th>Odometer</th>
                                            <th>Engine hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>

                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>

                                           
                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>

                                          
                                        </tr>



                                    </tbody>
                                </Table>
                            </div>


                        </Card.Body>
                    </Card>

                </div>
            </div>
        </div>
    )
}

export default CurrentPositionoffline;
