import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PiUserSwitchFill } from "react-icons/pi";
import { MdSpaceDashboard } from "react-icons/md";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function ActionsInAccordionSummary() {
    const classes = useStyles();

    return (
        <div>

            <div class="card col-12" style={{ height: '70px' }}>
                <div class="card-body">
                    <h5 class="card-title"><img src={require('./images/coordinate.png')} style={{ width: '30px' }} /> Role & Permision</h5>
                </div>
            </div>

            <div class="row">

                <div class="col-4"><div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="User Dashboard"


                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Admin Dashboard"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Monitor"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div></div>
                <div class="col-4"><div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Device List"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Userlist"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Unused Device"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div></div>
                <div class="col-4"><div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Report"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Object Control"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <FormControlLabel
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox />}
                                label="Tasks"
                            />
                        </AccordionSummary>
                        <AccordionDetails class="d-flex">
                            <Typography color="textSecondary">
                                <div class="row align-items-start">
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Edit"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Add"
                                        />
                                    </div>
                                    <div class="col">
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox />}
                                            label="Delete"
                                        />
                                    </div>

                                </div>



                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div></div>
                <div class="widgets-content">

                    <div class="widget widget-device">
                        <div class="widget-heading">
                            <div class="widget-title">
                                <div class="widget-actions">
                                    <span data-device="status" style={{backgroundColor: 'yellow'}} title="ACK">ACK</span> <span data-device="status-text">ACK</span>
                                </div>
                                <i class="icon device"></i>
                                <span data-device="name">DM-NA-16-1034</span>
                            </div>
                        </div>
                        <div class="widget-body">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Address:</td>
                                        <td class="full-text">
                                            <span class="pull-right p-relative"><span data-device="preview"><a href="https://maps.google.com/maps?q=23.756733,90.354909&amp;t=m&amp;hl=en" target="_blank" class="btn btn-xs btn-default"><i class="icon eye"></i></a></span></span>
                                            <span data-device="address" data-lat="23.756733" data-lng="90.354909">Chand Uddan, Mohammadpur, Dhaka, Dhaka Metropolitan, Dhaka District, Dhaka Division, 1207, Bangladesh</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Time:</td>
                                        <td><span data-device="time">23-12-2023 05:04:19 PM</span></td>
                                    </tr>
                                    <tr>
                                        <td>Stop duration:</td>
                                        <td><span data-device="stop_duration">97h 32min 19s</span></td>
                                    </tr>
                                    <tr>
                                        <td>Driver:</td>
                                        <td><span data-device="driver.name">-</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="widget widget-sensors">
                        <div class="widget-heading">
                            <div class="widget-title"><i class="icon sensors"></i> Sensors</div>
                        </div>
                        <div class="widget-body">
                            <div data-device="sensors"><table class="table"><tbody><tr><td><i class="icon engine"></i>Engine</td><td>Off</td></tr><tr><td><i class="icon satellites"></i>GPS</td><td>- SAT</td></tr><tr><td><i class="icon odometer"></i>Odometer</td><td>10728 Km</td></tr><tr><td><i class="icon battery battery-0"></i>Device Battery</td><td>0 %</td></tr></tbody></table><table class="table"><tbody><tr><td><i class="icon gsm gsm-0"></i>GSM Level</td><td>0 %</td></tr><tr><td><i class="icon textual"></i>Chraging Status</td><td>-</td></tr><tr><td><i class="icon speed"></i>Speed</td><td>0 kph</td></tr></tbody></table></div>

                            <div class="widget-empty">
                                <a href="javascript:" class="btn btn-default" data-url="" data-modal="sonsors_create" type="button">
                                    <i class="icon add"></i> Add sensor
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget-services">
                        <div class="widget-heading">
                            <div class="widget-title">
                                <div class="widget-actions">
                                    <a href="javascript:" class="btn icon edit" data-url="https://track.safetyvts.com/services/index/6430" data-modal="services" type="button"></a>
                                </div>
                                <i class="icon tools"></i> Services
                            </div>
                        </div>
                        <div class="widget-body">
                            <table class="table" data-device="services"></table>

                            <div class="widget-empty">
                                <a href="javascript:" class="btn btn-default" data-url="https://track.safetyvts.com/services/create/6430" data-modal="services_create" type="button">
                                    <i class="icon add"></i> Add service
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="widget widget-camera" id="widget-camera">
                    </div>


                </div>
            </div>
        </div>


    );
}
