import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table } from 'react-bootstrap';
import "./Style.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";
import { TfiEye } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import DeleteUsageModal from './DeleteUsageModal';




const SubAccountSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [deleteUsage, setDeleteUsage] = useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      <div>
        <div>



          <div class="row " style={{ marginTop: '10px', marginLeft: '4px', }}>

          </div>

          <div class="p-2 flex-grow-1 bd-highlight">
            <span class="" style={{ marginTop: '30px', fontSize: '15px', marginLeft: '5px' }}>Object control
              <hr style={{ marginTop: '8px', width: '100px', marginLeft: '8px' }}></hr>
              <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '8px' }}></hr></span>
          </div>

          <form lg="12" style={{ marginTop: '10px', marginLeft: '12px' }}>
            <div class="row">
              <div class="col-6 col-md-4">Send command</div>
              <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-4"></div>
            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Template</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ minWidth: '330px', marginLeft: '-35%' }}>
                <option selected>Custom</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Gateway</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ minWidth: '330px', marginLeft: '-35%' }}>
                <option selected>GPRS</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>

            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Type</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ minWidth: '330px', marginLeft: '-35%' }}>
                <option selected>ASCII</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>

            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-4">Command</div>
              <div class="col-6 col-md-4">
                <textarea type="text" style={{ marginLeft: '21%', minWidth: '400px', padding: '20px' }} />
              </div>
            </div>
          </form>





        </div>


      </div>

      <DeleteUsageModal
        show={deleteUsage}
        onHide={() => setDeleteUsage(false)}
      />



    </div>
  )
}

export default SubAccountSetting
