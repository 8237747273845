import React from 'react'
import { Table, Card } from "react-bootstrap";
import { TbRouteAltLeft } from "react-icons/tb";
import { TbRouteAltRight } from "react-icons/tb";
import { TbClockStop } from "react-icons/tb";
import { TbRouteSquare } from "react-icons/tb";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { TbRouteSquare2 } from "react-icons/tb";
import { IoSpeedometerOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { PiClockCounterClockwiseLight } from "react-icons/pi";
import { LuFuel } from "react-icons/lu";
import { BsFuelPump } from "react-icons/bs";
import { PiEngineBold } from "react-icons/pi";
import { SiGoogleearthengine } from "react-icons/si";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaTrailer } from "react-icons/fa";

const DrivesAndStopsWithLogic = () => {
  return (
    <div>
       <div>
            <div>
                <div class="card">
                    <div class="card-body" style={{ minHeight: '40px' }}>
                        <div class="row">
                            <div class="col">
                                <h6>Drives And Stops with logic sensor -  Object:	10H10-9400</h6>
                            </div>
                            <div class="col-md-auto">
                                <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                            </div>
                        </div>
                    </div>
                    <Card style={{ marginTop: '-18px' }}>
                        <Card.Body >
                            <div className="table-responsive" style={{ marginTop: '10px' }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th >Status</th>
                                            <th  >Start</th>
                                            <th>End</th>
                                            <th>Duration</th>
                                            <th>Fuel consumption</th>
                                            <th>Avg. fuel cons.</th>
                                            <th>Length</th>
                                            <th>Top speed</th>
                                            <th>Average speed</th>
                                            <th>Fuel consumption</th>
                                            <th>Fuel cost</th>
                                            <th>Engine idle</th>
                                            <th>Battery Voltage</th>
                                            <th>Driver</th>
                                            <th>Trailer</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                         

                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                          

                                           
                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                            <td>11:00</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>
                                            <td>38</td>
                                            <td>0</td>
                                         

                                          
                                        </tr>



                                    </tbody>
                                </Table>
                            </div>


                        </Card.Body>
                    </Card>

                    <div class="card-body">
          <div class="row">
            <div class="col-4"><div class="card" style={{ width: '27rem' }}>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><TbRouteAltLeft />{" "}Route start</div>
                    <div class=" bd-highlight">2023-12-16 00:00:04</div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><LiaTruckMovingSolid />{" "}Move duration</div>
                    <div class=" bd-highlight">2023-12-16 00:00:04</div>
                  </div></li>
                <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                  <div class=" bd-highlight "><AiOutlineStop />{" "}Stop count</div>
                  <div class=" bd-highlight">6.91 km</div>
                </div></li>
              </ul>
            </div></div>
            <div class="col-4" >
              <div class="card" >
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><TbRouteSquare />{" "}Route end</div>
                      <div class=" bd-highlight">2023-12-16 10:45:03</div>
                    </div>
                  </li>
                  <li class="list-group-item" >
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><TbRouteSquare2 />{" "}Route length</div>
                      <div class=" bd-highlight">6.91 km</div>
                    </div></li>
                  <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Overspeed count</div>
                    <div class=" bd-highlight">6.91 km</div>
                  </div></li>
                </ul>
              </div>
            </div>
            <div class="col-4">
              <div class="card" style={{ width: '27rem' }}>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><TbClockStop />{" "}Average speed</div>
                      <div class=" bd-highlight">10 h 6 min 17 s</div>
                    </div>
                  </li>
                  <li class="list-group-item" >
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><IoSpeedometerOutline />{" "}Top speed</div>
                      <div class=" bd-highlight">6.91 km</div>
                    </div>
                  </li>
                  <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><LuFuel />{" "}Fuel consumption</div>
                    <div class=" bd-highlight">6.91 km</div>
                  </div></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-4"><div class="card" style={{ width: '27rem' }}>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><BsFuelPump />{" "}Avg. fuel cons. (100 km)</div>
                    <div class=" bd-highlight">2023-12-16 00:00:04</div>
                  </div>
                </li>
                <li class="list-group-item" >
                  <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><LuFuel />{" "}Fuel cost</div>
                    <div class=" bd-highlight">2023-12-16 00:00:04</div>
                  </div></li>
                <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                  <div class=" bd-highlight "><SiGoogleearthengine />{" "}Engine work</div>
                  <div class=" bd-highlight">6.91 km</div>
                </div></li>
              </ul>
            </div></div>
            <div class="col-4">
              <div class="card" style={{ width: '27rem' }}>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><PiEngineBold />{" "}Engine idle</div>
                      <div class=" bd-highlight">2023-12-16 10:45:03</div>
                    </div>
                  </li>
                  <li class="list-group-item" >
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><BsSpeedometer2 />{" "}Odometer</div>
                      <div class=" bd-highlight">6.91 km</div>
                    </div></li>
                  <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Engine hours</div>
                    <div class=" bd-highlight">6.91 km</div>
                  </div></li>
                </ul>
              </div>
            </div>
            <div class="col-4">
              <div class="card" style={{ width: '27rem' }}>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><TbClockStop />{" "}Driver</div>
                      <div class=" bd-highlight">10 h 6 min 17 s</div>
                    </div>
                  </li>
                  <li class="list-group-item" >
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><FaTrailer />{" "}Trailer</div>
                      <div class=" bd-highlight">6.91 km</div>
                    </div>
                  </li>
                  <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><LuFuel />{" "}Fuel consumption</div>
                    <div class=" bd-highlight">6.91 km</div>
                  </div></li>
                </ul>
              </div>
            </div>
          </div>


        </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default DrivesAndStopsWithLogic
