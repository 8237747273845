import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table, Modal } from 'react-bootstrap';
import "./Style.css"
import EditObjectModal from './EditObjectModal';
import DeleteObjectModal from './DeleteObjectModal';




const ObjectSetting = (props) => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  //states for managing input data
  const [notifySystemMessage, setNotifySystemMessage] = useState(props.addEventData?.notify?.system?.msg)
  const [notifySystemAutoHide, setNotifySystemAutoHide] = useState(props.addEventData?.notify?.system?.auto_hide)
  const [notifySystemSound, setNotifySystemSound] = useState(props.addEventData?.notify?.system?.sound)
  const [notifySystemSoundAlert, setNotifySystemSoundAlert] = useState(props.addEventData?.notify?.system?.sound_alert)
  const [pushNotification, setPushNotification] = useState(props.addEventData?.notify?.push)
  const [notifyEmail, setNotifyEmail] = useState(props.addEventData?.notify?.email?.enabled)
  const [notifyEmailAddress, setNotifyEmailAddress] = useState(props.addEventData?.notify?.email?.address)
  const [notifyPhone, setNotifyPhone] = useState(props.addEventData?.notify?.phone?.enabled)
  const [notifyPhoneNumber, setNotifyPhoneNumber] = useState(props.addEventData?.notify?.phone?.number)
  const [notifyWhatshpp, setNotifyWhatshpp] = useState(props.addEventData?.notify?.whatsApp?.enabled)
  const [notifyWhatshppNumber, setNotifyWhatshppNumber] = useState(props.addEventData?.notify?.whatsApp?.number)

  const handleClose = () => setShow(false);




  return (
    <div>
      <div>
        <div>

          <div>
            <div class="d-flex bd-highlight">
              <div class="p-2 flex-grow-1 bd-highlight">
                <span class="" style={{ marginTop: '30px', fontSize: '15px', marginLeft: '5px' }}>Notification
                  <hr style={{ marginTop: '8px', width: '100px', marginLeft: '8px' }}></hr>
                  <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '8px' }}></hr></span>
              </div>
            </div>
          </div>

          <form lg="12" style={{ marginTop: '10px', marginLeft: '12px' }}>
            <div class="row">
              <div class="col-6 col-md-4">System message</div>
              <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                checked={notifySystemMessage}
                onChange={() => {
                  setNotifySystemMessage(!notifySystemMessage);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      system: {
                        ...props.addEventData?.notify?.system,
                        msg: notifySystemMessage ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-4"></div>
            </div>

            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-4">Auto hide</div>
              <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                checked={notifySystemAutoHide}
                onChange={() => {
                  setNotifySystemAutoHide(!notifySystemAutoHide);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      system: {
                        ...props.addEventData?.notify?.system,
                        auto_hide: notifySystemMessage ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-4"></div>
            </div>

            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Sound alert</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }}
                checked={notifySystemSound}
                onChange={() => {
                  setNotifySystemSound(!notifySystemSound);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      system: {
                        ...props.addEventData?.notify?.system,
                        sound: notifySystemSound ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ width: '65%', marginLeft: '-35%' }}
                disabled={!notifySystemSound}
                value={notifySystemSoundAlert}
                onChange={(e) => {
                  setNotifySystemSoundAlert(e.target.value);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      system: {
                        ...props.addEventData?.notify?.system,
                        sound_alert: e.target.value
                      }
                    }
                  })
                }
                }
              >
                <option value="alarm1.mp3">alarm1.mp3</option>
                <option value="alarm2.mp3">alarm2.mp3</option>
                <option value="alarm3.mp3">alarm3.mp3</option>
                <option value="alarm4.mp3">alarm4.mp3</option>
                <option value="alarm5.mp3">alarm5.mp3</option>
              </select>
              </div>
              <div class="col-6 col-md-3"> <button type="button" class="btn btn-outline-primary" style={{ marginLeft: '-75%', width: '65%' }}>Primary</button>


              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-4">Push notification</div>
              <div class="col-6 col-md-4">  <input class="form-check-input" type="checkbox" id="flexCheckDefault" style={{ marginLeft: '20%', height: '25px', width: '20px' }}
                checked={pushNotification}
                onChange={() => {
                  setPushNotification(!pushNotification);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      push: pushNotification ? false : true
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-4"></div>
            </div>


            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Message toe-mail, for multiple email seprate by comma.</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }}
                checked={notifyEmail}
                onChange={() => {
                  setNotifyEmail(!notifyEmail);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      email: {
                        ...props.addEventData?.notify?.email,
                        enabled: notifyEmail ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3">
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="E-mail address" style={{ marginLeft: '-35%', minWidth: '332px' }}
                  disabled={!notifyEmail}
                  value={notifyEmailAddress}
                  onChange={(e) => {
                    setNotifyEmailAddress(e.target.value);
                    props.setAddEventData({
                      ...props.addEventData,
                      notify: {
                        ...props.addEventData?.notify,
                        email: {
                          ...props.addEventData?.notify?.email,
                          address: e.target.value
                        }
                      }
                    })
                  }
                  }
                />
              </div>
              <div class="col-6 col-md-3">
              </div>
            </div>

            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">SMS to mobile phone, for multiple phone number separate them by comma.</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }}
                checked={notifyPhone}
                onChange={() => {
                  setNotifyPhone(!notifyPhone);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      phone: {
                        ...props.addEventData?.notify?.phone,
                        enabled: notifyPhone ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3"> <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone number with code " style={{ marginLeft: '-35%', minWidth: '332px' }}
                disabled={!notifyPhone}
                value={notifyPhoneNumber}
                onChange={(e) => {
                  setNotifyPhoneNumber(e.target.value);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      phone: {
                        ...props.addEventData?.notify?.phone,
                        number: e.target.value
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Enable Whatshpp notification</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }}
                checked={notifyWhatshpp}
                onChange={() => {
                  setNotifyWhatshpp(!notifyWhatshpp);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      whatsApp: {
                        ...props.addEventData?.notify?.whatsApp,
                        enabled: notifyWhatshpp ? false : true
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3"> <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone number with code " style={{ marginLeft: '-35%', minWidth: '332px' }}
                disabled={!notifyWhatshpp}
                value={notifyWhatshppNumber}
                onChange={(e) => {
                  setNotifyWhatshppNumber(e.target.value);
                  props.setAddEventData({
                    ...props.addEventData,
                    notify: {
                      ...props.addEventData?.notify,
                      whatsApp: {
                        ...props.addEventData?.notify?.whatsApp,
                        number: e.target.value
                      }
                    }
                  })
                }
                }
              />
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">E-mail template</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ minWidth: '330px', marginLeft: '-35%' }}>
                <option selected>Default</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">SMS template</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ minWidth: '330px', marginLeft: '-35%' }}>
                <option selected>Default</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>

            <div class="p-2 flex-grow-1 bd-highlight">
              <span class="text-primary" style={{ marginTop: '30px', fontSize: '15px', marginLeft: '-10px' }}>Colors
                <hr style={{ marginTop: '8px', width: '100px', marginLeft: '-10px' }}></hr>
                <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '-10px' }}></hr></span>
            </div>


            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Object arrow color</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3"><select class="form-select" aria-label="Default select example" style={{ width: '330px', marginLeft: '-35%' }}>
                <option selected>Yellow</option>
                <option value="1">00:15</option>
                <option value="2">00:30</option>
                <option value="3">00:45</option>
                <option value="3">01:00</option>
              </select>
              </div>
              <div class="col-6 col-md-3">
              </div>

            </div>
            <div class="row" style={{ marginTop: '10px' }}>
              <div class="col-6 col-md-3">Object list color</div>
              <div class="col-6 col-md-3">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '64.5%', height: '25px', width: '20px' }} />
              </div>
              <div class="col-6 col-md-3">
                <input type="color" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="E-mail address" style={{ marginLeft: '-35%', width: '50%', height: '40px' }} />
              </div>
              <div class="col-6 col-md-3">
              </div>
            </div>
            {/* <div class="btn-group" style={{  marginTop: '20px' }}>
                    <button type="button" class="btn btn-primary" >Save</button>
                    <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>
                  </div> */}

          </form>

          {/* clear history modal */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '14px' }}>Clear History</Modal.Title>
            </Modal.Header>
            <Modal.Body> <span style={{ color: 'red' }}>Are you sure you want to clear history</span></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="danger" onClick={handleClose}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>


          {/* edit modal */}






        </div>





      </div>

      <EditObjectModal
        show={showEdit}
        onHide={() => setShowEdit(false)}
      />
      <DeleteObjectModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
      />



    </div>
  )
}

export default ObjectSetting
