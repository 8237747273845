import React from 'react'
import { Card } from 'react-bootstrap'


const MyAccount = () => {
  return (
    <div>
      <Card style={{ height: '20px', marginTop: '-20px' }}>

        <div class="card" style={{ paddingBottom: '20px' }}>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Contact information
          </div>
          <div class="row align-items-start">
            <div class="col" style={{ marginLeft: '20px' }}>
              Name, Surname
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Company
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Address
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Post code
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              City
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Country/State
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Phone number 1
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Phone number 2
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              E-mail
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Change password
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Old password
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              New password
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Repeat new password
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Change password
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Number of e-mails (daily)
            </div>
            <div class="col">
              0/10000
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Number of SMS (daily)
            </div>
            <div class="col">
              0/10000
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "5px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Number of API calls (daily)
            </div>
            <div class="col">
              0/10000
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            API
          </div>
          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              API Key
            </div>
            <div class="col">
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ width: '50%' }} />
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-sm" placeholder="B7E466D7B2BED26568BD271A6AA5" style={{ width: '8%', marginLeft: '20px', marginTop: '15px' }}>Save</button>
        </div>
      </Card>
    </div>
  )
}

export default MyAccount
