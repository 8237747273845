import React from 'react'
import { Button, Card, Modal, Table } from 'react-bootstrap';
import { IoIosClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";


const TaskModal = (props) => {
    return (
        <div>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ marginLeft: '10%' }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                        Task properties        </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="" style={{ marginTop: '15px', marginLeft: '15px' }}>
                        <div class="card-body text-primary" style={{ minHeight: '40px' }}>
                            <div class="row">
                                <div class="col-sm">
                                    Task
                                </div>
                                {/* <div class="col-sm">
                                    Time
                                </div> */}
                            </div>
                        </div>
                        <div class="row" style={{ marginTop: '' }}>
                            <div class="col-sm-8">
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Name
                                    </div>
                                    <div class="col" >
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Object
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>10H10-9400</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Priority
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>Low</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        Status
                                    </div>
                                    <div class="col" >
                                        <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                                            <option selected>New</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="card-body text-primary" style={{ minHeight: '40px' }}>
                                    <div class="row">
                                        <div class="col-sm">
                                            Start
                                        </div>
                                    </div>
                                </div>

                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Address
                                    </div>
                                    <div class="col" >
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>


                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        From
                                    </div>
                                    <div class="col" >
                                        <input type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col">
                                        To
                                    </div>
                                    <div class="col" >
                                        <input type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-6" style={{ marginLeft: '-17%' }}>
                                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                    <div class="col" style={{ marginTop: '5px' }}>
                                        Description
                                    </div>
                                    <div class="col" >
                                        <textarea type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '100%', height: '160px' }} />
                                    </div>
                                    <div class="card-body text-primary" style={{ minHeight: '40px' }}>
                                        <div class="row">
                                            <div class="col-sm">
                                                Destination
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                        <div class="col" style={{ marginTop: '5px' }}>
                                            Address
                                        </div>
                                        <div class="col" >
                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-25%', minWidth: '160px' }} />
                                        </div>
                                        <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                            <div class="col">
                                                From
                                            </div>
                                            <div class="col" >
                                                <input type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-10%', width: '100%' }} />
                                            </div>
                                        </div>
                                        <div class="row align-items-start" style={{ marginTop: '3px' }}>
                                            <div class="col">
                                                To
                                            </div>
                                            <div class="col" >
                                                <input type="datetime-local" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-10%', width: '100%' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>





                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={props.onHide}>Save</Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TaskModal
