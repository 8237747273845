import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';





//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import EventWebhook from './SmsSetting';
// import UserInterfaceSetting from './UserInterfaceSetting';
// import MyAccountSetting from './MyAccountSetting';
import SubAccountSetting from './SubAccountSetting';

function EditTable(props) {
  const [eventRoute, setEventRoute] = useState('');
  const [addEventData, setAddEventData] = useState({});
  const [eventsList, setEventList] = useState([]);
  const [addEventDevices, setAddEventDevices] = useState([]);
  const [addEventRoutes, setAddEventRoutes] = useState([]);
  const [addEventZones, setAddEventZones] = useState([]);




  useEffect(() => {
    const funcFetchRequiredData = async () => {
      try {
        var myHeaders = new Headers();

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${props.userNameForEvent}/event/required-data`, requestOptions)
        const result = await response.json();
        setEventList(result.event_types);
        setAddEventDevices(result.devices);
        console.log("required data", result)
      } catch (error) {
        console.log(error)
      }
    }
    funcFetchRequiredData()
  }, [])

  //function for adding a new event.
  const funcAddEvent = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(addEventData);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${props.userNameForEvent}/event`, requestOptions)
      const result = await response.json()
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '11%' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Event Properties
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div>

          <div className=" topnav mx-auto w-100 z-index-5 d-flex align-items-center justify-content-start">

            <ButtonGroup className="btn-group w-75" >

              <Button variant="link" className="btn btn-link btn-lg active" data-bs-toggle="tooltip" title="Objects setting."
                style={{ fontSize: '14px' }}
                onClick={() => setEventRoute('')}
              >Main</Button>

              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Events setting."
                style={{ fontSize: '14px' }}
                onClick={() => setEventRoute('TIME')}
              >Time</Button>

              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting"
                style={{ fontSize: '14px' }}
                onClick={() => setEventRoute('NOTIFICATION')}
              >Notification </Button>

              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting"
                style={{ fontSize: '14px' }}
                onClick={() => setEventRoute('WEBHOOK')}
              >Webhook </Button>

              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting"
                style={{ fontSize: '14px' }}
                onClick={() => setEventRoute('OBJECT-CONTROL')}
              >Object control </Button>

            </ButtonGroup>
          </div>

          {/* declaration of the route path manually */}

          {eventRoute === 'TIME' ? (
            <EventSetting
              addEventData={addEventData}
              setAddEventData={setAddEventData}
            />
          ) : eventRoute === 'NOTIFICATION' ? (
            <TemplateSetting
              addEventData={addEventData}
              setAddEventData={setAddEventData}
            />
          ) : eventRoute === 'WEBHOOK' ? (
            <EventWebhook
              addEventData={addEventData}
              setAddEventData={setAddEventData}
            />
          ) : eventRoute === 'OBJECT-CONTROL' ? (
            <SubAccountSetting />
          ) : (
            <ObjectSetting
              addEventData={addEventData}
              setAddEventData={setAddEventData}
              eventsList={eventsList}
              addEventDevices={addEventDevices}
            />
          )}


        </div>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>
        <button type="button" class="btn btn-primary" onClick={funcAddEvent}>Create</button>
        <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
}
export default EditTable;

