import React from 'react'
import { Card, Container, Col, Dropdown } from 'react-bootstrap';
import CustomToggle from '../../../../../components/dropdowns';

import Chart from "react-apexcharts";


const getVariableColor = () => {
  let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
  if (prefix) {
    prefix = prefix.trim()
  }
  const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
  const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
  const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
  const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
  return {
    primary: color1.trim(),
    info: color2.trim(),
    warning: color4.trim(),
    primary_light: color3.trim(),
  };
}

const variableColors = getVariableColor();
// const [checked, setChecked] = useState(true);
const colors = [variableColors.primary, variableColors.info];
// useEffect(() => {
//     return () => colors
// })

const chart1 = {
  options: {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "28%",
        endingShape: "rounded",
        borderRadius: 3,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 3,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 7,
    },
    xaxis: {
      categories: ["S", "M", "T", "W", "T", "F", "S"],
      labels: {
        minHeight: 20,
        maxHeight: 20,
        style: {
          colors: "#8A92A6",
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        minWidth: 20,
        maxWidth: 20,
        style: {
          colors: "#8A92A6",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
  },
  series: [
    {
      name: "Successful deals",
      data: [30, 50, 35, 60, 40, 60, 60],
    },
    {
      name: "Failed deals",
      data: [40, 50, 55, 50, 30, 80, 30],
    }
  ],
}
const chart2 = {
  options: {

    colors: colors,
    chart: {

      toolbar: {
        show: false,
      },
    },
    forecastDataPoints: {
      count: 3,
    },
    stroke: {
      width: 3,
    },
    grid: {
      show: true,
      strokeDashArray: 7,
    },
    markers: {
      size: 6,
      colors: "#FFFFFF",
      strokeColors: colors,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 0,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Sales",
      data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
    },
  ]
}
const SpeedGraph = () => {
  return (
    <div>
      <div class="card-body" style={{ minHeight: '40px' }}>
        <div class="card">
          <div class="card-body" style={{ minHeight: "20px" }}>
            <div class="row">
              <div class="col">
                <h6>Speed Graph -  Device:	10H10-9400</h6>
              </div>
              <div class="col-md-auto">
                <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
              </div>
            </div>
          </div>


        </div>

        <div class="col-12"> <Card className="card-block card-stretch card-height">
          <Card.Header>
            <div className=" d-flex justify-content-between  flex-wrap">
              <h6 className="card-title">Odometer Top 10 (km)<br></br>
                <p style={{ fontSize: '12px' }}>some title here</p>
              </h6>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                  This year
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">Year</Dropdown.Item>
                  <Dropdown.Item href="#">Month</Dropdown.Item>
                  <Dropdown.Item href="#">Week</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Card.Header>
          <Card.Body>
            <Chart options={chart2.options} series={chart2.series} type="line" height="100%" className="dashboard-line-chart"></Chart>
          </Card.Body>
        </Card></div>

      </div>
    </div>
  )
}

export default SpeedGraph
