import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table, Modal } from 'react-bootstrap';
import "./Style.css"
import EditObjectModal from './EditObjectModal';
import DeleteObjectModal from './DeleteObjectModal';


//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";
import { TfiEye } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";

import { FaEraser } from "react-icons/fa6";



const ObjectSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      {
        optionSelected === 'objects' ? (
          <div>
            <div>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title"></div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div class="row " style={{ marginTop: '-10px', marginLeft: '4px', }}>
                    <div class="col-md-5 ">
                      <div class="input-group">
                        <input class="form-control border " type="search" value="search" id="example-search-input" style={{ minWidth: '720px', borderRadius: '5px' }} />
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive" style={{ marginTop: '10px' }}>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                          <th style={{ paddingLeft: '0px', }} >Active</th>
                          <th >Name</th>
                          <th>IMEI</th>
                          <th>Expires On</th>
                          <th>Last Connection</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>

                          <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                          <td><FcCheckmark style={{ marginLeft: '-10px' }} /></td>
                          <td>SpeedoTrack</td>
                          <td>894758489498</td>
                          <td>2023-07-03 07:38:17</td>
                          <td>2023-07-03</td>
                          <td><svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 20 16" fill="none">
                            <g opacity="0.6">
                              <path d="M14.2534 9.71712C13.0841 8.67766 11.5739 8.10349 10.0094 8.10349C8.44479 8.10349 6.93463 8.67766 5.76527 9.71712C5.66646 9.80262 5.58562 9.9069 5.52745 10.0239C5.46928 10.1409 5.43494 10.2683 5.42642 10.3987C5.41789 10.5291 5.43536 10.6599 5.47781 10.7834C5.52025 10.907 5.58683 11.0209 5.67367 11.1186C5.7605 11.2162 5.86588 11.2956 5.98366 11.3522C6.10145 11.4088 6.2293 11.4414 6.35979 11.4481C6.49028 11.4549 6.62082 11.4356 6.74381 11.3915C6.8668 11.3474 6.9798 11.2792 7.07624 11.1911C7.88443 10.4728 8.9281 10.076 10.0094 10.076C11.0906 10.076 12.1343 10.4728 12.9425 11.1911C13.1381 11.3649 13.3947 11.454 13.6559 11.4386C13.9171 11.4232 14.1616 11.3047 14.3354 11.1091C14.5093 10.9135 14.5983 10.6569 14.5829 10.3957C14.5675 10.1344 14.449 9.89003 14.2534 9.71618V9.71712Z" fill="#00B33D" />
                              <path d="M10.0087 4.05238C7.3603 4.04873 4.81026 5.05531 2.87843 6.86689C2.78062 6.95451 2.70128 7.06077 2.64508 7.17945C2.58888 7.29813 2.55694 7.42683 2.55114 7.55802C2.54533 7.6892 2.56578 7.82022 2.61127 7.9434C2.65677 8.06658 2.72641 8.17944 2.8161 8.27535C2.90579 8.37126 3.01372 8.4483 3.13358 8.50194C3.25343 8.55559 3.38279 8.58477 3.51407 8.58776C3.64535 8.59076 3.7759 8.56751 3.89808 8.51939C4.02025 8.47126 4.13159 8.39923 4.22556 8.3075C5.79415 6.841 7.86131 6.02519 10.0087 6.02519C12.156 6.02519 14.2232 6.841 15.7918 8.3075C15.8857 8.39923 15.9971 8.47126 16.1192 8.51939C16.2414 8.56751 16.372 8.59076 16.5033 8.58776C16.6345 8.58477 16.7639 8.55559 16.8837 8.50194C17.0036 8.4483 17.1115 8.37126 17.2012 8.27535C17.2909 8.17944 17.3605 8.06658 17.406 7.9434C17.4515 7.82022 17.472 7.6892 17.4662 7.55802C17.4604 7.42683 17.4284 7.29813 17.3722 7.17945C17.316 7.06077 17.2367 6.95451 17.1389 6.86689C15.2071 5.05531 12.657 4.04873 10.0087 4.05238Z" fill="#00B33D" />
                              <path d="M19.6716 3.68418C17.0122 1.31137 13.5727 0 10.0087 0C6.44459 0 3.00511 1.31137 0.34573 3.68418C0.245783 3.76956 0.163907 3.87405 0.104915 3.99152C0.0459228 4.10898 0.0110039 4.23706 0.00220997 4.36821C-0.006584 4.49936 0.0109244 4.63095 0.0537064 4.75524C0.0964885 4.87953 0.163681 4.99402 0.251336 5.09197C0.338991 5.18993 0.44534 5.26937 0.564134 5.32564C0.682929 5.38191 0.811771 5.41387 0.943092 5.41964C1.07441 5.42542 1.20556 5.40488 1.32884 5.35925C1.45211 5.31361 1.56502 5.2438 1.66092 5.15391C3.95858 3.10474 6.92972 1.9723 10.0084 1.9723C13.0871 1.9723 16.0583 3.10474 18.3559 5.15391C18.5509 5.32832 18.8073 5.41811 19.0685 5.40353C19.3297 5.38895 19.5744 5.2712 19.7488 5.07618C19.9233 4.88115 20.013 4.62484 19.9985 4.36361C19.9839 4.10238 19.8661 3.85765 19.6711 3.68324L19.6716 3.68418Z" fill="#E1DCD2" />
                              <path d="M10.0087 15.4058C10.8388 15.4058 11.5117 14.7328 11.5117 13.9027C11.5117 13.0726 10.8388 12.3996 10.0087 12.3996C9.17854 12.3996 8.50558 13.0726 8.50558 13.9027C8.50558 14.7328 9.17854 15.4058 10.0087 15.4058Z" fill="#00B33D" />
                            </g>
                          </svg></td>


                          <td>
                            <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Clear History" onClick={handleShow}>
                              <FaEraser style={{ width: '23px' }} />
                            </a>

                            <a class="delete" data-toggle="modal" title="Edit"  onClick={() => setShowEdit(true)} ><TfiPencil style={{ width: '23px' }} /></a>

                            <a class="delete" data-toggle="modal" title="Delete"  onClick={() => setShowDelete(true)}><TfiTrash style={{ width: '23px' }} /></a>



                          </td>
                        </tr>



                      </tbody>
                    </Table>
                  </div>

                  {/* clear history modal */}

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{ fontSize: '14px' }}>Clear History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> <span style={{ color: 'red' }}>Are you sure you want to clear history</span></Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        No
                      </Button>
                      <Button variant="danger" onClick={handleClose}>
                        Yes
                      </Button>
                    </Modal.Footer>
                  </Modal>


                  {/* edit modal */}



                </Card.Body>






              </Card>
            </div>


          </div>







        ) : (
          <h4>Unknown</h4>
        )
      }

      <EditObjectModal
        show={showEdit}
        onHide={() => setShowEdit(false)}
      />
       <DeleteObjectModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
      />



    </div>
  )
}

export default ObjectSetting
