import React, { useRef } from 'react'

const ScrollTest = () => {
    const containerRef = useRef(null);

    const handleScroll = (e) => {
        const delta = Math.max(-1, Math.min(1, e.nativeEvent.wheelDelta || -e.nativeEvent.detail));
        containerRef.current.scrollLeft -= delta * 40; 
        e.preventDefault();
    };



    return (
        <div style={{ display: 'flex', overflowX: 'scroll', width: '300px', height: '150px' }}
            ref={containerRef}
            onWheel={handleScroll}
        >
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
            <div style={{ width: '200px', height: '40px', backgroundColor: 'green', marginRight: '30px' }}>
                this is the card
            </div>
        </div>
    )
}

export default ScrollTest
