import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    monitorData : []
}

const trackingSlice = createSlice({
    name: "tracking",
    initialState,
    reducers:{
        setTrackingData : (state, action) => {
            state.monitorData = action.payload;
        }
    }
})


export default trackingSlice.reducer;
export const {setTrackingData} = trackingSlice.actions;