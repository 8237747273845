import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{marginLeft:'10%'}}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div class="p-2 flex-grow-1 bd-highlight">
                <span class="text-primary" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '' }}>Vehicle Information
                  <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                  <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

                </span>
              </div>

            <form lg="12" style={{ marginTop: '20px' }}>
                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Driver Name:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Plate Number: </label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>

                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>SN:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Engine. No.</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Device Brand</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>

                  <div class="form-group col-md-4"style={{ marginLeft: '8px' }} >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Phone Number:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>ID Number:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Vin Number:</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}

                    >
                      <option selected>Manager</option>
                      <option value="calibration">Calibration</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>

                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Fuel/100km:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Vehicle Model</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> ICCID</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>

                  <div class="form-group col-md-4"style={{ marginLeft: '8px' }} >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}> Device IMEI</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <span class="text-primary" style={{  marginTop: '35px', fontSize: '15px',  }}>Install Information
                      <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                      <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

                    </span>
                  </div>

                  

                </div>

                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Installation Time:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Install company:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>

                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Installer:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Install Address:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Install Position:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>

                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Install picture:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div>

                


                

                

                
                
                
                <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary">Edit</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div>



              </form>
            </Modal.Body>

        </Modal>
    );
}



export default MyVerticallyCenteredModal;