import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModals(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            style={{ marginLeft: '10%' }}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '14px' }}>Expenses properties</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Modal.Body>
                    <div class="row">
                        <div class="col-sm text-primary">
                            Expense
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6" style={{ marginTop: '15px' }}>
                            <div class="row" >
                                <div class="col">Name</div>
                                <div class="col"><input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '5px' }}>
                                <div class="col">Date</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '1px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '5px' }}>
                                <div class="col">Quantity</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '1px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '5px' }}>
                                <div class="col">Cost</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '1px', width: '80%' }} /><span style={{ marginLeft: '8px', marginTOp: '8px' }}>USD</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '5px' }}>
                                <div class="col">Supplier</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '1px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '5px' }}>
                                <div class="col">Buyer</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '1px', width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row" style={{ marginTop: '18px' }}>
                                <div class="col">Objects</div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '18px' }}>
                                <div class="col">Odometer (mi)
                                </div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '18px' }}>
                                <div class="col">Engine hours (h)
                                </div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '18px' }}>
                                <div class="col">Description
                                </div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                                    </div>
                                </div>
                            </div>
                            <div class="row" style={{ marginTop: '18px' }}>
                                <div class="col">Update last service
                                </div>
                                <div class="col">
                                    <div class="col d-flex">
                                        <textarea class="" type="" value="" id="flexCheckDefault" style={{ marginLeft: '-37px', }} />                  </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cancel</Button>
                <Button onClick={props.onHide}>Save</Button>

            </Modal.Footer>
        </Modal>
    );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }
export default MyVerticallyCenteredModals