import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Form, Spinner } from 'react-bootstrap';
import "./DeviceStyle.css"


import MyVerticallyCenteredModal from './EditAccountView';


const ObjectSetting = (props) => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1');
  const [modalShow, setModalShow] = React.useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  //variables for holding the data of the form
  const [name, setName] = useState(props?.deviceData?.device?.name);
  const [imei, setImei] = useState(props?.deviceData?.device?.imei);
  const [port, setPort] = useState(props?.deviceData?.device?.port);
  const [protocol, setProtocol] = useState(props?.deviceData?.device?.protocol);
  const [netProtocol, setNetProtocol] = useState(props?.deviceData?.device?.net_protocol);
  const [ip, setIp] = useState(props?.deviceData?.device?.ip);
  const [active, setActive] = useState(props?.deviceData?.device?.active);











  return (
    <div>

      <div>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title"></div>
        </Card.Header>
        <div>
          <div class="d-flex bd-highlight">


          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Form.Check
            type="checkbox"
            id={`default-checkbox`}
            label={`Edit`}
            onChange={() => { setCanEdit(!canEdit) }}
          />
        </div>
        <form lg="12" style={{ marginTop: '20px' }}>
          <div class="form-row d-flex">
            <div class="form-group col-md-6">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
              <input type="text" class="form-control" id="inputEmail4"
                placeholder="vehicle name" style={{ borderRadius: '5px' }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    name: e.target.value
                  });
                  console.log(props.patchData)
                }}
                disabled={canEdit ? true : false}
              />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Imei`</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="vehicle imei"
                style={{ borderRadius: '5px' }}
                value={imei}
                onChange={(e) => {
                  setImei(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    imei: e.target.value
                  })
                }}
                disabled
              />
            </div>

          </div>
          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Creation Date</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="device creation date"
                style={{ borderRadius: '5px' }}
                value={props?.deviceData?.device?.createdAt.slice(0, 11)}
                disabled
              />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Expiration Date</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="device expiry date" style={{ borderRadius: '5px' }} value={props?.deviceData?.device?.expire_dt.slice(0, 11)} disabled />
            </div>
          </div>
          <div class="form-row d-flex" >
            <div class="form-group col-md-6">
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Last Updated</label>
              <input type="text" class="form-control" id="inputEmail4" placeholder="device last updated" style={{ borderRadius: '5px' }} value={props?.deviceData?.device?.updatedAt.slice(0, 11)} disabled />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Port</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="device port"
                style={{ borderRadius: '5px' }}
                value={port}
                onChange={(e) => {
                  setPort(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    port: e.target.value
                  })
                }}
                disabled
              />
            </div>
          </div>


          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>Protocol</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="device protocol"
                style={{ borderRadius: '5px' }}
                value={protocol}
                onChange={(e) => {
                  setProtocol(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    protocol: e.target.value
                  })
                }}
                disabled
              />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Net Protocol</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="device net protocol"
                style={{ borderRadius: '5px' }}
                value={netProtocol}
                onChange={(e) => {
                  setNetProtocol(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    net_protocol: e.target.value
                  })
                }}
                disabled
              />
            </div>
          </div>
          <div class="form-row d-flex" >
            <div class="form-group col-md-6" >
              <label for="inputEmail4" style={{ fontSize: '13px' }}>IP</label>
              <input type="email" class="form-control" id="inputEmail4" placeholder="device ip address"
                style={{ borderRadius: '5px' }}
                value={ip}
                onChange={(e) => {
                  setIp(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    ip: e.target.value
                  })
                }}
                disabled
              />
            </div>
            <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
              <label for="inputPassword4" style={{ fontSize: '13px' }}>Active</label>
              <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                value={active}
                onChange={(e) => {
                  setActive(e.target.value);
                  props.setPatchData({
                    ...props.patchData,
                    active: e.target.value
                  })
                }}
                disabled={canEdit ? true : false}
              >
                <option value={true}>True</option>
                <option value={false}>False</option>
                {/* <option value="2">User</option>
                      <option value="3">Sub Admin</option> */}
              </select>
            </div>``
          </div>
          {/* <div class="form-row d-flex" >
                <div class="form-group col-md-6" >
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>IMEI</label>
                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                      <option selected>Manager</option>
                      <option value="1">Super Admin</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>
                  
                  <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Subscription Expiration</label>
                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                  </div>
                </div> */}
          <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
            {!canEdit ? (
              <button type="button" class="btn btn-primary" onClick={() => { props.editDevice() }} disabled={isLoading ? true : false}>
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                  </>
                ) : null}
                Save
              </button>
            ) : null}
            <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

          </div>



        </form>

      </div>



      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  )
}


export default ObjectSetting;
