import React from 'react'
import { Card } from 'react-bootstrap'

const Massage = () => {
  return (
    <div>
      <Card style={{ height: '20px', marginTop: '-20px' }}>

        <div class="card" style={{paddingBottom:'20px'}}>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            SMS Gateway
          </div>
          <div class="row align-items-start">
            <div class="col" style={{ marginLeft: '20px' }}>
              Enable SMS Gateway
            </div>
            <div class="col">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            </div>
          </div>

          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              SMS Gateway Type
            </div>
            <div class="col">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            HTTP
          </div>
          <div style={{ marginLeft: '20px' }}>
            SMS Gateway, which can send messages via HTTP Get should be used.
          </div>
          <div style={{ marginLeft: '20px', marginTop: '15px' }}>
            SMS Gateway, URL example: http://SMS_GATEWAY/sendsms.php?
          </div>
          <div style={{ marginLeft: '20px', }}>
            username=USER&password=PASSWORD&number=%NUMBER%&message=%MESSAGE%
          </div>

          <div class="row align-items-start" style={{marginTop:"20px"}}>
            <div class="col" style={{ marginLeft: '20px', marginTop:'18px' }}>
               SMS Gateway URL
            </div>
            <div class="col" >
              <textarea class="" type="text"  id=""  placeholder='ex.http://full_address_here' style={{width:'80%', height:'100px'}}/>
            </div>
          </div>

          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            variables
          </div>
          <div style={{ marginLeft: '20px' }}>
            %NUMBER%-phone number, where SMS will be sent
          </div>
          <div style={{ marginLeft: '20px', marginTop: '15px' }}>
           %MESSAGE%-text of SMS message
          </div>
          <button type="button" class="btn btn-primary" style={{width:'8%', marginLeft:'20px', marginTop:'15px'}}>Save</button>

        </div>
      </Card>
    </div>
  )
}

export default Massage
