import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import allData from 'country-currency-list';

import "./Style.css"


const ObjectSetting = (props) => {
  const [canEdit, setCanEdit] = useState(false)

  //state variables for form inputs
  const [editUserName, setEditUserName] = useState(props.userDetails?.username);
  const [editUserEmail, setEditUserEmail] = useState(props.userDetails?.email);
  const [edituserActive, setEditUserActive] = useState(props.userDetails?.active);
  const [editUserPhone, setEditUserPhone] = useState(props.userDetails?.phone?.number)
  const [editUserExpireDate, setEditUserExpireDate] = useState(props.userDetails?.account_expire_dt)
  const [editUserTimeZone, setEditUserTimeZone] = useState(props.userDetails?.timezone)
  const [editUserCurrency, setEditUserCurrency] = useState(props.userDetails?.currency)
  //state variables for holding map previlages
  const [editUserMapOsm, setEditUserMapOsm] = useState(props.userDetails?.map?.osm)
  const [editUserMapBing, setEditUserMapBing] = useState(props.userDetails?.map?.bing)
  const [editUserMapGoogle, setEditUserMapGoogle] = useState(props.userDetails?.map?.google)
  const [editUserMapStreet, setEditUserMapStreet] = useState(props.userDetails?.map?.google_street_view)
  const [editUserMapTraffic, setEditUserMapTraffic] = useState(props.userDetails?.map?.google_traffic)
  const [editUserMapMapBox, setEditUserMapMapBox] = useState(props.userDetails?.map?.mapbox)
  const [editUserMapYandex, setEditUserMapYandex] = useState(props.userDetails?.map?.yandex)




  return (
    <div>
      <div>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title"></div>
        </Card.Header>
        <div>
          <div class="d-flex bd-highlight">
            <div class="p-2 flex-grow-1 bd-highlight">
              <span class="" style={{ marginLeft: '1.5%', marginTop: '35px', fontSize: '15px', marginLeft: '22px' }}>
                User
                <hr style={{ marginTop: '8px', width: '150px' }}></hr>
                <hr style={{ marginTop: '-10px', width: '150px' }}></hr></span>
            </div>
            <Form.Check
              type="checkbox"
              id={`default-checkbox`}
              label="Edit"
              checked={canEdit}
              onChange={() => setCanEdit(!canEdit)}
            />
            <div class="p-2 flex-grow-1 bd-highlight">
              <span class="" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '220px' }}>Map Privileges
                <hr style={{ marginTop: '8px', width: '120px', marginLeft: '37%' }}></hr>
                <hr style={{ marginTop: '-10px', width: '120px', marginLeft: '37%' }}></hr></span>
            </div>
          </div>
        </div>
        <div class="row row-cols-2 g-3">
          <form lg="12" style={{ marginTop: '20px' }}>
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Active</label>
                <select class="form-select" aria-label="Default select example"
                  style={{ width: '98%' }}
                  disabled={!canEdit}
                  value={edituserActive}
                  onChange={(e) => {
                    setEditUserActive(e.target.value)
                    props.setPatchData({
                      ...props.patchData,
                      active: e.target.value
                    })
                  }}
                >
                  <option value={true}>true</option>
                  <option value={false}>false</option>

                </select>
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}> Username</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="username"
                  style={{ borderRadius: '5px' }}
                  disabled={!canEdit}
                  value={editUserName}
                  onChange={(e) => {
                    setEditUserName(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      username: e.target.value
                    })
                  }}
                />


              </div>
            </div>
            <div class="form-row d-flex" >
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>E-mail</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="user email"
                  style={{ borderRadius: '5px' }}
                  disabled={!canEdit}
                  value={editUserEmail}
                  onChange={(e) => {
                    setEditUserEmail(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      user_email: e.target.value
                    })
                  }}
                />

              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Phone Number</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="user id"
                  style={{ borderRadius: '5px' }}
                  disabled={!canEdit}
                  value={editUserPhone}
                  onChange={(e) => {
                    setEditUserPhone(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      phone: {
                        ...props.patchData.phone,
                        number: e.target.value
                      }
                    })
                  }}
                />
              </div>
            </div>

            <div class="form-row d-flex">
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Account Type</label>
                <select class="form-select" aria-label="Default select example"
                  style={{ width: '98%' }}
                  disabled={!canEdit}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                  <option value="super_admin">Super Admin</option>

                </select>
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Expiry on</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="user expire date"
                  style={{ borderRadius: '5px' }}
                  disabled={!canEdit}
                  value={editUserExpireDate?.slice(0, 10)}
                />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Time Zone</label>
                <select class="form-select" aria-label="Default select example"
                  style={{ width: '98%' }}
                  disabled={!canEdit}
                  value={editUserTimeZone}
                  onChange={(e) => {
                    setEditUserTimeZone(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      timezone: e.target.value
                    })
                  }}
                >
                  {timeZones.map((zone, index) => (
                    <option value={zone} key={index}>{zone}</option>
                  ))}
                </select>
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Currency</label>
                <Multiselect
                  disabled
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => { setEditUserCurrency(e);
                  props.setPatchData({
                    ...props.patchData,
                    currency: e
                  })
                  }}
                  selectedValues={[editUserCurrency]}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { setEditUserCurrency(e);
                  props.setPatchData({
                    ...props.patchData,
                    currency: e
                  })
                  }}
                  options={(allData()).map(currency => currency.currency)}
                  closeOnSelect={true}
                  selectionLimit={1}
                  placeholder={false}
                  style={{
                    chips: {
                      background: 'none',
                      color: 'grey'
                    },
                    searchBox: {
                      height: '37px',
                      backgroundColor: !canEdit ? '#e9ecef' : '',
                      border: !canEdit ? 'none' : ''
                    }
                  }}
                />
              </div>
            </div>

            <div class="form-row d-flex">
              <div class="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Date Created</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="user expire date"
                  style={{ borderRadius: '5px' }}
                  value={props.userDetails.createdAt?.slice(0, 10)}
                  disabled
                />
              </div>
              <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Date last Updated</label>
                <input type="text" class="form-control" id="inputPassword4" placeholder="user expire date"
                  style={{ borderRadius: '5px' }}
                  value={props?.userDetails?.updatedAt?.slice(0, 10)}
                  disabled
                />
              </div>
            </div>





          </form>
          <div class="col scroll">
            <div class="scroll">
              <form lg="12" style={{ marginTop: '4px' }}>
                <div class="form-row d-flex ">
                  <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>OSM Map</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapOsm}
                      onChange={(e) => {
                        setEditUserMapOsm(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            osm: e.target.value
                          }
                        });
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select></div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Bing Map</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapBing}
                      onChange={(e) => {
                        setEditUserMapBing(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            bing: e.target.value
                          }
                        });
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>


                  </div>
                </div>


                <div class="form-row d-flex" >
                  <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Google Map</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapGoogle}
                      onChange={(e) => {
                        setEditUserMapGoogle(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            google: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>

                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Google Street </label>
                    <select class="form-select" aria-label="Default selGoogleect example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapStreet}
                      onChange={(e) => {
                        setEditUserMapStreet(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            google_street_view: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>


                  </div>
                </div>

                <div class="form-row d-flex" >
                  <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Mapbox Maps</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapMapBox}
                      onChange={(e) => {
                        setEditUserMapMapBox(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            mapbox: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Yandex Map</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapYandex}
                      onChange={(e) => {
                        setEditUserMapYandex(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            yandex: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>

                  </div>
                </div>
                <div class="form-row d-flex" >
                  <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Google Traffic</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapTraffic}
                      onChange={(e) => {
                        setEditUserMapTraffic(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            google_traffic: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Yandex Map</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}
                      disabled={!canEdit}
                      value={editUserMapYandex}
                      onChange={(e) => {
                        setEditUserMapYandex(e.target.value);
                        props.setPatchData({
                          ...props.patchData,
                          map: {
                            ...props.patchData?.map,
                            yandex: e.target.value
                          }
                        })
                      }}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>

                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
            <button type="button" class="btn btn-primary" onClick={props.editUser}>Save</button>
            <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }} onClick={props.onHide}>Cancel</button>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ObjectSetting



const timeZones = [
  "UTC-12:00",
  "UTC-11:00",
  "UTC-10:00",
  "UTC-09:30",
  "UTC-09:00",
  "UTC-08:00",
  "UTC-07:00",
  "UTC-06:00",
  "UTC-05:00",
  "UTC-04:00",
  "UTC-03:30",
  "UTC-03:00",
  "UTC-02:00",
  "UTC-01:00",
  "UTC+00:00",
  "UTC+01:00",
  "UTC+02:00",
  "UTC+03:00",
  "UTC+03:30",
  "UTC+04:00",
  "UTC+04:30",
  "UTC+05:00",
  "UTC+05:30",
  "UTC+06:00",
  "UTC+06:30",
  "UTC+07:00",
  "UTC+08:00",
  "UTC+09:00",
  "UTC+09:30",
  "UTC+10:00",
  "UTC+11:00",
  "UTC+12:00",
  "UTC+13:00",
  "UTC+14:00"
]