import React, { useEffect, useState, useRef } from 'react'
import { Table, Modal, Pagination, Form } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import NewLoader from '../../Speedo/speedo-asset/Loader';
import { Image, Button, } from 'react-bootstrap';
import Fancytable from './DriverTable';
import { IoMdRefresh } from "react-icons/io";




const Driver = () => {
  const [isLoading, setIsLoading] = useState(false);
  //modals
  const [showEditUser, setShowEditUser] = useState(false)
  const [addModalShow, setAddModalShow] = useState(false)

  //data
  const [driversList, setDriversList] = useState([])
  //pagination
  const [pageNumber, setPageNumber] = useState('1');
  const [totalPages, setTotalPages] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState('20')


  //add driver 
  const [itemMissing, setItemMissing] = useState(false)
  const [addDriverName, setAddDriverName] = useState('')
  const [addDriverAssignId, setAddDriverAssignId] = useState('')
  const [addDriverIdn, setAddDriverIdn] = useState('')
  const [addDriverAddress, setAddDriverAddress] = useState('')
  const [addDriverPhone, setAddDriverPhone] = useState('')
  const [addDriverEmail, setAddDriverEmail] = useState('')
  const [addDriverDesc, setAddDriverDesc] = useState('')
  const fileInputRef = useRef(null);
  const [driverImage, setDriverImage] = useState()

  //edit driver
  const [editDataLoading, setEditDataLoading] = useState(false)
  const [driverData, setDriverData] = useState({})
  const [editDriverId, setEditDriverId] = useState('')
  const [editDriverName, setEditDriverName] = useState('')
  const [editDriverAssignId, setEditDriverAssignId] = useState('')
  const [editDriverIdn, setEditDriverIdn] = useState('')
  const [editDriverAddress, setEditDriverAddress] = useState('')
  const [editDriverPhone, setEditDriverPhone] = useState('')
  const [editDriverEmail, setEditDriverEmail] = useState('')
  const [editDriverDesc, setEditDriverDesc] = useState('')
  const [editDriverPhoto, setEditDriverPhoto] = useState()

  //delete driver
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [driverIdForDelete, setDriverIdForDelete] = useState('')

  useEffect(() => {
    setEditDriverName(driverData?.name);
    setEditDriverIdn(driverData?.idn);
    setEditDriverAssignId(driverData?.assign_id);
    setEditDriverAddress(driverData?.address);
    setEditDriverPhone(driverData?.phone);
    setEditDriverEmail(driverData?.email);
    setEditDriverDesc(driverData?.desc);
    // setEditDriverPhoto(driverData?.img_file)

  }, [driverData])


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };





  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setDriverImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setDriverImage(null);
      alert('Please select a valid image file.');
    }
  };





  // fetch drivers list
  const fetchDriversList = async () => {
    try {
      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/driver?page=${pageNumber}&rows=${rows}&sort=name&search-field=all&search-value=${searchValue}`, requestOptions)
      const result = await response.json()
      setDriversList(result?.result)
      setTotalPages(result?.pages)
      setIsLoading(false)
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    fetchDriversList();
  }, [pageNumber, searchValue, rows])


  const funcCreateDriver = async () => {

    if (!addDriverName || addDriverName === '') {
      setItemMissing(true)
    } else {
      setItemMissing(false)
      try {
        var formdata = new FormData();
        formdata.append("name", addDriverName);
        formdata.append("assign_id", addDriverAssignId);
        formdata.append("idn", addDriverIdn);
        formdata.append("address", addDriverAddress);
        formdata.append("phone", addDriverPhone);
        formdata.append("email", addDriverEmail);
        formdata.append("image-file", driverImage);
        formdata.append("desc", addDriverDesc);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        const response = await fetch("https://gps.speedotrack.com/api/user/device/driver", requestOptions)
        const result = await response.json()
        if (response.ok) {
          setAddModalShow(false);
          fetchDriversList();

          setAddDriverName('')
          setAddDriverAssignId('')
          setAddDriverIdn('')
          setAddDriverAddress('')
          setAddDriverPhone('')
          setAddDriverEmail('')
          setAddDriverDesc('')
          setDriverImage()
        }
        console.log(result)
      } catch (error) {
        console.log(error)
      }
    }

  }


  const funcFetchDriverData = async (driverId) => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/driver/${driverId}`, requestOptions)
      const result = await response.json();
      setDriverData(result)
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const funcEditDriver = async () => {
    try {
      setEditDataLoading(true)
      var formdata = new FormData();
      formdata.append("assign_id", editDriverAssignId);
      formdata.append("idn", editDriverIdn);
      formdata.append("address", editDriverAddress);
      formdata.append("email", editDriverEmail);
      formdata.append("desc", editDriverDesc);
      formdata.append("img", "");

      var requestOptions = {
        method: 'PATCH',
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/driver/${editDriverId}`, requestOptions)
      const result = await response.json()
      console.log(result)
      setEditDataLoading(false)
    } catch (error) {
      console.log(error)
    }
  }



  const funcDeleteDriver = async () => {
    try {
      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/driver/${driverIdForDelete}`, requestOptions)
      const result = response.json()
      console.log(result)
      setConfirmDelete(false);
      fetchDriversList()
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      <div class="">

        <div class="card-body">

          <div className="w-100 " >
            <div class="form-group has-search d-flex"
              style={{ marginTop: '5px', minWidth: '500px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span class="fa fa-search form-control-feedback"></span>

              <input type="text" class="form-control" placeholder="Search"
                style={{ marginTop: '-15px', height: '30px' }}
                className='w-25'
                value={searchValue}
                onChange={(e) => { setSearchValue(e.target.value) }}
              />

              <Form.Select size="sm" style={{ width: '70px' }} value={rows} onChange={(e) => { setRows(e.target.value) }}>
                <option value="05">05</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select>
            </div>


            {/* <Fancytable
              driversList={driversList}
            /> */}

            {isLoading ? (
              <NewLoader />
            ) : (

              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                    <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                    <th>Name</th>
                    <th>ID Number</th>
                    <th>Phone no.</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>


                  {driversList?.map((driver, index) => (
                    <tr>
                      <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                      <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                      <td>{driver?.name}</td>
                      <td>{driver?.idn}</td>
                      <td>{driver?.phone}</td>
                      <td>{driver?.desc}</td>
                      <td>
                        <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Duplicate" >
                          <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                        </a>{" "}

                        <a href="#" class="delete" data-toggle="modal" title="Edit" onClick={() => { funcFetchDriverData(driver?._id); setShowEditUser(true); setEditDriverId(driver?._id) }}><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                        <a href="#" class="delete" data-toggle="modal" title="Delete" onClick={() => { setConfirmDelete(true); setDriverIdForDelete(driver?._id) }}><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                        <a href="#" class="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            )}

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="link" onClick={fetchDriversList}><IoMdRefresh /></Button>
              <Pagination size="sm">
                <Pagination.Prev
                  disabled={pageNumber === '1' ? true : false}
                  onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                />
                {
                  (
                    <>
                      {Array.from({ length: totalPages }).map((_, i) => (
                        <Pagination.Item
                          key={i}
                          onClick={() => setPageNumber((i + 1).toString())}
                          active={pageNumber === (i + 1).toString() ? true : false}
                        >{i + 1}
                        </Pagination.Item>
                      ))}
                    </>
                  )
                }
                <Pagination.Next
                  disabled={pageNumber === totalPages.toString() ? true : false}
                  onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                />
              </Pagination>
            </div>
          </div>


        </div>


        <div class="card-footer d-flex bg-light" style={{ padding: "2px", marginBottom: '20px' }}>
          <button type="button" class="btn btn-primary btn-sm btn-block" title="Add" onClick={() => setAddModalShow(true)}><FiPlus style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Refresh" onClick={fetchDriversList}><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

        </div>
      </div>


      {/* Add User Modal */}
      {addModalShow ? (
        <Modal
          size="sm"
          show={addModalShow}
          onHide={() => setAddModalShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          ClassName="custom-modal"
          style={{ marginLeft: '-14%' }}

        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
              Add Object driver properties
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <div class="row align-items-start">
              <div class="col" >
                <img src={driverImage ? driverImage : require('./images/man.png')} style={{ width: '200px' }} />
                <div class="btn-group" role="group" aria-label="Basic example" style={{ marginTop: '20px', marginTop: '25%' }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    id="uploadInput"
                  />
                  <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }}
                    onClick={handleButtonClick}>Upload Image</button>

                  <button type="button" class="btn btn-secondary btn-sm"
                    style={{ minWidth: '100px', marginLeft: '10px' }}
                    onClick={() => { setDriverImage() }}
                  >Delete</button>

                </div>
              </div>
              <div class="col">
                <div class="" style={{ paddingBottom: '20px' }}>
                  <div class="row align-items-start">
                    <div class="col" style={{ marginLeft: '20px' }}>
                      Name
                    </div>
                    <div class="col">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverName}
                        onChange={(e) => { setAddDriverName(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      RFID or iButton
                    </div>
                    <div class="col">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverIdn}
                        onChange={(e) => { setAddDriverIdn(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      ID number
                    </div>
                    <div class="col">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverAssignId}
                        onChange={(e) => { setAddDriverAssignId(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      Address
                    </div>
                    <div class="col">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverAddress}
                        onChange={(e) => { setAddDriverAddress(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      Phone
                    </div>
                    <div class="col">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverPhone}
                        onChange={(e) => { setAddDriverPhone(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      E-mail
                    </div>
                    <div class="col">
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverEmail}
                        onChange={(e) => { setAddDriverEmail(e.target.value) }}
                      />
                    </div>
                  </div>
                  <div class="row align-items-start" style={{ marginTop: '5px' }}>
                    <div class="col" style={{ marginLeft: '20px' }}>
                      Description
                    </div>
                    <div class="col">
                      <textarea type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                        value={addDriverDesc}
                        onChange={(e) => { setAddDriverDesc(e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {itemMissing ? (
              <p className='text-danger'>* Name is mandatory.</p>
            ) : null}

            <div class="modal-footer">
              <button type="file" class="btn btn-secondary btn-sm" style={{ minWidth: '100px' }}
                onClick={funcCreateDriver}
              >Save</button>
              <button type="button" class="btn btn-secondary btn-sm"
                style={{ minWidth: '100px', marginLeft: '10px' }}
                onClick={() => { setAddModalShow(false) }}
              >Cancel</button>

            </div>


          </Modal.Body>
        </Modal>
      ) : null}



      {/* Edit User Modal */}
      {showEditUser ? (
        <Modal
          size="sm"
          show={showEditUser}
          onHide={() => setShowEditUser(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          ClassName="custom-modal"
          style={{ marginLeft: '-14%' }}

        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
              Edit Object driver properties
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {editDataLoading ? (
              <NewLoader />
            ) : (
              <div class="row align-items-start">
                <div class="col" >
                  <img src={require('./images/man.png')} style={{ width: '200px' }} />
                  <div class="btn-group" role="group" aria-label="Basic example" style={{ marginTop: '20px', marginTop: '25%' }}>
                    {/* <button type="file" class="btn btn-secondary btn-sm" style={{ minWidth: '100px' }}>Upload</button> */}
                    <input
                      type="file"
                      accept="image/*"
                      // onChange={handleEditFileSelect}
                      // ref={fileInputEditRef}
                      style={{ display: 'none' }}
                      id="uploadInput"
                    />
                    <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }} >Upload Image</button>
                    <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }} >Delete</button>

                  </div>
                </div>
                <div class="col">
                  <div class="" style={{ paddingBottom: '20px' }}>
                    <div class="row align-items-start">
                      <div class="col" style={{ marginLeft: '20px' }}>
                        Name
                      </div>
                      <div class="col">
                        <input type="name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverName}
                          onChange={(e) => setEditDriverName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        RFID or iButton
                      </div>
                      <div class="col">
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverIdn}
                          onChange={(e) => setEditDriverIdn(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        ID number
                      </div>
                      <div class="col">
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverAssignId}
                          onChange={(e) => { setEditDriverAssignId(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        Address
                      </div>
                      <div class="col">
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverAddress}
                          onChange={(e) => { setEditDriverAddress(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        Phone
                      </div>
                      <div class="col">
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverPhone}
                          onChange={(e) => setEditDriverPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        E-mail
                      </div>
                      <div class="col">
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverEmail}
                          onChange={(e) => { setEditDriverEmail(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div class="row align-items-start" style={{ marginTop: '5px' }}>
                      <div class="col" style={{ marginLeft: '20px' }}>
                        Description
                      </div>
                      <div class="col">
                        <textarea type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          style={{ minWidth: '200px' }}
                          value={editDriverDesc}
                          onChange={(e) => { setEditDriverDesc(e.target.value) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div class="modal-footer">
              <button type="file" class="btn btn-secondary btn-sm" style={{ minWidth: '100px' }}>Save</button>
              <button type="button" class="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }}>Cancel</button>

            </div>


          </Modal.Body>
        </Modal>
      ) : null}




      <Modal
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete driver ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={funcDeleteDriver}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Driver;
