import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoMdTime } from "react-icons/io";
import { IoIosSend } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import Multiselect from 'multiselect-react-dropdown';
import "./Style.css";




function SendNotificationModal(props) {
    const [addDeviceUserList, setAddDeviceUserList] = useState([]);
    const [userList, setUserList] = useState([])
    const [sendToOption, setSendToOption] = useState('All'); // Track selected option

    const [showMultiselect, setShowMultiselect] = useState(true); // State to control visibility

    const handleSelectChange = (e) => {
        const selectedOption = e.target.value;
        if (selectedOption === 'all user accounts') {
            setShowMultiselect(false); // Hide the Multiselect component
        } else {
            setShowMultiselect(true); // Show the Multiselect component
        }
    };


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            style={{ marginLeft: '7%' }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                    Send Notification
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="row">
                    <div class="col-4">Send To</div>
                    <div class="col-4"><select class="form-select" aria-label="Default select example" style={{ minWidth: '400px' }}
                        onChange={handleSelectChange}
                    >
                        <option value="all user accounts">All user accounts</option>
                        <option value="Selected user account">Selected user account</option>
                    </select></div>
                </div>
                <div class="row">
                    <div class="col-4">Subject</div>
                    <div class="col-4" style={{ marginTop: '5px' }}>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '400px' }} />
                    </div>
                </div>
                {showMultiselect && (
                    <div class="row">
                        <div class="col-4">Username</div>
                        <div class="col-4" style={{ marginTop: '5px' }}>
                            <Multiselect
                                isObject={false}
                                onKeyPressFn={function noRefCheck() { }}
                                onRemove={(e) => { setAddDeviceUserList(e) }}
                                onSearch={function noRefCheck() { }}
                                onSelect={(e) => { setAddDeviceUserList(e) }}
                                onChange={(e) => console.log(e.target.value)}
                                options={userList.map(user => user.username)}
                                placeholder=''
                                style={{ minWidth: '400px', height: '200px' }}
                            />
                        </div>
                    </div>
                )}
                <div class="row">
                    <div class="col-4">Massage</div>
                    <div class="col-4" style={{ marginTop: '5px' }}>
                        <textarea type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '400px', height: '200px' }} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">Status</div>
                    <div class="col-8" style={{ marginTop: '5px' }}>
                        <div class="d-flex">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Watsap
                                </label>

                            </div>
                            <div class="form-check" style={{marginLeft:'15px'}}>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    E-mail
                                </label>

                            </div>
                            <div class="form-check" style={{marginLeft:'15px'}}>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    SMS
                                </label>

                            </div>
                            <div class="form-check" style={{marginLeft:'15px'}}>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label" for="flexCheckDefault">
                                    Push notification
                                </label>

                            </div>
                            
                        </div>
                        </div>
                    </div>
            </Modal.Body><hr></hr>
            <Modal.Footer class="d-flex" style={{ marginLeft: '33%' }}>
                <button type="button" class="btn btn-light " style={{ backgroundColor: 'light' }}><IoMdTime /> Test</button>
                <button type="button" class="btn btn-light " style={{ marginLeft: '7px' }}><IoIosSend /> Send</button>
                <button type="button" class="btn btn-light " style={{ marginLeft: '7px' }}> <IoMdClose /> Cancel</button>

            </Modal.Footer>
        </Modal>
    );
}

export default SendNotificationModal

