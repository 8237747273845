import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import NewLoader from '../speedo-asset/Loader';



import { Routes, Route, Link, NavLink } from 'react-router-dom';
// import Card from '../../../components/bootstrap/card';



//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import SmsSetting from './SmsSetting';
import UserInterfaceSetting from './UserInterfaceSetting';
import MyAccountSetting from './MyAccountSetting';
import SubAccountSetting from './SubAccountSetting';


function EditTable(props) {
  //states
  const [editUserRoute, setEditUserRoute] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('got some error !')
  const [toggleCustomerChange, setToggleCustomerChange] = useState(false)
  const [userDetails, setUserDetails] = useState({});
  const [patchData, setPatchData] = useState({})

  //function for fetching the user details
  const fetchUserDetail = async () => {
    try {
      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${props.userNameForEdit}`, requestOptions)
      const result = await response.json()
      console.log(result)
      setUserDetails(result)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }


  useEffect(() => {
    fetchUserDetail()
  }, [toggleCustomerChange])



  //function for edit the user
  const editUser = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(patchData);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${props.userNameForEdit}`, requestOptions)
      const result = await response.json()
      if (!response.ok) {
        setAlertMessage(JSON.stringify(result))
        showAlert(true)
      }
      fetchUserDetail()
      alert(result.message)
      console.log(result)
      setIsLoading(false)
      props.setToggleChange(!props.toggleChange)
      console.log(result)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    console.log(patchData)
  }, [patchData])


  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '11%' }}
    >
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        <p>{alertMessage}</p>
      </Alert>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Edit User
        </Modal.Title>

      </Modal.Header>
      <Modal.Body>

        <div>
          <div className=" topnav mx-auto w-100 z-index-5 d-flex align-items-center justify-content-start">

            <ButtonGroup className="btn-group w-75" >
              <Button variant="link" className="btn btn-link btn-lg active" data-bs-toggle="tooltip" title="Objects setting."
                style={{ fontSize: '14px' }}
                onClick={() => setEditUserRoute('')}
              >Account</Button>


              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Events setting."
                style={{ fontSize: '14px' }}
                onClick={() => setEditUserRoute('CONTACT')}
              >Contact Information</Button>


              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting"
                style={{ fontSize: '14px' }}
                onClick={() => setEditUserRoute('OBJECT')}
              >Object </Button>


              <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting"
                style={{ fontSize: '14px' }}
                onClick={() => setEditUserRoute('USAGE')}
              >Usage </Button>

            </ButtonGroup>
          </div>

          {isLoading ? (
            <NewLoader />
          ) : (
            <>
              {
                editUserRoute === 'CONTACT' ? (
                  <EventSetting
                    userDetails={userDetails}
                    toggleCustomerChange={toggleCustomerChange}
                    setToggleCustomerChange={setToggleCustomerChange}
                    setPatchData={setPatchData}
                    patchData={patchData}
                    editUser={editUser}
                    onHide={props.onHide}
                  />
                ) : editUserRoute === 'OBJECT' ? (
                  <TemplateSetting
                    userDetails={userDetails}
                  />
                ) : editUserRoute === 'USAGE' ? (
                  <SmsSetting
                    userDetails={userDetails}
                  />
                ) : (
                  <ObjectSetting
                    userDetails={userDetails}
                    toggleCustomerChange={toggleCustomerChange}
                    setToggleCustomerChange={setToggleCustomerChange}
                    setPatchData={setPatchData}
                    patchData={patchData}
                    editUser={editUser}
                    onHide={props.onHide}
                  />
                )}
            </>

          )}

        </div>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>

      </Modal.Footer>
    </Modal>
  );
}
export default EditTable;

