import React, {useState} from 'react'
import { Table, } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { FcCheckmark } from "react-icons/fc";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import ScheduleModal from './ScheduleModal'

const Schedule = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <div>
      <div class="card" style={{ marginTop: '-20px' }}>
        <div class="card-body" style={{ minHeight: '25px', marginTop: '-15px' }}>
          <div className="table-responsive" style={{ marginTop: '10px' }}>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                  <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                  <th style={{ paddingLeft: '0px' }} >Status</th>
                  <th>Name</th>
                  <th>Schedule</th>
                  <th>gateway</th>
                  <th>Type</th>
                  <th>Command</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ marginLeft: '50px' }}>1</td>
                  <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                  <td><FcCheckmark style={{ marginLeft: '-10px' }} /></td>
                  <td>10-12-2023</td>
                  <td>SpeedoTrack</td>
                  <td>SpeedoTrack</td>
                  <td>demo@gmail.com</td>
                  <td>Admin</td>

                  <td>


                    <a href="#" class="delete" data-toggle="modal" title="Edit"  ><TfiPencil style={{ width: '23px' }} /></a>

                    <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>



                  </td>
                </tr>



              </tbody>
            </Table>
          </div>
        </div>
        <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
          <button type="button" class="btn btn-primary btn-sm btn-block" title="Add" onClick={() => setModalShow(true)}> <FiPlus style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Refresh"><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

        </div>
      </div>
      <ScheduleModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

    </div>
    </div>
  )
}

export default Schedule
