import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ActivateModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{marginLeft:'-6%'}}
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"  style={{fontSize:'14px'}}>
        Activate 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        <p style={{color:'green'}}>
        Are you sure you want to activate the device?
        </p>
      </Modal.Body>
      <Modal.Footer style={{paddingBottom:'20px'}}>
        <Button variant='success' onClick={props.onHide}>Yes</Button>
        <Button variant='secondary' onClick={props.onHide}>No</Button>

      </Modal.Footer>
    </Modal>
  );
}
export default ActivateModal;

