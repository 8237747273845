import React, { useState } from 'react'
import { Fragment, Row, Button, Dropdown, Table, Card, Modal } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { FcCheckmark } from "react-icons/fc";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import MyVerticallyCenteredModal from '../Speedo/MaintenanceModal';

const Maintainence = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div>
      <div class="card col-12" style={{ height: '70px' }}>
                <div class="card-body">
                    <h5 class="card-title"> <img src={require('./images/processing.png')} style={{width:'30px'}}/>  Maintenance</h5>
                </div>
            </div>
      <Card style={{ marginTop: '-18px' }}>
        <Card.Body >
          <div className="table-responsive" style={{ marginTop: '10px' }}>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                  <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                  <th>Object</th>
                  <th>Name</th>
                  <th>Odometer</th>
                  <th>Odometer Left</th>
                  <th>Engine Hours</th>
                  <th>Engine Hours Left</th>
                  <th>Days</th>
                  <th>Days Left</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ marginLeft: '50px' }}>1</td>
                  <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                  <td>SpeedoTrack</td>
                  <td>Maintenance</td>
                  <td>123.7 km</td>
                  <td>123.7 km</td>
                  <td>10 Hour</td>
                  <td>10 Hour</td>
                  <td>Monday</td>
                  <td>Tuesday</td>
                  <td>
                    <a href="#" class="delete" data-toggle="modal" title="Edit" ><TfiPencil style={{ width: '23px' }} /></a>
                    <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
        <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
          <button type="button" class="btn btn-primary btn-sm btn-block" title="Add" onClick={() => setModalShow(true)}> <FiPlus style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Refresh"><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

        </div>
      </Card>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      

    </div>
  )
}

export default Maintainence
