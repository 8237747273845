import React from 'react';
import "./Style.css"
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Object from "./Object";
import EventSettings from './EventSettings'
import Templates from './Templates';
import Massage from './Massage';
import Interface from './Interface';
import MyAccount from './MyAccount';


import { Card } from "react-bootstrap";


const Settings = () => {
    return (
        <div>

            <Card style={{ padding: '20px' }}>
                <div class="topnav">
                    <NavLink to="/speedotrack/settings" >Object</NavLink>
                    <NavLink to="/speedotrack/settings/event-settings" class="" >Events</NavLink>
                    <NavLink to="/speedotrack/settings/templates">Templates</NavLink>
                    <NavLink to="/speedotrack/settings/massage">SMS</NavLink>
                    <NavLink to="/speedotrack/settings/interface">User interface</NavLink>
                    <NavLink to="/speedotrack/settings/my-account">My account</NavLink>
                </div>
            </Card>
            <Routes>
                <Route path='/*' element={<Object />} />
                <Route path='/event-settings' element={<EventSettings />} />
                <Route path='/templates' element={<Templates />} />
                <Route path='/massage' element={<Massage />} />
                <Route path='/interface' element={<Interface />} />
                <Route path='/my-account' element={<MyAccount />} />
            </Routes>


        </div>
    )
}

export default Settings
