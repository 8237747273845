import Button from 'react-bootstrap/Button';
import {Modal, Table } from 'react-bootstrap';
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { MdDeleteOutline } from "react-icons/md";


function CustomeReport(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{marginLeft:'9%'}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'15px'}}>
          Custome Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="table-responsive" style={{ marginTop: '10px' }}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                        <th>Time</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Formats</th>
                        <th>Objects</th>
                        <th>Zones</th>
                        <th>Sensors</th>
                        <th>Daily</th>
                        <th>Weekly</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                        <td>10-12-2023</td>
                        <td>SpeedoTrack</td>
                        <td>demo@gmail.com</td>
                        <td>Admin</td>
                        <td>2023-07-03 07:38:17</td>
                        <td>2023-07-03</td>
                        <td>6578</td>
                        <td>64378</td>
                        <td>64378</td>

                        <td>
                         
                        <a href="#" class="delete" data-toggle="modal" title="Open"><TfiBookmarkAlt style={{ width: '23px' }} /></a>
                          <a href="#"class="delete" data-toggle="modal" title="Delete"  ><MdDeleteOutline  style={{ fontSize: '16px' }} /></a>


                        </td>
                      </tr>



                    </tbody>
                  </Table>
                </div>
            
      </Modal.Body>

    </Modal>
  );
}

export default CustomeReport;