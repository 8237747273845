import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TemplatesModal = (props) => {
    return (
        <div>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ marginLeft: '-12%' }}


            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                        Command properties
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row align-items-start">
                        <div class="col">
                            <div class="col-sm text-primary" style={{ marginLeft: '15px' }}>
                                Templates
                            </div>
                            <div class="" style={{ paddingBottom: '20px', marginTop: '15px' }}>
                                <div class="row align-items-start">
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Hide unused protocols
                                    </div>
                                    <div class="col">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />

                                    </div>
                                </div>
                                <div class="row align-items-start">
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Name
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Protocol
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Gateway
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Type
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }} />
                                    </div>
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '5px' }}>
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        Command
                                    </div>
                                    <div class="col">
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }} />
                                    </div>
                                </div>
                                <div class="col-sm text-primary" style={{ marginLeft: '15px' }}>
                                    Variables
                                </div>
                                <div class="row align-items-start" style={{ marginTop: '8px' }}>
                                    <div class="col" style={{ marginLeft: '20px' }}>
                                        %IMEI% - Object IMEI

                                    </div>
                                    <div class="col">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cancel</Button>
                    <Button onClick={props.onHide}>Save</Button>

                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default TemplatesModal
