import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination } from 'react-bootstrap';
import "./Style.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import MyVerticallyCenteredModal from './EditViewAccount';

const ObjectSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>


      {
        optionSelected === 'objects' ? (
          <div>
            <div>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title"></div>
              </Card.Header>
              <div>
                <div class="d-flex bd-highlight">
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <span class="" style={{ marginLeft: '1.5%', marginTop: '35px', fontSize: '15px', marginLeft: '22px' }}>User
                      <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                      <hr style={{ marginTop: '-10px', width: '100px' }}></hr></span>
                  </div>
                  <div class="p-2 flex-grow-1 bd-highlight">
                    <span class="" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '250px' }}>Account privileges
                      <hr style={{ marginTop: '8px', width: '100px', marginLeft: '37%' }}></hr>
                      <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '37%' }}></hr></span>
                  </div>
                </div>
              </div>
              <div class="row row-cols-2 g-3">
                <form lg="12" style={{ marginTop: '20px' }}>
                  <div class="form-row d-flex">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>Active</label>
                      <select class="form-select" aria-label="Default select example"
                        style={{ width: '98%' }}

                      >
                        <option selected>Manager</option>
                        <option value="calibration">Calibration</option>

                      </select>                  </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}> Username</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />


                    </div>
                  </div>
                  <div class="form-row d-flex" >
                    <div class="form-group col-md-6" >
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>E-mail</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                    </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}>Password</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />


                    </div>
                  </div>

                  <div class="form-row d-flex" >
                    <div class="form-group col-md-6" >
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>Privileges</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}>Expiry on</label>
                      <input type="date" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                    </div>
                  </div>





                </form>
                <div class="col scroll">
                  <div class="scroll">
                    <form lg="12" style={{ marginTop: '4px' }}>
                      <div class="form-row d-flex ">
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>OSM Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select></div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}> Bing Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>


                        </div>
                      </div>


                      <div class="form-row d-flex" >
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Google Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>

                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}>Google Maps Street View </label>
                          <select class="form-select" aria-label="Default selGoogleect example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>


                        </div>
                      </div>

                      <div class="form-row d-flex" >
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Mapbox Maps</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}>Yandex Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary" onClick={() => setModalShow(true)}>Edit</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div>
              </div>
            </div>
          </div>









        ) : (
          <h4>Unknown</h4>
        )
      }



      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />




    </div>
  )
}

export default ObjectSetting
