import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal, Table, Card } from 'react-bootstrap';
import { FaCarAlt } from "react-icons/fa";
import { FaCarSide } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { RiPoliceCarFill } from "react-icons/ri";
import { GrCar } from "react-icons/gr";
import Select from 'react-select';
import { MdOutlineSensors } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import "./Style.css";




function EditDeviceModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            top
            style={{ marginLeft: '10%', paddingBottom: '40px', }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                    <MdOutlineSensors /> {" "}Event Properties
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>




                <div class="container">
                    <div class="row">
                        <div class="col-sm">
                            <span class="text-primary">Event</span> <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                            <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

                            <form class="container" style={{ marginTop: '10px' }}>
                                <div class="form-row d-flex">
                                    <div class="form-group col-md-6">
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Event Type</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                            <option selected>Manager</option>
                                            <option value="1" >Callibration</option>
                                            <option value="2">User</option>
                                            <option value="3">Sub Admin</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Object</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                            <option selected>Manager</option>
                                            <option value="1">Super Admin</option>
                                            <option value="2">User</option>
                                            <option value="3">Sub Admin</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Depending on routes</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                            <option selected>Manager</option>
                                            <option value="1">Super Admin</option>
                                            <option value="2">User</option>
                                            <option value="3">Sub Admin</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Routes</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Depending on zones</label>
                                        <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                </div>

                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Zones</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Time Period (min)</label>
                                        <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                </div>
                                <div class="form-row d-flex" >
                                    <div class="form-group col-md-6" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Speed limit (kph)</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                    <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Active</label>
                                        <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                            <option selected>Manager</option>
                                            <option value="1">Super Admin</option>
                                            <option value="2">User</option>
                                            <option value="3">Sub Admin</option>
                                        </select>                                    </div>
                                </div>
                                {/* <span class="text-primary" >Event result preview</span> */}
                                {/* <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                                <hr style={{ marginTop: '-10px', width: '100px' }}></hr> */}
                                {/* <div class="form-row d-flex" style={{ marginTop: '10px' }}>
                                    <div class="form-group col-md-5" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Current Value</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>

                                    <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}><MdDoubleArrow style={{ marginTop: '-5px' }} />
                                    </button>
                                    <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Result</label>
                                        <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                </div> */}



                            </form>
                        </div>
                        <div class="col-sm">
                            <div class="form-check" style={{ marginBottom: '15px' }}>
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ padding: '10px' }} />
                                <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: '8px' }}>
                                    Exclude calculations out of the event range
                                </label>
                            </div>
                            <span class="text-primary">Event</span>
                            <Card style={{ marginTop: '10px', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px', borderRadius: '0px' }}>
                                <div className="table-responsive scroll" style={{ marginTop: '5px' }}>
                                    <Table striped hover size="sm" >
                                        <thead>
                                            <tr>
                                                <th>Source</th>
                                                <th>Value</th>
                                                <th></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>

                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>
                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>

                                            <tr>
                                                <td>Battery</td>
                                                <td style={{ marginRight: '10%' }}>io66</td>
                                                <td><IoIosClose /></td>
                                            </tr>




                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                            <div class="form-row d-flex" style={{ marginTop: '-10px' }}>
                                <div class="form-group col-md-5" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Source</label>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                                <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Value</label>
                                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                                <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}><FaPlus style={{ marginTop: '-5px' }} /></button>

                            </div>
                        </div>

                        <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                            <button type="button" class="btn btn-primary">Save</button>
                            <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                        </div>

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
export default EditDeviceModal;

