import React from 'react'
import { Table, Card } from "react-bootstrap";


const Dtc = () => {
    return (
        <div>
            <div>
                <div class="card">
                    <div class="card-body" style={{ minHeight: '40px' }}>
                        <div class="card">
                            <div class="card-body" style={{ minHeight: "20px" }}>
                                <div class="row">
                                    <div class="col">
                                        <h6>DTC (Diagnostic Trouble Codes)


                                            -  Object:	10H10-9400</h6>
                                    </div>
                                    <div class="col-md-auto">
                                        <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Card style={{ marginTop: '-30px' }}>
                        <Card.Body >
                            <div className="table-responsive" style={{ marginTop: '10px' }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th >Object</th>
                                            <th>Code</th>
                                            <th>Position</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nothing has been found on your request.
                                            </td>






                                        </tr>






                                    </tbody>
                                </Table>
                            </div>


                        </Card.Body>
                    </Card>

                </div>
            </div>
        </div>
    )
}

export default Dtc

