import React, { useState } from 'react'
import { Card, Button, Dropdown } from 'react-bootstrap';
import { IoArrowForwardCircle } from "react-icons/io5";
import CustomToggle from '../../../../components/dropdowns';
import Chart from "react-apexcharts";
import "./style.css";
import Speed from './Speed';
import Engine from './Engine';
import DeviceBattery from './DeviceBattery';
import Altitude from './Altitude';
import Odometer from './Odometer';
import GsmLevel from './GsmLevel';
import { Link } from 'react-router-dom';


const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
    if (prefix) {
        prefix = prefix.trim()
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
        primary: color1.trim(),
        info: color2.trim(),
        warning: color4.trim(),
        primary_light: color3.trim(),
    };
}

const variableColors = getVariableColor();
// const [checked, setChecked] = useState(true);
const colors = [variableColors.primary, variableColors.info];
// useEffect(() => {
//     return () => colors
// })

const chart1 = {
    options: {
        chart: {
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "28%",
                endingShape: "rounded",
                borderRadius: 3,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 3,
            colors: ["transparent"],
        },
        grid: {
            show: true,
            strokeDashArray: 7,
        },
        xaxis: {
            categories: ["S", "M", "T", "W", "T", "F", "S"],
            labels: {
                minHeight: 20,
                maxHeight: 20,
                style: {
                    colors: "#8A92A6",
                },
            },
        },
        yaxis: {
            title: {
                text: "",
            },
            labels: {
                minWidth: 20,
                maxWidth: 20,
                style: {
                    colors: "#8A92A6",
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands";
                },
            },
        },
        responsive: [
            {
                breakpoint: 1025,
                options: {
                    chart: {
                        height: 130,
                    },
                },
            },
        ],
    },
    series: [
        {
            name: "Successful deals",
            data: [30, 50, 35, 60, 40, 60, 60],
        },
        {
            name: "Failed deals",
            data: [40, 50, 55, 50, 30, 80, 30],
        }
    ],
}

const chart2 = {
    options: {

        colors: colors,
        chart: {

            toolbar: {
                show: false,
            },
        },
        forecastDataPoints: {
            count: 3,
        },
        stroke: {
            width: 3,
        },
        grid: {
            show: true,
            strokeDashArray: 7,
        },
        markers: {
            size: 6,
            colors: "#FFFFFF",
            strokeColors: colors,
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 0,
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
            ],
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    },
    series: [
        {
            name: "Sales",
            data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
        },
    ]
}


const MonitorGraph = (props) => {
    const [mapRoute, setMapRoute] = useState();
    const [cardVisible, setCardVisible] = useState(true);

    const handleCancelClick = () => {
        setCardVisible(false);
    };


    return (
        <div>

            {/* graph */}

            <Button style={{ zIndex: '15' }} variant="" data-bs-toggle="tooltip" title="Collapse" onClick={props.setFullScreen}
            ><IoArrowForwardCircle style={{ marginLeft: '-32px', fontSize: '32px', zIndex: '10', }} className='text-primary' /></Button>
            {cardVisible && (
                <Card style={{ width: "100vw", display: "flex", overflowX: "hidden", height: '190px', marginBottom: '0px', borderRadius: '0px', overflowY: 'hidden' }} >
                    <div class="col-9">


                        <div className="topnav">
                            <Button variant="link" onClick={() => setMapRoute('SPEED')} class="active" style={{
                                borderBottom: '3px solid green', fontSize: '13px'
                            }}>Speed</Button>
                            <Button variant="link" onClick={() => setMapRoute('ALTITUDE')} style={{ fontSize: '13px' }}>Altitude</Button>
                            <Button variant="link" onClick={() => setMapRoute('ENGINE')} style={{ fontSize: '13px' }}>Engine</Button>
                            <Button variant="link" onClick={() => setMapRoute('ODOMETER')} style={{ fontSize: '13px' }}>Odometer</Button>
                            <Button variant="link" onClick={() => setMapRoute('DEVICEBATTERY')} style={{ fontSize: '13px' }}>Device Battery</Button>
                            <Button variant="link" onClick={() => setMapRoute('LEVEL')} style={{ fontSize: '13px' }}>Level</Button>


                            <span type="button" class="btn-close" aria-label="Close" style={{
                                marginTop: "15px", marginLeft: '45%', fontSize: '12px'
                            }} onClick={handleCancelClick}></span>


                        </div>


                        {
                            mapRoute === 'SPEED' ? (
                                <Speed />
                            ) : mapRoute === 'ALTITUDE' ? (
                                <Altitude />
                            ) : mapRoute === 'ENGINE' ? (
                                <Engine />
                            ) : mapRoute === 'ODOMETER' ? (
                                <Odometer />
                            ) : mapRoute === 'DEVICEBATTERY' ? (
                                <DeviceBattery />
                            ) : mapRoute === 'GSMLEVEL' ? (
                                <GsmLevel />
                            ) : (
                                <Speed />
                            )
                        }

                    </div>

                </Card>






            )}
        </div>
    )
}

export default MonitorGraph
