import React, { useState, useEffect } from "react";
import { Button, Table, Card, Dropdown } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { MdOutlineSensors } from "react-icons/md";
import { FaEraser } from "react-icons/fa6";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { FaPlus } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";
import { TbSettingsCog } from "react-icons/tb";
import EditCustomerEvent from './EditCustomerEvent';
import CustomerEventAdd from './CustomerEventAdd';
import { SlEvent } from "react-icons/sl";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import "./Style.css";
import EventProperties from './EventProperties';

//importing of the loader
import NewLoader from "../speedo-asset/Loader";



function MyVerticallyCenteredModal(props) {
    // const [showEdit, setShowEdit] = useState(false);
    // const [showAdd, setShowAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [eventProperties, setEventProperties] = useState(false);
    const [toggleEventChange, setToggleEventChange] = useState(false);
    const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
    const [showEdit, setShowEdit] = useState(false)
    //state variables for event setting
    const [eventList, setEventList] = useState([]);
    const [noOfEvents, setNoOfEvents] = useState(0);
    const [eventDeleted, setEventDeleted] = useState(false);
    const [eventId, setEventId] = useState('');


    //function for fetching event list
    const fetchEvents = async () => {
        try {
            setIsLoading(true)
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/cpanel/user/${props.userNameForEvent}/event`, requestOptions)
            const result = await response.json()
            setNoOfEvents(`(${result.total})`)
            setEventList(result.events);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        fetchEvents()
    }, [])



    //function for deleting the event
    const deleteEvent = async () => {
        try {
            var requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/user/event/${eventId}`, requestOptions)
            const result = await response.json();
            setEventDeleted(true);
            fetchEvents();
            setTimeout(() => {
                console.log(result);
                setShowDeleteEventModal(false);
            }, 3000)

        } catch (error) {
            console.log(error)
        }
    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal"
            centered
            style={{ marginTop: '-5%', marginLeft: '80px' }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal" style={{ fontSize: '15px' }}>
                    <SlEvent />{" "}Events{" "}{noOfEvents}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="row " style={{ marginTop: '-5px', marginLeft: '-10px', }}>
                    <div class="col-md-5 ">
                        <div class="input-group">
                            {/* <input class="form-control border " type="search" value="search" id="example-search-input" style={{ minWidth: '720px', borderRadius: '5px' }} /> */}
                        </div>
                    </div>
                </div>
                <div className="table-responsive" style={{ marginTop: '10px' }}>
                    {isLoading ? (
                        <NewLoader />
                    ) : (
                        <Table striped hover size="sm">
                            <thead>
                                <tr>
                                    {/* <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ padding: '6.5px' }} /></th> */}
                                    <th>Name</th>
                                    <th>Active</th>
                                    <th>Type</th>
                                    <th>Notify</th>
                                    <th>Email</th>
                                    <th>SMS</th>
                                    <th>Whatsapp</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventList?.map((event, index) => (
                                    <tr key={index}>
                                        {/* <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ padding: '6px' }} /></td> */}
                                        <td>{event.name}</td>
                                        <td>{event.active ? (<FcCheckmark style={{ marginLeft: '10px', }} />) : (<IoMdClose style={{ marginLeft: '10px', color: 'red' }} />)}</td>
                                        <td>{event.type}</td>
                                        <td>{event.push ? (<FcCheckmark style={{ marginLeft: '10px', }} />) : (<IoMdClose style={{ marginLeft: '10px', color: 'red' }} />)}</td>
                                        <td>{event.email ? (<FcCheckmark style={{ marginLeft: '10px', }} />) : (<IoMdClose style={{ marginLeft: '10px', color: 'red' }} />)}</td>
                                        <td>{event.phone ? (<FcCheckmark style={{ marginLeft: '10px', }} />) : (<IoMdClose style={{ marginLeft: '10px', color: 'red' }} />)}</td>
                                        <td>{event.whatsApp ? (<FcCheckmark style={{ marginLeft: '10px', }} />) : (<IoMdClose style={{ marginLeft: '10px', color: 'red' }} />)}</td>
                                        <td>
                                            <a class="delete" data-toggle="modal" title="Edit" onClick={() => { setShowEdit(true) }}><TfiPencil style={{ width: '23px', cursor: 'pointer' }} /></a>
                                            <a class="delete" data-toggle="modal" title="Delete" onClick={() => { setEventId(event._id); setShowDeleteEventModal(true) }}><TfiTrash style={{ width: '23px', color: 'red', cursor: 'pointer' }} /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </Modal.Body>
            <div>

            </div>
            <EditCustomerEvent
                show={showEdit}
                onHide={() => setShowEdit(false)}
            />

            {/* <CustomerEventAdd
                show={showAdd}
                onHide={() => setShowAdd(false)}
            /> */}


            {/* delete event modal */}
            {showDeleteEventModal ? (
                <DeleteEventModal
                    show={showDeleteEventModal}
                    onHide={() => setShowDeleteEventModal(false)}
                />
            ) : null}


            {/* Modal for adding a new event */}
            {eventProperties ? (
                <EventProperties
                    show={eventProperties}
                    onHide={() => setEventProperties(false)}
                    userNameForEvent={props.userNameForEvent}
                />
            ) : null}

            <Card style={{ padding: '7px', borderRadius: '0px' }}>
                <div class="btn-group" role="group" aria-label="Basic example">
                    <a href="#" onClick={() => setEventProperties(true)}>
                        <FaPlus title="Add" style={{ width: '35px' }} />
                    </a>
                    <a href="#" onClick={fetchEvents}><FcRefresh title="Refresh" style={{ marginLeft: '-5px', width: '35px', marginRight: '5px' }} /></a>


                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" style={{ marginLeft: '-15px', marginTop: '-3px' }}>
                            <TbSettingsCog />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="">Import</Dropdown.Item>
                            <Dropdown.Item href="">Export</Dropdown.Item>
                            <Dropdown.Item href="">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Card>



        </Modal>


    );




    function DeleteEventModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <h4>Delete Event</h4>
                </Modal.Header>
                <Modal.Body>
                    {eventDeleted ? (
                        <p className="text-success"> Event deleted successfully !  </p>
                    ) : (
                        <p className="text-danger">Are you sure you want to delete this event ?  </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteEvent} >Delete</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default MyVerticallyCenteredModal;






