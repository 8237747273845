import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table, Modal } from 'react-bootstrap';
import "./Style.css"
import EditObjectModal from './EditObjectModal';
import DeleteObjectModal from './DeleteObjectModal';


//importing of the icons from react icons
import { FcCancel } from "react-icons/fc";
import { FcCheckmark } from "react-icons/fc";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { FaEraser } from "react-icons/fa6";

import { FcAddRow } from "react-icons/fc";



const ObjectSetting = (props) => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      {
        optionSelected === 'objects' ? (
          <div>
            <div>

              <div class="row " style={{ marginTop: '-10px', marginLeft: '4px', }}>
                <div class="col-md-5 ">
                  <div class="input-group">
                    <input class="form-control border " type="search" value="search" id="example-search-input" style={{ minWidth: '720px', borderRadius: '5px' }} />
                  </div>
                </div>
              </div>

              <div className="table-responsive" style={{ marginTop: '10px' }}>
                <Table striped hover size="sm">
                  <thead>
                    <tr>
                      <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                      <th style={{ paddingLeft: '0px', }} >Active</th>
                      <th >Name</th>
                      <th>IMEI</th>
                      <th>Expires On</th>
                      <th>Last Connection</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.userDetails?.devices?.map((userDevice, index) => (
                      <tr key={index}>
                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                        <td>
                          {userDevice.active ? (
                            <FcCheckmark style={{ marginLeft: '-10px' }} />
                          ) : (
                            <FcCancel style={{ marginLeft: '-10px' }} />
                          )}
                        </td>
                        <td>{userDevice?.name}</td>
                        <td>{userDevice?.imei}</td>
                        <td>{userDevice?.expire_dt.slice(0, 10)}</td>
                        <td>{userDevice?.dt_tracker?.slice(0, 10)} - {userDevice?.dt_tracker?.slice(12, 20)}</td>
                        <td>{userDevice?.vehicle_status?.is_stopped ? (<FcCancel />) : userDevice?.vehicle_status?.is_stopped ? (<FcCheckmark />) : (<FcAddRow />)}</td>
                        <td>
                          <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Clear History" onClick={handleShow}>
                            <FaEraser style={{ width: '23px' }} />
                          </a>

                          <a class="delete" data-toggle="modal" title="Edit" onClick={() => setShowEdit(true)} ><TfiPencil style={{ width: '23px' }} /></a>

                          <a class="delete" data-toggle="modal" title="Delete" onClick={() => setShowDelete(true)}><TfiTrash style={{ width: '23px' }} /></a>
                        </td>
                      </tr>
                    ))
                    }



                  </tbody>
                </Table>
              </div>

              {/* clear history modal */}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title style={{ fontSize: '14px' }}>Clear History</Modal.Title>
                </Modal.Header>
                <Modal.Body> <span style={{ color: 'red' }}>Are you sure you want to clear history</span></Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    No
                  </Button>
                  <Button variant="danger" onClick={handleClose}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>


              {/* edit modal */}






            </div>





          </div>







        ) : (
          <h4>Unknown</h4>
        )
      }

      <EditObjectModal
        show={showEdit}
        onHide={() => setShowEdit(false)}
      />
      <DeleteObjectModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
      />



    </div>
  )
}

export default ObjectSetting
