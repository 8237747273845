import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



function DeviceDelete(props) {
  const [deleted, setDeleted] = useState(false)
  const funcForDeleteDevice = async () => {
    try {
      var myHeaders = new Headers();

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/cpanel/device/${props.imeiForDelete}`, requestOptions)
      if (response.ok) {
        props.setToggleChange(!props.toggleChange)
        setDeleted(true)

        setTimeout(() => {
          props.onHide()
          setDeleted(false)
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      alert(error.message)
    }
  }




  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '-6%' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Delete User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          deleted ? (
            <p style={{ color: 'green', fontSize: '14px' }}>
              Device deleted sucessfully
            </p>
          ) : (
            <p style={{ color: 'red', fontSize: '14px' }}>
              Are you sure you want to delete this device?
            </p>)
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={funcForDeleteDevice} style={{ fontSize: '14px' }}>Yes</Button>
        <Button variant='secondary' onClick={props.onHide} style={{ fontSize: '14px' }}>No</Button>

      </Modal.Footer>
    </Modal>
  );
}
export default DeviceDelete;

