import React from 'react'

// default layout
import Default from '../layouts/dashboard/default';

// Dashboard
import UserDashboard from '../views/dashboard/UserDashboard';
import Customer from '../views/dashboard/Speedo/Customer';
import Device from '../views/dashboard/Speedo/Device';
import MonitorScreen from '../views/dashboard/Speedo/Monitor/MonitorScreen';
import Setting from '../views/dashboard/Speedo/ModalComponents/Setting';
import EditDeviceTable from '../views/dashboard/Speedo/DeviceModal/EditDeviceTable';
import TestReport from '../views/dashboard/Speedo/report/report-components/TestReport';
import Settings from '../views/dashboard/SpeedoUserPanel/Settings/Settings';
import Maintenance from '../views/dashboard/Speedo/Maintenance';
import AdminDashboard from '../views/dashboard/AdminDashboard';
import Expenses from '../views/dashboard/Speedo/Expenses';
import ObjectControls from '../views/dashboard/Speedo/ObjectControl/ObjectControls';
import Camera from '../views/dashboard/Speedo/Camera';
import Task from '../views/dashboard/Speedo/Task';
import Coins from '../views/dashboard/Speedo/Coins/Coins';
import UnusedDevice from '../views/dashboard/Speedo/Unused-device';
import RolesAndPermission from '../views/dashboard/Speedo/RolesAndPermission';

import ScrollTest from '../views/dashboard/Speedo/ScrollTest';

export const DefaultRouter = [
    {
        path: "/speedotrack",
        element: <Default />,
        children: [
            {
                path: "/speedotrack/user-dashboard",
                element: <UserDashboard />
            },
            {
                path: "/speedotrack/customer/*",
                element: <Customer />
            },
            {
                path: "/speedotrack/device/*",
                element: <Device />
            },
            {
                path: "/speedotrack/monitor",
                element: <MonitorScreen />
            },
            // {
            //     path: "/setting",
            //     element: <Setting />
            // },
            {
                path: "/speedotrack/demo-report/*",
                element: <TestReport />
            },
            {
                path: "/speedotrack/settings/*",
                element: <Settings />
            },
            {
                path: "/speedotrack/maintenance",
                element: <Maintenance />
            },
            {
                path: "/speedotrack/admin-dashboard",
                element: <AdminDashboard />
            },
            {
                path: "/speedotrack/expenses",
                element: <Expenses />
            },
            {
                path: "/speedotrack/expenses",
                element: <Expenses />
            },
            {
                path: "/speedotrack/object-controls",
                element: <ObjectControls />
            },
            {
                path: "/speedotrack/camera",
                element: <Camera />
            },
            {
                path: "/speedotrack/task",
                element: <Task />
            },
            {
                path: "/speedotrack/coins",
                element: <Coins />
            },
            {
                path: "/speedotrack/unused-device",
                element: <UnusedDevice />
            },
            {
                path: "/speedotrack/roles-and-permision",
                element: <RolesAndPermission />
            },
            
        ]
    },
]
