import React from 'react';
import "./Sing.css";
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';



function Register() {
    const formStyle = {
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        color: 'black',
        width: '70%',
        float: 'right',
        marginTop: '130px'


    };



    return (
        <div>

            <div className="login-container" style={{ marginTop: '7%' }}>
                <div className="login-container" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', borderRadius: '15px', padding: '20px' }}>
                    <div className="onboarding">
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide color-1">
                                    <div className="slide-image">
                                    <img src={require('./images/bb.png')} />
                                    </div>
                                    <div className="slide-content" style={{ marginTop: '-30px' }}>
                                        <h5>SpeedoTrack GPS Tracking</h5>
                                        <p style={{ fontSize: '14px' }}>Consistent quality and eperience across all platform and devices</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login-form" >
                        <div className="login-form-inner float-right">
                            <div className="logo">
                                <img src={require("./images/logos.png")} style={{ width: '50%', marginBottom: '50px', marginTop: '18px', marginLeft: '25%' }} />
                            </div>

                            <div className="sign-in-seperator" style={{ marginTop: '-30px' }}>
                                <span>Login to your account</span>
                            </div>
                            <div className="login-form-group">
                                <label htmlFor="email">Name <span className="required-star">*</span></label>
                                <input type="text" placeholder="Enter email" id="email" />
                            </div>
                            <div className="login-form-group">
                                <label htmlFor="pwd">Email <span className="required-star">*</span></label>
                                <input autoComplete="off" type="text" placeholder="Minimum 8 characters" id="pwd" />
                            </div>
                            <div className="login-form-group">
                                <label htmlFor="pwd">Phone <span className="required-star">*</span></label>
                                <input autoComplete="off" type="text" placeholder="Minimum 8 characters" id="pwd" />
                            </div>
                            <div className="login-form-group">
                                <label htmlFor="pwd">Password <span className="required-star">*</span></label>
                                <input autoComplete="off" type="text" placeholder="Minimum 8 characters" id="pwd" />
                            </div>

                            <div className="login-form-group single-row">
                                <div className="custom-check">

                                </div>

                               
                                <br />
                            </div>

                            
                           

                            <Button className='rounded-button login-cta' style={{ marginTop: '-10px' }}>Register</Button>


                            <div className="register-div" style={{ marginLeft: '10%', marginTop: '-15px' }}>Already Have an Account? Click Here to<Link to="/sign-in" className="link create-account">__Login </Link></div>
                        </div>

                    </div>

                </div>

            </div>


            
        </div>
    );
}

export default Register;
