import React from 'react'
import { Table, Card } from "react-bootstrap";
import { AiOutlineStop } from "react-icons/ai";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { FcChargeBattery } from "react-icons/fc";
import { FcLowBattery } from "react-icons/fc";
import { MdAltRoute } from "react-icons/md";





const LogicSensor = () => {
    return (
        <div>
            <div>
                <div class="card">
                    <div class="card-body" style={{ minHeight: '40px' }}>
                        <div class="card">
                            <div class="card-body" style={{ minHeight: "20px" }}>
                                <div class="row">
                                    <div class="col">
                                        <h6>Logic Sensor
                                            -  Object:	10H10-9400</h6>
                                    </div>
                                    <div class="col-md-auto">
                                        <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <h6>Sensor Name
                                            - Device Battery</h6>
                                    </div>
                                    <div class="col-md-auto">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Card style={{ marginTop: '-30px' }}>
                        <Card.Body >
                            <div className="table-responsive" style={{ marginTop: '10px' }}>
                                <Table striped hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Sensor	</th>
                                            <th >Duration</th>
                                            <th>Start Time	</th>
                                            <th>Stop Time	</th>
                                            <th>Start Location	</th>
                                            <th>Stop Location
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>




                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>





                                        </tr>

                                        <tr>
                                            <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                            <td>2023-12-16 00:00:02</td>
                                            <td>2023-12-16 12:17:37</td>
                                            <td>14.37</td>
                                            <td>01:17</td>
                                            <td>11:00</td>

                                        </tr>



                                    </tbody>
                                </Table>
                            </div>


                        </Card.Body>
                    </Card>

                </div>
                <div class="card">
                    <div class="card-body" style={{ minHeight: '10px' }}>
                        Single Generator Running Report


                    </div>
                    <div class="card-body">
                        <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                        <div class=" bd-highlight "><FcChargeBattery />
                                            {" "}
                                            Device BatteryOn</div>
                                        <div class=" bd-highlight">20 sec</div>
                                    </div>
                                </li>
                                <li class="list-group-item" >
                                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                        <div class=" bd-highlight "><FcLowBattery />
                                            {" "}Device BatteryOff</div>
                                        <div class=" bd-highlight">23 h 59 min 40 s
                                        </div>
                                    </div></li>
                                <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                    <div class=" bd-highlight "><MdAltRoute />
                                        {" "}Route length	</div>
                                    <div class=" bd-highlight">6.91 km</div>
                                </div></li>
                            </ul>
                        </div></div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default LogicSensor

