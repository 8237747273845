import React from 'react';
import { Button, Tab, Nav, ButtonGroup, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


import { Routes, Route, Link, NavLink } from 'react-router-dom';
// import Card from '../../../components/bootstrap/card';



//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import SmsSetting from './SmsSetting';
// import UserInterfaceSetting from './UserInterfaceSetting';
// import MyAccountSetting from './MyAccountSetting';
// import SubAccountSetting from './SubAccountSetting';

function EditTable(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{marginLeft:'10%'}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Do not Edit Device
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div>
          {/* <Card style={{ borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>  */}
            <div className=" topnav mx-auto w-100 z-index-5 d-flex align-items-center justify-content-center">

              <ButtonGroup className="btn-group w-100" >
                <NavLink to="/device">
                  <Button variant="link" className="btn btn-link btn-lg active" data-bs-toggle="tooltip" title="Objects setting." style={{ fontSize: '14px' }}>Account</Button>

                </NavLink>
                <Link to='/device/event-setting'>
                  <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Events setting." style={{ fontSize: '14px' }} >Customer</Button>
                </Link>
                <Link to='/device/template-setting'>
                  <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting" style={{ fontSize: '14px' }} >Object </Button>
                </Link>
                <Link to='/device/sms-setting'>
                  <Button variant="link" className="btn btn-link btn-lg" data-bs-toggle="tooltip" title="Templates setting" style={{ fontSize: '14px' }} >Usage </Button>
                </Link>


              </ButtonGroup>
            </div>

            <Routes>
              <Route path='/' element={<ObjectSetting />} />
              <Route path='/event-setting' element={<EventSetting />} />
              <Route path='/template-setting' element={<TemplateSetting />} />
              <Route path='/sms-setting' element={<SmsSetting />} />
              {/* <Route path='/user-interface-setting' element={<UserInterfaceSetting />} /> */}
              {/* <Route path='/my-account-setting' element={<MyAccountSetting />} /> */}
              {/* <Route path='/sub-account-setting' element={<SubAccountSetting />} /> */}
            </Routes>
          {/* </Card> */}
        </div>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>
       
      </Modal.Footer>
    </Modal>
  );
}
export default EditTable;

