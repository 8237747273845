import React from 'react'
import { Table, Card } from "react-bootstrap";


const GeneralAnalysis = () => {
    return (
        <div>
            <div class="card">
                <div class="card-body" style={{ minHeight: '40px' }}>
                    <div class="row">
                        <div class="col">
                            <h6>General Anaysis -  Object:	10H10-9400</h6>
                        </div>
                        <div class="col-md-auto">
                            <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                        </div>
                    </div>
                </div>
                <Card style={{ marginTop: '-18px' }}>
                    <Card.Body >
                        <div className="table-responsive" style={{ marginTop: '10px' }}>
                            <Table striped hover size="sm">
                                <thead>
                                    <tr>
                                        <th >Object</th>
                                        <th  >Route start</th>
                                        <th>Route end</th>
                                        <th>Route length</th>
                                        <th>Move duration</th>
                                        <th>Stop duration</th>
                                        <th>Stop count</th>
                                        <th>Overspeed count</th>
                                        <th>Fuel consumption</th>
                                        <th>Fuel consumption (Km/l)</th>
                                        <th>Engine work</th>
                                        <th>Engine idle</th>
                                        <th>Engine hours</th>
                                        <th>Wireless Angle Sensor Duration</th>
                                        <th>Wireless Angle Sensor Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                        <td>2023-12-16 00:00:02</td>
                                        <td>2023-12-16 12:17:37</td>
                                        <td>14.37</td>
                                        <td>01:17</td>
                                        <td>11:00</td>
                                        <td>38</td>
                                        <td>0</td>
                                        <td>94.88</td>
                                        <td>0.15</td>
                                        <td>04:27</td>
                                        <td>03:09</td>
                                        <td>4877:05	</td>
                                        <td>0</td>
                                        <td>0</td>

                                    </tr>



                                </tbody>
                            </Table>
                        </div>


                    </Card.Body>
                </Card>

            </div>
        </div>
    )
}

export default GeneralAnalysis
