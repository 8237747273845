import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import Chart from "react-apexcharts";

const Speed = () => {
  const historyData = useSelector(state => state.history.route)
  const [speedData, setSpeedData] = useState([])
  const [timeData, setTimeData] = useState([])



  const dates = [
    [1327359600000, 30.95],
    [1327446000000, 31.34],
    // ...more data
  ];
  const [series] = useState([
    {
      name: 'XYZ MOTORS',
      data: dates
    }
  ]);

  const [options] = useState({
    chart: {
      type: 'area',
      stacked: false,
      height: 300,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    title: {
      text: '',
      align: 'left'
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100]
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      },
      title: {
        text: ''
      }
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val / 1000000).toFixed(0);
        }
      }
    }
  });



  useEffect(() => {
    const speedList = historyData?.map(item => item?.speed)
    setSpeedData(speedList)
    const timeList = historyData?.map(item => item?.dt_tracker)
    setTimeData(timeList)
  }, [])

  useEffect(() => {
    console.log('speed data is ', speedData)
  }, [speedData])


  const getVariableColor = () => {
    let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
    if (prefix) {
      prefix = prefix.trim()
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
    const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
    const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
    const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  }

  const variableColors = getVariableColor();
  // const [checked, setChecked] = useState(true);
  const colors = [variableColors.primary, variableColors.info];



  const chart2 = {
    options: {

      colors: colors,
      chart: {

        toolbar: {
          show: true,
        },
      },
      forecastDataPoints: {
        count: 3,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        show: false,
        size: 1,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 1,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        // shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: speedData,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false, // Hide the data labels on the x-axis
        },
      },
    },
    series: [
      {
        name: "speed",
        data: speedData, //[10, 82, 75, 68, 47, 60, 49, 91, 108],
      },
    ]
  }


  return (
    <div>
      <div id="chart">
        <Chart options={chart2.options} series={chart2.series} type="line" height="100%" />
      </div>
    </div>
  )
}

export default Speed
