import React, { useEffect, useState } from 'react'
import { Table, Modal, Button, Form, Pagination } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import "./Menu.css"
import NewLoader from '../../Speedo/speedo-asset/Loader';

const Groups = () => {
  //modals
  const [showAddGroup, setShowAddGroup] = useState(false)
  const [showEditGroup, setShowEditGroup] = useState(false)

  //data
  const [groupList, setGroupList] = useState([])

  //pagination
  const [isLoading, setIsLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState('1');
  const [totalPages, setTotalPages] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState('20');


  //add group
  const [addGroupName, setAddGroupName] = useState('');
  const [addGroupDesc, setAddGroupDesc] = useState('');

  //edit group
  const [groupIdForEdit, setGroupIdForEdit] = useState('')
  const [editGroupName, setEditGroupName] = useState('')
  const [editGroupDesc, setEditGroupDesc] = useState('')



  //fetch groups
  const funcFetchGroupList = async () => {
    try {
      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/group?page=${pageNumber}&rows=${rows}&sort=name&search-field=all&search-value=${searchValue}`, requestOptions)
      const result = await response.json()
      console.log(result)
      setGroupList(result?.result)
      setTotalPages(result?.pages)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchGroupList()
  }, [pageNumber, rows, searchValue])



  //function for adding new group
  const funcAddGroup = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": addGroupName,
        "description": addGroupDesc
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/device/group", requestOptions)
      const result = response.json()
      funcFetchGroupList()
      setShowAddGroup(false)
      funcClearAddGroupData()
    } catch (error) {
      console.log(error)
    }
  }

  const funcClearAddGroupData = () => {
    setAddGroupName('')
    setAddGroupDesc('')
  }


  //fetching group data for edit
  const funcFetchGroupData = async (groupId) => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/group/${groupId}`, requestOptions)
      const result = await response.json()
      setEditGroupName(result?.name)
      setEditGroupDesc(result?.description)
      setShowEditGroup(true)
    } catch (error) {
      console.log(error)
    }
  }


  //editing the group
  const funcEditGroup = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": editGroupName,
        "description": editGroupDesc
      });

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/group/${groupIdForEdit}`, requestOptions)
      const result = await response.json()
      funcFetchGroupList()
      setShowEditGroup(false)

    } catch (error) {
      console.log(error)
    }
  }




  return (
    <div>
      <div class="">
        <div class="card-body">
          <div className="input-group rounded" >
            <div className="w-100 " >
              <div class="form-group has-search d-flex"
                style={{ marginTop: '5px', minWidth: '500px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span class="fa fa-search form-control-feedback"></span>

                <input type="text" class="form-control" placeholder="Search"
                  style={{ marginTop: '-15px', height: '30px' }}
                  className='w-25'
                  value={searchValue}
                  onChange={(e) => { setSearchValue(e.target.value) }}
                />

                <Form.Select size="sm" style={{ width: '70px' }} value={rows} onChange={(e) => { setRows(e.target.value) }}>
                  <option value="05">05</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </div>

            {isLoading ? (
              <div className='w-100'>
                <NewLoader />
              </div>
            ) : (
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                    <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                    <th>Name</th>
                    <th>Group Id</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    groupList?.map((group, index) => (
                      <tr key={index}>
                        <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                        <td>{group?.name}</td>
                        <td>{group?._id}</td>
                        <td>{group?.description}</td>
                        <td>
                          <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Duplicate" >
                            <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                          </a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Edit" onClick={() => { funcFetchGroupData(group._id); setGroupIdForEdit(group._id) }}><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                          <a href="#" class="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            )}
          </div>

          <div className='w-100 d-flex justify-content-end'>
            <Pagination size="sm">
              <Pagination.Prev
                disabled={pageNumber === '1' ? true : false}
                onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
              />
              {
                (
                  <>
                    {Array.from({ length: totalPages }).map((_, i) => (
                      <Pagination.Item
                        key={i}
                        onClick={() => setPageNumber((i + 1).toString())}
                        active={pageNumber === (i + 1).toString() ? true : false}
                      >{i + 1}
                      </Pagination.Item>
                    ))}
                  </>
                )
              }
              <Pagination.Next
                disabled={pageNumber === totalPages.toString() ? true : false}
                onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
              />
            </Pagination>
          </div>
        </div>

      </div>
      <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
        <button type="button" class="btn btn-primary btn-sm btn-block" onClick={() => setShowAddGroup(true)}><FiPlus style={{ fontSize: '15px' }} /> </button>
        <button type="button" class="btn btn- btn-sm btn-block" onClick={funcFetchGroupList}><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
        <button type="button" class="btn btn- btn-sm btn-block"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

      </div>




      {/* Add group  */}
      <Modal
        size="sm"
        show={showAddGroup}
        backdrop="static"
        onHide={() => setShowAddGroup(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Add Object group properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <form>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label">Name:</label>
              <input type="text" class="form-control" id="recipient-name"
                value={addGroupName}
                onChange={(e) => { setAddGroupName(e.target.value) }}
              />
            </div>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label">Objects:</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Nothing selected</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label">Description:</label>
              <textarea class="form-control" id="message-text"
                value={addGroupDesc}
                onChange={(e) => { setAddGroupDesc(e.target.value) }}
              ></textarea>
            </div>
          </form>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" onClick={funcAddGroup}>Save</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => { setShowAddGroup(false); funcClearAddGroupData() }}>Cancel</button>
          </div>


        </Modal.Body>
      </Modal>



      {/* Edit group  */}
      <Modal
        size="sm"
        show={showEditGroup}
        backdrop="static"
        onHide={() => setShowEditGroup(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Edit Object group properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <form>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label">Name:</label>
              <input type="text" class="form-control" id="recipient-name"
                value={editGroupName}
                onChange={(e) => { setEditGroupName(e.target.value) }}
              />
            </div>
            <div class="form-group">
              <label for="recipient-name" class="col-form-label">Objects:</label>
              <select class="form-select" aria-label="Default select example">
                <option selected>Nothing selected</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="form-group">
              <label for="message-text" class="col-form-label">Description:</label>
              <textarea class="form-control" id="message-text"
                value={editGroupDesc}
                onChange={(e) => { setEditGroupDesc(e.target.value) }}
              ></textarea>
            </div>
          </form>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => { setShowEditGroup(false) }}>Cancel</button>
            <button type="button" class="btn btn-primary" onClick={funcEditGroup}>Save</button>
          </div>


        </Modal.Body>
      </Modal>


    </div>




  )
}

export default Groups
