import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      style={{ marginLeft: '10%' }}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: '14px' }}>Service properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Body>
          <div class="row">
            <div class="col-sm text-primary">
              Service
            </div>
          </div>
          <div class="row">
            <div class="col-6" style={{ marginTop: '15px' }}>
              <div class="row" >
                <div class="col">Name</div>
                <div class="col"><input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Data list</div>
                <div class="col">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Popup</div>
                <div class="col">  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Odometer interval (km)</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Engine hours interval (h)</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Days interval</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '10px' }}>
                <div class="col-sm text-primary">
                  Trigger event
                </div>
              </div>
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Odometer left (km)</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Engine hours left (h)</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '5px' }}>
                <div class="col">Days left</div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '90%' }} />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Objects</div>
                <div class="col">
                  <div class="col d-flex">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Last service (km)
                </div>
                <div class="col">
                  <div class="col d-flex">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Last service (h)
                </div>
                <div class="col">
                  <div class="col d-flex">
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Last service
                </div>
                <div class="col">
                  <div class="col d-flex">
                    <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', minWidth: '190px' }} />
                  </div>
                </div>
              </div>
              <div class="row" style={{ marginTop: '18px' }}>
                <div class="col">Update last service
                </div>
                <div class="col">
                  <div class="col d-flex">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '-40px' }} />                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }
export default MyVerticallyCenteredModal