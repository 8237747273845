import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import Multiselect from 'multiselect-react-dropdown';
import "./Style.css"
import { IoIosClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";




const ObjectSetting = (props) => {
    const eventList = []
    const devices = [
        {
            imei: '234',
            name: 'new device'
        },
        {
            imei: '323232',
            name: 'sensor device'
        },
        {
            imei: '32323232',
            name: 'sensorVehicle'
        },
        {
            imei: '324234',
            name: 'devicefornewtes'
        },
        {
            imei: '3324234',
            name: 'device for dele'
        }
    ];

    //states for holding the form data
    const [eventName, setEventName] = useState(props.addEventData?.name);
    const [eventType, setEventType] = useState(props.addEventData?.type);
    const [eventDevices, setEventDevices] = useState(props.addEventData?.devices);
    const [eventRouteTrigger, setEventRouteTrigger] = useState(props.addEventData?.route_trigger)
    const [eventRoutes, setEventRoutes] = useState(props.addEventData?.routes);
    const [eventZoneTrigger, setEventZoneTrigger] = useState(props.addEventData?.zone_trigger)





    return (
        <div>
            <Card.Header className="d-flex justify-content-between">
                <div className="header-title"></div>
            </Card.Header>
            <div>
                <div class="d-flex bd-highlight">
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <span class="" style={{ marginLeft: '1.5%', marginTop: '35px', fontSize: '15px', marginLeft: '22px' }}>Event
                            <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                            <hr style={{ marginTop: '-10px', width: '100px' }}></hr></span>
                    </div>
                    <div class="p-2 flex-grow-1 bd-highlight">
                        <span class="" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '80px' }}>Parameter and sensors
                            <hr style={{ marginTop: '8px', width: '100px', marginLeft: '15%' }}></hr>
                            <hr style={{ marginTop: '-10px', width: '100px', marginLeft: '15%' }}></hr></span>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm">

                        <form class="container" style={{}}>

                            <div class="form-row d-flex">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="event name"
                                        style={{ borderRadius: '5px' }}
                                        value={eventName}
                                        onChange={(e) => {
                                            setEventName(e.target.value)
                                            props.setAddEventData({
                                                ...props.addEventData,
                                                name: e.target.value
                                            })
                                        }}
                                    />
                                    <p className='text-danger'>*</p>
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Event Type</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: '98%', }}
                                        value={eventType}
                                        onChange={(e) => {
                                            setEventType(e.target.value)
                                            props.setAddEventData({
                                                ...props.addEventData,
                                                type: e.target.value
                                            })
                                        }}
                                    >
                                        {props.eventsList?.map((type, index) => (
                                            <option key={index} value={type}>{type}</option>
                                        ))}
                                    </select>
                                    <p className='text-danger'>*</p>
                                </div>
                            </div>

                            <div class="form-row d-flex">
                                <div class="form-group col-md-12" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Object</label>
                                    <Multiselect
                                        isObject={true}
                                        options={props.addEventDevices}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            devices: e[0].imei
                                        })}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            devices: e[0].imei
                                        })}
                                        selectedValues={eventDevices}
                                        placeholder={false}
                                        displayValue="name"
                                        style={{
                                            searchBox: {
                                                minheight: '38px',
                                                overFlow: 'auto'
                                            }
                                        }}
                                    />
                                    <p className='text-danger'>*</p>
                                </div>
                            </div>
                            <div class="form-row d-flex" >
                                <div class="form-group col-md-6" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Depending on Route</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                                        value={eventRouteTrigger}
                                        onChange={(e) => {
                                            setEventRouteTrigger(e.target.value)
                                            props.setAddEventData({
                                                ...props.addEventData,
                                                route_trigger: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="off" >select</option>
                                        <option value="off" >Off</option>
                                        <option value="in-selected-route">In Selected Route</option>
                                        <option value="out-of-selectedRoute">Out of Selected Route</option>
                                    </select>
                                    <p className='text-danger'>*</p>
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Routes</label>
                                    <Multiselect
                                        disable={eventRouteTrigger !== 'off' ? false : true}
                                        isObject={false}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            routes: e
                                        })}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            routes: e
                                        })}
                                        options={devices.map(device => device.name)}
                                        selectedValues={eventRoutes}
                                        placeholder={false}
                                        displayValue="name"
                                        style={{
                                            searchBox: {
                                                minheight: '38px',
                                                overFlow: 'auto'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div class="form-row d-flex" >
                                <div class="form-group col-md-6" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Depending on Zones</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}
                                        onChange={(e) => {
                                            setEventZoneTrigger(e.target.value)
                                            props.setAddEventData({
                                                ...props.addEventData,
                                                zone_trigger: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="off" >Select</option>
                                        <option value="off" >Off</option>
                                        <option value="in-selected-route">In Selected Route</option>
                                        <option value="out-of-selectedRoute">Out of Selected Route</option>
                                    </select>
                                    <p className='text-danger'>*</p>
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Zones</label>
                                    <Multiselect
                                        disable={eventZoneTrigger !== 'off' ? false : true}
                                        isObject={false}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            zones: e
                                        })}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={(e) => props.setAddEventData({
                                            ...props.addEventData,
                                            zones: e
                                        })}
                                        options={devices.map(device => device.name)}
                                        selectedValues={eventDevices}
                                        placeholder={false}
                                        displayValue="name"
                                        style={{
                                            searchBox: {
                                                minheight: '38px',
                                                overFlow: 'auto'
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div class="form-row d-flex" >
                                <div class="form-group col-md-6" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Routes</label>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Depending on zones</label>
                                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                            </div>

                            <div class="form-row d-flex" >
                                <div class="form-group col-md-6" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Zones</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                        <option value='654bd972ada373e9c1cc9b91'>Zone 1</option>
                                        <option value='654bd972ada373e9c1cc9b93'>Zone 2</option>
                                        <option value="654bd972ada373e9c1cc9b94">Zone 3</option>
                                        <option value="654bd972ada373e9c1cc9b95">Zone 4</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Time Period (min)</label>
                                    <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                            </div>

                            <div class="form-row d-flex" >
                                <div class="form-group col-md-6" >
                                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Speed limit (kph)</label>
                                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                </div>
                                <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                                    <label for="inputPassword4" style={{ fontSize: '13px' }}>Active</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                        <option selected>Manager</option>
                                        <option value="1">Super Admin</option>
                                        <option value="2">User</option>
                                        <option value="3">Sub Admin</option>
                                    </select>                                    </div>
                            </div>

                            {/* <span class="text-primary" >Event result preview</span> */}
                            {/* <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                                <hr style={{ marginTop: '-10px', width: '100px' }}></hr> */}
                            {/* <div class="form-row d-flex" style={{ marginTop: '10px' }}>
                                    <div class="form-group col-md-5" >
                                        <label for="inputEmail4" style={{ fontSize: '13px' }}>Current Value</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>

                                    <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}><MdDoubleArrow style={{ marginTop: '-5px' }} />
                                    </button>
                                    <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                        <label for="inputPassword4" style={{ fontSize: '13px' }}>Result</label>
                                        <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                                    </div>
                                </div> */}



                        </form>
                    </div>
                    <div class="col-sm">

                        <span class="text-primary">Event</span>
                        <Card style={{ marginTop: '10px', boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px', borderRadius: '0px' }}>
                            <div className="table-responsive scroll" style={{ marginTop: '5px' }}>
                                <Table striped hover size="sm" >
                                    <thead>
                                        <tr>
                                            <th>Source</th>
                                            <th>Value</th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>

                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>
                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>

                                        <tr>
                                            <td>Battery</td>
                                            <td style={{ marginRight: '10%' }}>io66</td>
                                            <td><IoIosClose /></td>
                                        </tr>




                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                        <div class="form-row d-flex" style={{ marginTop: '-10px' }}>
                            <div class="form-group col-md-5" >
                                <label for="inputEmail4" style={{ fontSize: '13px' }}>Source</label>
                                <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                            </div>
                            <div class="form-group col-md-5" style={{ marginLeft: '8px' }}>
                                <label for="inputPassword4" style={{ fontSize: '13px' }}>Value</label>
                                <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                            </div>
                            <button type="button" class="btn btn-primary" style={{ width: '15%', height: '35px', marginTop: '21px', marginLeft: '10px' }}><FaPlus style={{ marginTop: '-5px' }} /></button>

                        </div>
                    </div>

                    {/* <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                            <button type="button" class="btn btn-primary">Save</button>
                            <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>
                        </div> */}

                </div>
            </div>
        </div>
    )
}

export default ObjectSetting
