import React, { useEffect, useState } from 'react'
import { Card, Container, Col, Dropdown } from 'react-bootstrap';
import CustomToggle from '../../../../../components/dropdowns';
import NewLoader from '../../speedo-asset/Loader';

import Chart from "react-apexcharts";


const getVariableColor = () => {
  let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
  if (prefix) {
    prefix = prefix.trim()
  }
  const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
  const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
  const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
  const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
  return {
    primary: color1.trim(),
    info: color2.trim(),
    warning: color4.trim(),
    primary_light: color3.trim(),
  };
}

const variableColors = getVariableColor();
// const [checked, setChecked] = useState(true);
const colors = [variableColors.primary, variableColors.info];
// useEffect(() => {
//     return () => colors
// })





const chart2 = {
  options: {

    colors: colors,
    chart: {

      toolbar: {
        show: false,
      },
    },
    forecastDataPoints: {
      count: 3,
    },
    stroke: {
      width: 3,
    },
    grid: {
      show: true,
      strokeDashArray: 7,
    },
    markers: {
      size: 6,
      colors: "#FFFFFF",
      strokeColors: colors,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 0,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  },
  series: [
    {
      name: "Sales",
      data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
    },
  ]
}


const FuelLevelGraph = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fuelLevelData, setFuelLevelData] = useState([]);


  const funcFetchFuelLevelData = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Cookie", "connect.sid=s%3Aw_-iRC82nuJKbIwc2Pw3nQdCFJXB5Dd_.qE0f5K%2BN7Cp0mMhc0I6ts736rNoKQfyxfHh1ROBFXHc");

      var urlencoded = new URLSearchParams();
      urlencoded.append("devices_imei", "350612076585973");
      urlencoded.append("date_time_from", "2023-01-22 05:30:00");
      urlencoded.append("date_time_to", "2023-12-22 06:00:00");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/reports/fuellevel-graph", requestOptions)
      const result = await response.json()
      console.log(result)
      setFuelLevelData(result)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    funcFetchFuelLevelData()
  }, [])

  return (
    <div>
      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          {
            fuelLevelData?.map((report, index) => (
              <div class="card-body" style={{ minHeight: '40px' }} key={index}>
                <div class="card">
                  <div class="card-body" style={{ minHeight: "20px" }}>
                    <div class="row">
                      <div class="col">
                        <h6>Fuel Level Graph -  Device:	{report?.object}</h6>
                      </div>
                      <div class="col-md-auto">
                        <h6>Period:	{report?.period}</h6>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-12"> <Card className="card-block card-stretch card-height">
                  <Card.Header>
                    <div className=" d-flex justify-content-between  flex-wrap">
                      <h6 className="card-title">Fuel Level Graph<br></br>
                        <p style={{ fontSize: '12px' }}>some title here</p>
                      </h6>
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">
                          This year
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#">Year</Dropdown.Item>
                          <Dropdown.Item href="#">Month</Dropdown.Item>
                          <Dropdown.Item href="#">Week</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Chart options={chart2.options} series={[
                      {
                        name: "Fuel Level",
                        data: report?.fuel_level?.data.map(item => item.y),
                      },
                    ]} type="line" height="100%" className="dashboard-line-chart"></Chart>
                  </Card.Body>
                </Card></div>

              </div>
            ))
          }
        </>
      )}
    </div>
  )
}

export default FuelLevelGraph;
