import React, { useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { Table, } from "react-bootstrap";
import { CiImport } from "react-icons/ci";
import { MdAutorenew } from "react-icons/md";
import CoinsModal from './CoinsModal';
import { LiaBookOpenSolid } from "react-icons/lia";
import axios from 'axios';
const Coins = () => {
    const [modalShow, setModalShow] = useState(false);
    const [coin, setCoin] = useState(1);
    const [currency, setCurrency] = useState("Select One");



    const checkoutHandler = async (e) => {
        e.preventDefault();
        console.log("currency", currency);
        const {
            data: { key },
        } = await axios.get("/api/razorpay/key");

        const {
            data: { order },
        } = await axios.post("/api/razorpay/pay", {
            coin,
            currency_code: currency,
        });

        console.log(order);

        const options = {
            key,
            amount: order.amount,
            currency: order.currency,
            name: order.name,
            description: order.description,
            order_id: order.id,
            callback_url: "/api/razorpay/verify",
            prefill: {
                // Logged in user details
                name: "Rafay",
                email: "rafay.siddiqui106@gmail.com",
                contact: "7073830060",
            },
            notes: { address: "some address" },
            theme: { color: "#3399cc" },
        };

        const razor = new window.Razorpay(options);
        razor.open();
    };





    return (
        <div>
            <div class="card" style={{ marginTop: '-15px' }}>
                <div class="card-body">
                    <h6><img src={require('./images/coins.png')} style={{ width: '5%' }} />{" "}{" "} Coin Balances : 0</h6>
                    <div class="form-check d-flex" style={{marginTop:'20px'}}>
                        <span>Search by:</span>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '13px' }} />{" "}
                        <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: "5px" }}>
                            Order
                        </label>
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '13px' }} />{" "}
                        <label class="form-check-label" for="flexCheckDefault" style={{ marginLeft: "5px" }}>
                            Details
                        </label>
                    </div>
                    <div class="d-flex" style={{ marginTop: '15px' }}>
                        <span style={{ marginLeft: '20px' }}>Operation time:</span>
                        <input
                            class="form-control"
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            style={{ marginLeft: '20px', width: '20%' }} />
                        <input
                            class="form-control"
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            style={{ marginLeft: '10px', width: '20%' }} />
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search..." style={{ marginLeft: '8px', width: '20%' }} />
                        <button type="button" class="btn btn-primary btn-sm" style={{ borderRadius: '5px', marginLeft: '5px', width: '30px' }}><CiSearch style={{ fontSize: '15px' }} /></button>
                        <button type="button" class="btn btn-primary btn-sm" style={{ marginLeft: "5px", borderRadius: '5px', width: '10%' }} onClick={() => setModalShow(true)}><MdAutorenew /> Renew Device </button>
                        <div className="table-responsive" style={{ marginTop: '10px' }}>
                        </div>
                    </div>
                    <Table striped hover size="sm" style={{ marginTop: '30px' }}>
                        <thead>
                            <tr>
                                <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                                <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                                <th>Date</th>
                                <th>Username</th>
                                <th>Type	</th>
                                <th>IMEI</th>
                                <th>Narration</th>
                                <th>Debit</th>
                                <th>Credit</th>
                                <th>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ marginLeft: '50px' }}>1</td>
                                <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                <td>10-12-2023</td>
                                <td>SpeedoTrack</td>
                                <td>7687565465345</td>
                                <td>SpeedoTrack</td>
                                <td>1000 RS.</td>
                                <td>20 Coins</td>


                            </tr>



                        </tbody>
                    </Table>

                </div>

                {modalShow ? (
                    <CoinsModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                ) : null}


            </div>

        </div>
    )
}

export default Coins;
