import React from 'react'
import { Modal, Button } from "react-bootstrap";


const Time = () => {
  return (
    <div>
      <div class="card-body text-primary" style={{ minHeight: '30px', marginLeft: '15px', marginTop: '15px' }}>
        Time
      </div>
      <div class="row align-items-start" style={{ marginTop: "10px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Duration from last event in minutes
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '10px', width: '60%' }} />

        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "10px" }}>
        <div class="col" style={{ marginLeft: '20px', marginTop: '15px' }}>
          Week Days
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}></span>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "-10px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>

        </div>
        <div class="col d-flex">
          <span style={{ marginLeft: '1px', }}>M</span>
          <span style={{ marginLeft: '13px' }}>T</span>
          <span style={{ marginLeft: '13px' }}>W</span>
          <span style={{ marginLeft: '13px' }}>T</span>
          <span style={{ marginLeft: '15px' }}>F</span>
          <span style={{ marginLeft: '15px' }}>S</span>
          <span style={{ marginLeft: '15px' }}>S</span>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "10px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Day time
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "15px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Monday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>


      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Tuesday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Wednesday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Thursday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Friday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Saturday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <div class="row align-items-start" style={{ marginTop: "5px" }}>
        <div class="col" style={{ marginLeft: '20px' }}>
          Sunday
        </div>
        <div class="col d-flex">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
          <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
            <option selected>00:00</option>
            <option value="1">00:15</option>
            <option value="2">00:30</option>
            <option value="3">00:45</option>
            <option value="3">01:00</option>
          </select>
        </div>
      </div>
      <Modal.Footer style={{marginTop:'10px'}}>
        <Button type="button" class="btn btn-secondary">Cancel</Button>
        <Button type="button">Save</Button>
      </Modal.Footer>
    </div>
  )
}

export default Time
