import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{marginLeft:'10%'}}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form lg="12" style={{ marginTop: '20px' }}>
                    <div class="form-row d-flex">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>Imei</label>
                            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}> ICCID</label>
                            <select class="form-select" aria-label="Default select example"
                                style={{ width: '98%' }}
                                
                            >
                                <option selected>Manager</option>
                                <option value="calibration">Calibration</option>
                                <option value="2">User</option>
                                <option value="3">Sub Admin</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row d-flex" >
                        <div class="form-group col-md-6" >
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>IMEI</label>
                            <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                <option selected>Manager</option>
                                <option value="1">Super Admin</option>
                                <option value="2">User</option>
                                <option value="3">Sub Admin</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}>Sim Number</label>
                            <select class="form-select" aria-label="Default select example" style={{ width: '98%' }}>
                                <option selected>Manager</option>
                                <option value="1">Super Admin</option>
                                <option value="2">User</option>
                                <option value="3">Sub Admin</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row d-flex" >
                        <div class="form-group col-md-6" >
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>Activated Time</label>
                            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}>Sales Time</label>
                            <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                    </div>

                    <div class="form-row d-flex" >
                        <div class="form-group col-md-6" >
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Name</label>
                            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}>Group</label>
                            <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                    </div>
                    <div class="form-row d-flex" >
                        <div class="form-group col-md-6" >
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Model</label>
                            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}>Import time</label>
                            <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                    </div>
                    <div class="form-row d-flex" >
                        <div class="form-group col-md-6" >
                            <label for="inputEmail4" style={{ fontSize: '13px' }}>Expiration Date</label>
                            <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-6" style={{ marginLeft: '8px', }}>
                            <label for="inputPassword4" style={{ fontSize: '13px' }}>Subscription Expiration</label>
                            <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                    </div>
                    <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                        <button type="button" class="btn btn-primary">Save</button>
                        <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                    </div>



                </form>
            </Modal.Body>

        </Modal>
    );
}



export default MyVerticallyCenteredModal;