import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteSensorModal = (props) => {
    const [deleted, setDeleted] = useState('');




    const funcDeleteSensor = async () => {
        try {
            var requestOptions = {
                method: 'DELETE',
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/device/${props.imeiForSensor}/sensor/${props.sensorIdForDelete}`, requestOptions)
            const result = await response.json()
            setDeleted('DELETED')
            props.setToggleSensorChange(!props.toggleSensorChange)
            console.log(result)

            setTimeout(() => {
                props.onHide()
            }, 3000)
        } catch (error) {
            console.log(error)
            setDeleted('ERROR')
            setTimeout(() => {
                props.onHide()
            }, 5000)
        }
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            top
            style={{ marginLeft: '-6%' }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
                    Delete Sensor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {deleted === 'DELETED' ? (
                    <p style={{ color: 'green', fontSize: '14px' }}>
                        Sensor deleted sucessfully !!
                    </p>
                ) : deleted === 'ERROR' ? (
                    <p style={{ color: 'red', fontSize: '14px' }}>
                        Unable to delete sensor !!
                    </p>
                ) : (<p style={{ fontSize: '14px' }} className=''>
                    Are you sure you want to delete this Sensor?
                </p>)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' style={{ fontSize: '14px' }} onClick={funcDeleteSensor}>Yes</Button>
                <Button variant='secondary' onClick={props.onHide} style={{ fontSize: '14px' }}>No</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default DeleteSensorModal
