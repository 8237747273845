import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table } from 'react-bootstrap';
import "./Style.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";
import { TfiEye } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import DeleteUsageModal from './DeleteUsageModal';




const SmsSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [deleteUsage, setDeleteUsage] = useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      {
        optionSelected === 'objects' ? (
          <div>
            <div>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title"></div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div class="row " style={{ marginTop: '-10px', marginLeft: '4px', }}>
                    
                  </div>

                  <div className="table-responsive" style={{ marginTop: '10px' }}>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                       
                        <th >Date</th>
                        <th>Login</th>
                        <th>E-mail</th>
                        <th>Sms</th>
                        <th>Api</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                     <td>1</td>
                        <td>2023-07-03</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                     
                        <td>
                          <a class="delete" data-toggle="modal" title="Delete" onClick={() => setDeleteUsage (true)}><TfiTrash style={{ width: '23px' }} /></a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                </Card.Body>


              </Card>
            </div>


          </div>







        ) : (
          <h4>Unknown</h4>
        )
      }

<DeleteUsageModal
        show={deleteUsage}
        onHide={() => setDeleteUsage(false)}
      />



    </div>
  )
}

export default SmsSetting
