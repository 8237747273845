import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Form } from 'react-bootstrap';
import "./DeviceStyle.css"

//importing of the icons from react icons
import MyVerticallyCenteredModal from './EditCustomerView';



const ObjectSetting = (props) => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [canEdit, setCanEdit] = useState(false)

  //states for holding the form data
  const [deviceId, setDeviceId] = useState(props.deviceData?.device?._id);
  const [deviceModel, setDeviceModel] = useState(props.deviceData?.device?.model);
  const [plateNumber, setPlateNumber] = useState(props.deviceData?.device?.plate_number);
  const [deviceType, setDeviceType] = useState(props.deviceData?.device?.device);
  const [managerId, setManagerId] = useState(props.deviceData?.device?.manager_id[0]);
  const [vin, setVin] = useState(props.deviceData?.device?.vin);
  const [vehicleType, setVehicleType] = useState(props.deviceData?.device?.vehicle_type)
  const [driver, setDriver] = useState('to do later');
  const [trailer, setTrailer] = useState('to to later');


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>
      <div>
        <div>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title"></div>
          </Card.Header>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Form.Check
              type="checkbox"
              id={`default-checkbox`}
              label={`Edit`}
              onChange={() => { setCanEdit(!canEdit) }}
            />
          </div>
          <div class="p-2 flex-grow-1 bd-highlight">
            <span class="text-primary" style={{ marginTop: '35px', fontSize: '15px', marginLeft: '' }}>Vehicle Information
              <hr style={{ marginTop: '8px', width: '100px' }}></hr>
              <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

            </span>
          </div>

          <form lg="12" style={{ marginTop: '20px' }}>
            <div class="form-row d-flex">
              <div class="form-group col-md-4">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Id :</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="device _id"
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={deviceId}
                  onChange={(e) => {
                    setDeviceId(e.target.value);
                  }}
                />
              </div>

              <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}> Driver : </label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="driver's name"
                  disabled={!canEdit ? true : false}
                  value={driver}
                  onChange={(e) => {
                    setDriver(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      driver: e.target.value
                    })
                  }}
                  style={{ borderRadius: '5px' }}
                />
              </div>

              <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Trailer:</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="device's trailer"
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={trailer}
                  onChange={(e) => {
                    setTrailer(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      trailer: e.target.value
                    })
                  }}
                />
              </div>
            </div>

            <div class="form-row d-flex">
              <div class="form-group col-md-4">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Plate Number : </label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="vehicle plate number"
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={plateNumber}
                  onChange={(e) => {
                    setPlateNumber(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      plateNumber: e.target.value
                    })
                  }}
                />
              </div>
              <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Device Model</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="device model"
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={deviceModel}
                  onChange={(e) => setDeviceModel(e.target.value)}
                />

              </div>

              <div class="form-group col-md-4" style={{ marginLeft: '8px' }} >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Device Type:</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="device type"
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={deviceType}
                  onChange={(e) => setDeviceType(e.target.value)}
                />
              </div>
            </div>

            {/* <div class="form-row d-flex">
                  <div class="form-group col-md-4">
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Manager Id</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="Manager Id"
                      style={{ borderRadius: '5px' }}
                      value={managerId}
                      onChange={(e) => setManagerId(e.target.value)}
                    />
                  </div>
                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputPassword4" style={{ fontSize: '13px' }}> Vin Number:</label>
                    <select class="form-select" aria-label="Default select example"
                      style={{ width: '98%' }}

                    >
                      <option selected>Manager</option>
                      <option value="calibration">Calibration</option>
                      <option value="2">User</option>
                      <option value="3">Sub Admin</option>
                    </select>
                  </div>

                  <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                    <label for="inputEmail4" style={{ fontSize: '13px' }}>Fuel/100km:</label>
                    <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                  </div>
                </div> */}

            <div class="form-row d-flex">
              <div class="form-group col-md-4">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Manager Id</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="manager id"
                  disabled
                  style={{ borderRadius: '5px' }}
                  value={managerId}
                  onChange={(e) => setManagerId(e.target.value)}
                />
              </div>
              <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}> VIN</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="Vehicle identification no."
                  disabled={!canEdit ? true : false}
                  style={{ borderRadius: '5px' }}
                  value={vin}
                  onChange={(e) => {
                    setVin(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      vin: e.target.value
                    })
                  }}
                />

              </div>

              <div class="form-group col-md-4" style={{ marginLeft: '8px' }} >
                <label for="inputEmail4" style={{ fontSize: '13px' }}> Vehicle Type</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="vehicle type" style={{ borderRadius: '5px' }}
                  disabled={!canEdit ? true : false}
                  value={vehicleType}
                  onChange={(e) => {
                    setVehicleType(e.target.value);
                    props.setPatchData({
                      ...props.patchData,
                      vehicle_type: e.target.value
                    })
                  }}
                />

              </div>
            </div>
            <div>
              <div class="d-flex bd-highlight">
                <div class="p-2 flex-grow-1 bd-highlight">
                  <span class="text-primary" style={{ marginTop: '35px', fontSize: '15px', }}>Install Information
                    <hr style={{ marginTop: '8px', width: '100px' }}></hr>
                    <hr style={{ marginTop: '-10px', width: '100px' }}></hr>

                  </span>
                </div>
              </div>

              <div class="form-row d-flex">
                <div class="form-group col-md-4">
                  <label for="inputEmail4" style={{ fontSize: '13px' }}>Installation Time:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                </div>
                <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                  <label for="inputPassword4" style={{ fontSize: '13px' }}> Install company:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                </div>

                <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                  <label for="inputEmail4" style={{ fontSize: '13px' }}>Installer:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                </div>
              </div>

              <div class="form-row d-flex">
                <div class="form-group col-md-4">
                  <label for="inputEmail4" style={{ fontSize: '13px' }}>Install Address:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                </div>
                <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                  <label for="inputPassword4" style={{ fontSize: '13px' }}> Install Position:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                </div>

                <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                  <label for="inputEmail4" style={{ fontSize: '13px' }}>Install picture:</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                </div>
              </div>
            </div>
            <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>

              {canEdit ? (
                <button type="button" class="btn btn-primary"
                  onClick={props.editDevice}
                >Save </button>
              ) : null}

              <button type="button" class="btn btn-secondary"
                style={{ marginLeft: '5px' }}
                onClick={() => setModalShow(true)}
              >Cancel</button>

            </div>



          </form>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />





    </div>
  )
}

export default ObjectSetting
