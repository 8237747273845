import React, { useEffect, useState } from 'react'
import { TbRouteAltLeft } from "react-icons/tb";
import { FaRoute } from "react-icons/fa6";
import { TbRouteAltRight } from "react-icons/tb";
import { TbClockStop } from "react-icons/tb";
import { TbRouteSquare } from "react-icons/tb";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { TbRouteSquare2 } from "react-icons/tb";
import { IoSpeedometerOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { PiClockCounterClockwiseLight } from "react-icons/pi";
import { LuFuel } from "react-icons/lu";
import { BsFuelPump } from "react-icons/bs";
import { PiEngineBold } from "react-icons/pi";
import { SiGoogleearthengine } from "react-icons/si";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaTrailer } from "react-icons/fa";
import NewLoader from '../../speedo-asset/Loader';

import {animateScroll as scroll} from 'react-scroll';



const GeneralInfoData = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalInfoData, setGeneralInfoData] = useState([]);



  const funcFetchGeneralInfo = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("devices_imei", "350612076585973");
      urlencoded.append("data_items", props.selectedDataItems.join(',')); 
      urlencoded.append("date_time_from", "2023-06-07 00:00:00");
      urlencoded.append("date_time_to", "2023-12-18 00:00:00");
      urlencoded.append("speed_limit", props.speedLimit);
      urlencoded.append("stop_duration", props.stopDuration);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/reports/general-info", requestOptions)
      const result = await response.json()
      setGeneralInfoData(result)
      console.log(result);
      setIsLoading(false)
      scroll.scrollTo(100);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchGeneralInfo()
  }, [props.toggleSearch])





  return (
    <div>

      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          {generalInfoData?.map((report, index) => (
            <div class="card" style={{ marginTop: '-20px' }} key={index}>
              <div class="card">
                <div class="card-body" style={{ minHeight: '20px' }}>
                  <div class="row">
                    <div class="col">
                      <h6>General information -  Object:	{report?.object}</h6>
                    </div>
                    <div class="col-md-auto">

                      <h6>Period:	{report?.period}</h6>
                    </div>

                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                    <ul class="list-group list-group-flush">

                      {report?.route_start ? (
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbRouteAltLeft />{" "}Route start</div>
                            <div class=" bd-highlight">{report?.route_start}</div>
                          </div>
                        </li>
                      ) : null}

                      {report?.route_end ? (
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><LiaTruckMovingSolid />{" "}Route end</div>
                            <div class=" bd-highlight">{report?.route_end}</div>
                          </div>
                        </li>
                      ) : null}

                      {report?.route_length ? (
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><AiOutlineStop />{" "}Route length</div>
                          <div class=" bd-highlight">{report?.route_length}</div>
                        </div>
                        </li>
                      ) : null}


                    </ul>
                  </div></div>
                  <div class="col-4" >
                    <div class="card" >
                      <ul class="list-group list-group-flush">
                        {report?.move_duration ? (
                          <li class="list-group-item">
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><TbRouteSquare />{" "}Move duration</div>
                              <div class=" bd-highlight">{report?.move_duration}</div>
                            </div>
                          </li>
                        ) : null}

                        {report?.stop_duration ? (
                          <li class="list-group-item" >
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><TbRouteSquare2 />{" "}Stop duration</div>
                              <div class=" bd-highlight">{report?.stop_duration}</div>
                            </div>
                          </li>
                        ) : null}


                        {report?.stop_count ? (
                          <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Stop count</div>
                            <div class=" bd-highlight">{report?.stop_count}</div>
                          </div>
                          </li>
                        ) : null}

                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">

                        {report?.top_speed ? (
                          <li class="list-group-item">
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><IoSpeedometerOutline />{" "}Top speed</div>
                              <div class=" bd-highlight">{report?.top_speed}</div>
                            </div>
                          </li>
                        ) : null}

                        {report?.avg_speed ? (
                          <li class="list-group-item" >
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><TbClockStop />{" "}Average speed</div>
                              <div class=" bd-highlight">{report?.avg_speed}</div>
                            </div>
                          </li>
                        ) : null}

                        {report?.overspeed_count ? (
                          <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><LuFuel />{" "}Overspeed count</div>
                            <div class=" bd-highlight">{report?.overspeed_count}</div>
                          </div>
                          </li>
                        ) : null}

                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                    <ul class="list-group list-group-flush">

                      {report.fuel_consumption ? (
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><BsFuelPump />{" "}Fuel consumption</div>
                            <div class=" bd-highlight">{report?.fuel_consumption}</div>
                          </div>
                        </li>
                      ) : null}

                      {report?.avg_fuel_consumption ? (
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><LuFuel />{" "}Avg. fuel consumption</div>
                            <div class=" bd-highlight">{report?.avg_fuel_consumption}</div>
                          </div>
                        </li>
                      ) : null}

                      {report?.fuel_cost ? (
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><SiGoogleearthengine />{" "}Fuel cost</div>
                          <div class=" bd-highlight">{report?.fuel_cost}</div>
                        </div>
                        </li>
                      ) : null}
                    </ul>
                  </div></div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">
                        {report?.engine_idle ? (
                          <li class="list-group-item">
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><PiEngineBold />{" "}Engine idle</div>
                              <div class=" bd-highlight">{report?.engine_idle}</div>
                            </div>
                          </li>
                        ) : null}

                        {report?.engine_work ? (
                          <li class="list-group-item" >
                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><BsSpeedometer2 />{" "}Engine work</div>
                              <div class=" bd-highlight">{report?.engine_work}</div>
                            </div>
                          </li>
                        ) : null}

                        { report?.engine_hours ? (
                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                              <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Engine hours</div>
                              <div class=" bd-highlight">{report?.engine_hours}</div>
                            </div>
                            </li>
                              ) : null
                        }
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbClockStop />{" "}Odometer</div>
                            <div class=" bd-highlight">{report?.odometer}</div>
                          </div>
                        </li>
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><FaTrailer />{" "}Driver</div>
                            <div class=" bd-highlight">{report?.driver}</div>
                          </div>
                        </li>
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><LuFuel />{" "}Trailer</div>
                          <div class=" bd-highlight">{report?.trailer}</div>
                        </div></li>
                      </ul>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
                <button type="button" class="btn btn-primary  btn-block" style={{ borderRadius: '0px', }}><img src={require('./images/excel.png')} style={{ width: '15px' }} /> Excel</button>
                <button type="button" class="btn btn-primary btn-block" style={{ borderRadius: '0px', marginLeft: "5px" }}><img src={require('./images/pdf.png')} style={{ width: '15px' }} /> PDF </button>

              </div>
            </div>
          ))
          }
        </>
      )}

    </div>
  )
}

export default GeneralInfoData
