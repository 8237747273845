import React,{ memo, Fragment } from 'react'

//react-bootstrap
import { Row, Col, Image, Form, Button } from 'react-bootstrap'

//router
import { Link, useNavigate } from 'react-router-dom'

//components
import Card from '../../../components/bootstrap/card'

// img
import auth2 from '../../../assets/images/auth/02.png'

// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors'

// Redux Selector / Action
import { useSelector } from 'react-redux';

const Recoverpw = memo(() => {
   const appName = useSelector(SettingSelector.app_name)
   // let history =useHistory()
   let history = useNavigate()
   return (
      <Fragment>
         <section className="login-content">
            <Row className="m-0  align-items-center  vh-100" >
               <Col md="6 " className="d-md-block d-none  p-0 mt-n1 vh-100 overflow-hidden">
               <img src={require('./images/forgots.png')} style={{marginTop:'25%', marginLeft:'10%'}}/>
               </Col>
               <Col md="6" className="p-0" style={{marginTop:'-10%'}}>
                  <Card className="card-transparent auth-card shadow-none d-flex justify-content-center mb-0">
                     <Card.Body>
                        <Link to="/dashboard" className="navbar-brand d-flex align-items-center mb-3">
                        <img src={require('./images/logo.png')} style={{width:'30%', marginTop:'60px',}} />
                           
                        </Link>
                        <h3 className="mb-2" style={{marginTop:''}}>Reset Password</h3>
                        <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
                        <Form>
                           <Row>
                              <Col lg="12" className="col-lg-12">
                                 <Form.Group className="floating-label">
                                    <Form.Label htmlFor="email" className="form-label">Email</Form.Label>
                                    <Form.Control type="email" className="form-control" id="email" aria-describedby="email" placeholder=" " />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Button onClick={() => history.push('/')} className="mt-3" type="button" variant="primary">Reset</Button>
                        </Form>
                     </Card.Body>
                  </Card>
                  {/* <div className="sign-bg sign-bg-right">
                     <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                           <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                           <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                           <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                        </g>
                     </svg>
                  </div> */}
               </Col>
            </Row>
         </section>
      </Fragment>
   )
}
)

Recoverpw.displayName = "Recoverpw"
export default Recoverpw
