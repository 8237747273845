import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NewLoader from '../speedo-asset/Loader';
import { getCountries, getStates } from 'country-state-picker';
import Multiselect from 'multiselect-react-dropdown';


function AddModal(props) {
  //state variables
  const [addUserRequiredData, setAddUserRequiredData] = useState({})
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStatesList] = useState([]);

  //states for inputs of the modal
  const [addDeviceMissingItem, setAddDeviceMissingItem] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('Mr.')
  const [midName, setMidName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userEmail, setUserEmail] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userType, setUserType] = useState('ADMIN');
  const [building, setBuilding] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('India');
  const [userEvents, setUserEvents] = useState([]);
  const [event, setEvent] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)


  useEffect(() => {
    const countries = getCountries();
    setCountryList(countries)
  }, [])

  useEffect(() => {
    console.log(country)
    const val = countryList.find((cObject) => cObject.name === country)
    const states = getStates(val?.code)
    console.log(val)
    setStatesList(states)
    setCountryCode(val?.dial_code)
  }, [country])

  useEffect(() => {
    console.log(state)
  }, [state])


  useEffect(() => {
    const fetchRequiredData = async () => {
      try {
        setIsLoading(true);
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };

        const response = await fetch("https://gps.speedotrack.com/api/cpanel/user/required-data", requestOptions)
        const result = await response.json()
        setAddUserRequiredData(result)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }
    fetchRequiredData()
  }, [])


  const setEventId = (selectedEvent) => {
    const eventsList = addUserRequiredData.eventTemplates.filter((event) => {
      return event.name === selectedEvent;
    });
    console.log(eventsList)
    const eventIdList = eventsList.map((event) => event._id)
    setUserEvents([...userEvents, ...eventIdList]);
  };


  useEffect(() => {
    console.log(userEvents)
  }, [userEvents])


  //function for creating a new user.
  const createUser = async () => {

    const missingItems = [];

    const validateField = (field, name) => {
      if (field === null || field === '' || (Array.isArray(field) && field.length === 0)) {
        missingItems.push(name);
      }
    };

    validateField(firstName, 'firstName');
    validateField(midName, 'midName');
    validateField(lastName, 'lastName');
    validateField(userEmail, 'email');
    validateField(phoneNumber, 'phone');
    validateField(country, 'country');

    const isEmailValid = (userEmail) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(userEmail);
    };

    // Check if userEmail is not empty or not a valid email
    if (userEmail !== '' && !isEmailValid(userEmail)) {
      missingItems.push('email');
    }




    if (missingItems.length === 0) {


      try {
        setIsLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "name": {
            "first": firstName,
            "mid": midName,
            "last": lastName
          },
          "email": userEmail,
          "phone": {
            "country_code": countryCode,
            "number": phoneNumber
          },
          "user_type": userType,
          "address": {
            "building": "",
            "street": street,
            "city": city,
            "state": state,
            "postal_code": postalCode,
            "country": country
          },
          "event_templates": userEvents
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const response = await fetch("https://gps.speedotrack.com/api/cpanel/user", requestOptions)
        const result = await response.json()
        if (!response.ok) {
          alert(result.error[0])
        }
        console.log(result);
        props.setToggleChange(!props.toggleChange)
        setIsLoading(false)
        props.onHide()

      } catch (error) {

        console.log(error)
        alert(error[0])
        setIsLoading(false)
      }
    } else {
      setAddDeviceMissingItem(missingItems);
    }
  }



  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{ marginLeft: '-5%' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Add User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <NewLoader />
        ) : (
          <form lg="12">
            <div className="form-row d-flex">
              <div className="form-group col-md-2 mx-2">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Salutation</label>
                <select className="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                >
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                  <option value="Miss.">Miss.</option>
                  <option value="M/s.">M/s.</option>
                </select>
                {addDeviceMissingItem?.includes('firstName') ? (
                  <p className='text-danger mx-2'> Salutation is required</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
              <div className="form-group col-md-4 mx-2">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>First Name</label>
                <input type="text" className="form-control" id="inputEmail4" placeholder="first name"
                  style={{ borderRadius: '5px' }}
                  value={midName}
                  onChange={(e) => setMidName(e.target.value)}
                  maxLength={15}
                />
                {addDeviceMissingItem?.includes('midName') ? (
                  <p className='text-danger mx-2'>first name is required</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
              <div className="form-group col-md-4 mx-2">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Last Name</label>
                <input type="text" className="form-control" id="inputEmail4" placeholder="last name"
                  style={{ borderRadius: '5px' }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  maxLength={15}
                />
                {addDeviceMissingItem?.includes('lastName') ? (
                  <p className='text-danger mx-2'>last name is required</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
            </div>
            <div className="form-row d-flex" >
              <div className="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Email</label>
                <input type="email" className="form-control" id="inputPassword4" placeholder="user email"
                  style={{ borderRadius: '5px' }}
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  maxLength={25}
                />
                {addDeviceMissingItem?.includes('email') ? (
                  <p className='text-danger mx-2'>please enter a valid email</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
              <div className="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px', marginLeft: '8px' }}>Code</label>
                <input type="text" className="form-control" id="inputEmail4" placeholder="+91"
                  style={{ borderRadius: '5px', borderRight: 'none' }}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  maxLength={3}
                />
              </div>
            </div>
            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Phone No.</label>
                <input type="number" className="form-control" id="inputEmail4" placeholder="user phone number"
                  style={{ borderRadius: '5px', }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  maxLength={10}
                />
                {addDeviceMissingItem?.includes('phone') ? (
                  <p className='text-danger mx-2'>phone number is required</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Country</label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => { setCountry(e[0]) }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { setCountry(e[0]) }}
                  onChange={(e) => console.log(e.target.value)}
                  options={(countryList.map((country) => country.name))}
                  placeholder={false}
                  selectionLimit={1}
                  selectedValues={["India"]}
                  style={{
                    chips: {
                      background: 'none',
                      color: 'grey'
                    },
                  }}
                />
                {addDeviceMissingItem?.includes('country') ? (
                  <p className='text-danger mx-2'>country is required</p>
                ) : <p className='text-danger mx-2'>*</p>}
              </div>
            </div>

            <div className="form-row d-flex" >
              <div className="form-group col-md-6" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>City</label>
                <input type="email" className="form-control" id="inputEmail4" placeholder="city"
                  style={{ borderRadius: '5px' }}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6" style={{ marginLeft: '8px' }}>
                <label for="inputPassword4" style={{ fontSize: '13px' }}>State</label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => { setEventId(e[0]) }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { setEventId(e[0]) }}
                  options={statesList}
                  selectionLimit={1}
                  placeholder={false}
                  style={{
                    chips: {
                      background: 'none',
                      color: 'black'
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-row d-flex">
              <div className="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px' }}>User Type</label>
                <select className="form-select" aria-label="Default select example" style={{ width: '98%' }}
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  {addUserRequiredData?.userTypes?.map((userType, index) => (
                    <option value={userType} key={index}>{userType}</option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px' }}>Postal Code</label>
                <input type="text" className="form-control" id="inputPassword4" placeholder="postal code"
                  style={{ borderRadius: '5px' }}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>

            </div>
            <div className="form-row d-flex" >
              <div className="form-group col-md-6 px-2" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Address</label>
                <textarea type="text" className="form-control" id="inputEmail4" placeholder="enter user address"
                  style={{ borderRadius: '5px' }}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6 px-2" >
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Events</label>
                <Multiselect
                  isObject={false}
                  onKeyPressFn={function noRefCheck() { }}
                  onRemove={(e) => { setEventId(e[0]) }}
                  onSearch={function noRefCheck() { }}
                  onSelect={(e) => { setEventId(e[0]) }}
                  options={addUserRequiredData?.eventTemplates?.map(event => event.name)}
                  placeholder="Select user events"
                />
              </div>

            </div>

          </form>
        )}
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>
        <Button variant='primary' onClick={createUser} style={{ width: '100%', fontSize: '13px' }}>Add User</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddModal;

