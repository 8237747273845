import React from 'react'
import { Table, } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiEye } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { TfiBookmarkAlt } from "react-icons/tfi";
import { FcCheckmark } from "react-icons/fc";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import TaskModal from './TaskModal';
import { FcSerialTasks } from "react-icons/fc";



const Task = () => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div>
             <div class="card col-12" style={{ height: '70px' }}>
                <div class="card-body">
                    <h5 class="card-title"> <FcSerialTasks /> Tasks</h5>
                </div>
            </div>
            <div class="card" style={{ marginTop: '-20px' }}>
                <div class="card-body" style={{ minHeight: '25px', marginTop: '-15px' }}>
                    <div class="row" style={{ marginTop: '10px' }}>
                        <div class="col-2">
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Object</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: "100%", marginTop: "10px" }}>
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>                </div>
                            </form>
                        </div>
                        <div class="col-2" style={{ marginLeft: '-20px' }}>
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Filter</label>
                                    <select class="form-select" aria-label="Default select example" style={{ width: "100%", marginTop: "10px" }}>
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>                </div>
                            </form>
                        </div>
                        <div class="col-2" style={{ marginLeft: '-20px' }}>
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Time from</label>
                                    <input class="text" type="datetime-local" value="" id="flexCheckDefault" style={{ marginTop: '10px', width: '100%', height: '33px' }} />
                                </div>
                            </form>
                        </div>
                        <div class="col-2" style={{ marginLeft: '-20px' }}>
                            <form>
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Time to</label>
                                    <input class="datetime-local" type="datetime-local" value="" id="flexCheckDefault" style={{ marginTop: '10px', width: '100%', height: '33px' }} />            </div>
                            </form>
                        </div>
                        <div class="col-2">
                            <form>
                                <div class="form-group d-flex">
                                    <label for="exampleInputEmail1"></label>
                                    <button type="button" class="btn btn-outline-primary btn-sm" style={{ marginTop: '30px', minWidth: '130px', height: '35px', marginLeft: '-20px' }}>Delete All</button>
                                    <button type="button" class="btn btn-outline-primary btn-sm" style={{ marginTop: '30px', minWidth: '130px', height: '35px', marginLeft: '5px' }}>Export to CSV</button>
                                    <button type="button" class="btn btn-outline-primary btn-sm" style={{ marginTop: '30px', minWidth: '130px', height: '35px', marginLeft: '5px' }}>Show</button>


                                </div>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12"><div class="card" style={{ marginTop: '-15px' }}>
                    <div class="card-body">
                        <div className="table-responsive" style={{ marginTop: '10px' }}>
                            <Table striped hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                                        <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                                        <th style={{ paddingLeft: '0px' }} >Status</th>
                                        <th>Time</th>
                                        <th>Object</th>
                                        <th>Name</th>
                                        <th>Start</th>
                                        <th>Destination</th>
                                        <th>Priority</th>
                                        <th>Action</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ marginLeft: '50px' }}>1</td>
                                        <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                                        <td><FcCheckmark style={{ marginLeft: '-10px' }} /></td>
                                        <td>10-12-2023</td>
                                        <td>SpeedoTrack</td>
                                        <td>SpeedoTrack</td>
                                        <td>SpeedoTrack</td>
                                        <td>SpeedoTrack</td>
                                        <td>SpeedoTrack</td>
                                        <td>


                                            <a href="#" class="delete" data-toggle="modal" title="Edit"  ><TfiPencil style={{ width: '23px' }} /></a>

                                            <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ width: '23px' }} /></a>




                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>

                    <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
                        <button type="button" class="btn btn-primary btn-sm btn-block" title="Add" onClick={() => setModalShow(true)} > <FiPlus style={{ fontSize: '15px' }} /> </button>
                        <button type="button" class="btn btn- btn-sm btn-block" title="Refresh"><HiOutlineRefresh /> </button>
                        <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

                    </div>
                </div>
                </div>


            </div>
            <TaskModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />



        </div>
    )
}

export default Task
