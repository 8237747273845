import React, { useEffect, useState } from 'react'
import { Table, Modal, Form, Pagination } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import NewLoader from '../../Speedo/speedo-asset/Loader';

const MyAccount = () => {
  const [group, setGroup] = useState(false);
  const handleClose = () => setGroup(false);
  const handleGroup = () => setGroup(true);

  //modal
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  //data
  const [trailerList, setTrailerList] = useState([])

  //pagination
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState('1');
  const [totalPages, setTotalPages] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState('20')

  //add Trailer
  const [addTrailerName, setAddTrailerName] = useState('')
  const [addTrailerAssignId, setAddTrailerAssignId] = useState('')
  const [addTrailerVin, setAddTrailerVin] = useState('')
  const [addTrailerModel, setAddTrailerModel] = useState('')
  const [addTrailerPlateNo, setAddTrailerPlateNo] = useState('')

  //edit Trailer
  const [trailerIdForEdit, setTrailerIdForEdit] = useState('')
  const [editTrailerName, setEditTrailerName] = useState('')
  const [editTrailerAssignId, setEditTrailerAssignId] = useState('')
  const [editTrailerVin, setEditTrailerVin] = useState('')
  const [editTrailerModel, setEditTrailerModel] = useState('')
  const [editTrailerPlateNo, setEditTrailerPlateNo] = useState('')


  const funcFetchTrailerList = async () => {
    try {
      setIsLoading(true)
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/trailer?page=${pageNumber}&rows=${rows}&sort=name&search-field=all&search-value=${searchValue}`, requestOptions)
      const result = await response.json()
      setTrailerList(result?.result)
      setTotalPages(result?.pages)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchTrailerList()
  }, [pageNumber, rows, searchValue])

  const clearAddTrailerData = () => {
    setAddTrailerName("")
    setAddTrailerAssignId('')
    setAddTrailerVin('')
    setAddTrailerModel('')
    setAddTrailerPlateNo('')

  }


  // function for adding a new trailer
  const funcAddTrailer = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "name": addTrailerName,
        "assign_id": addTrailerAssignId,
        "model": addTrailerModel,
        "vin": addTrailerVin,
        "plate_number": addTrailerPlateNo
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/user/device/trailer", requestOptions)
      const result = await response.json();
      funcFetchTrailerList();
      setShowAddModal(false);
      clearAddTrailerData()
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }


  //fetching trailer data for editing
  const funcFetchTrailer = async (trailerId) => {
    try {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/trailer/${trailerId}`, requestOptions)
      const result = await response.json();
      console.log(result)

      setEditTrailerName(result?.name);
      setEditTrailerAssignId(result?.assign_id);
      setEditTrailerVin(result?.vin)
      setEditTrailerModel(result?.model)
      setEditTrailerPlateNo(result?.plate_number);
      setShowEditModal(true)
    } catch (error) {
      console.log(error)
    }
  }


  //edit trailer
  const funcEditTrailer = async () => {
    try {
      var formdata = new FormData();
      formdata.append("assign_id", "280948234");
      formdata.append("idn", "");
      formdata.append("address", "");
      formdata.append("email", "john@gmail.com");
      formdata.append("desc", "");
      formdata.append("img", "delete");

      var requestOptions = {
        method: 'PATCH',
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/trailer/${trailerIdForEdit}`, requestOptions)
      const result = await response.json();

    } catch (error) {
      console.log(error)
    }
  }

  //delete trailer
  const funcDeleteTrailer = async (trailerId) => {
    try {
      var requestOptions = {
        method: 'DELETE',
        redirect: 'follow'
      };

      const response = await fetch(`https://gps.speedotrack.com/api/user/device/trailer/${trailerId}`, requestOptions)
      const result = await response.json()
      funcFetchTrailerList()
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div>
      <div className="">
        <div className="card-body">
          <div className="input-group rounded" >


            <div className="w-100 " >
              <div class="form-group has-search d-flex"
                style={{ marginTop: '5px', minWidth: '500px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span class="fa fa-search form-control-feedback"></span>

                <input type="text" class="form-control" placeholder="Search"
                  style={{ marginTop: '-15px', height: '30px' }}
                  className='w-25'
                  value={searchValue}
                  onChange={(e) => { setSearchValue(e.target.value) }}
                />

                <Form.Select size="sm" style={{ width: '70px' }} value={rows} onChange={(e) => { setRows(e.target.value) }}>
                  <option value="05">05</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Form.Select>
              </div>
            </div>


            {isLoading ? (
              <div className='w-100'>
                <NewLoader />
              </div>
            ) : (
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                    <th style={{ paddingRight: '0px', }} ><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {trailerList?.map((trailer, index) => (
                    <tr key={index}>
                      <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                      <td ><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                      <td>{trailer?.name}</td>
                      <td>SpeedoTrack GPS Tracking</td>
                      <td>
                        <a href="#editEmployeeModal" className="edit" data-toggle="modal" title="Duplicate" >
                          <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                        </a>{" "}

                        <a href="#" className="delete" data-toggle="modal" title="Edit" onClick={() => { funcFetchTrailer(trailer?._id); setTrailerIdForEdit(trailer?._id) }}><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                        <a href="#" className="delete" data-toggle="modal" title="Delete" onClick={() => { funcDeleteTrailer(trailer?._id) }}><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                        <a href="#" className="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </Table>
            )}
          </div>
          <div className='w-100 d-flex justify-content-end'>
          <Pagination size="sm">
            <Pagination.Prev
              disabled={pageNumber === '1' ? true : false}
              onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
            />
            {
              (
                <>
                  {Array.from({ length: totalPages }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      onClick={() => setPageNumber((i + 1).toString())}
                      active={pageNumber === (i + 1).toString() ? true : false}
                    >{i + 1}
                    </Pagination.Item>
                  ))}
                </>
              )
            }
            <Pagination.Next
              disabled={pageNumber === totalPages.toString() ? true : false}
              onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
            />
          </Pagination>
          </div>
        </div>

      </div>
      <div className="card-footer d-flex bg-light" style={{ padding: "2px" }}>
        <button type="button" className="btn btn-primary btn-sm btn-block" onClick={() => setShowAddModal(true)}><FiPlus style={{ fontSize: '15px' }} /> </button>
        <button type="button" className="btn btn- btn-sm btn-block" onClick={funcFetchTrailerList}><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
        <button type="button" className="btn btn- btn-sm btn-block"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

      </div>





      {/* Add trailer  */}
      <Modal
        size="sm"
        show={showAddModal}
        backdrop="static"
        onHide={() => setShowAddModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Add Object trailer properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="row align-items-start">
            <div className="col">
              <div className="" style={{ paddingBottom: '20px' }}>
                <div className="row align-items-start">
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Name
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      style={{ minWidth: '200px' }}
                      value={addTrailerName}
                      onChange={(e) => { setAddTrailerName(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    RFID or iButton
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addTrailerAssignId}
                      onChange={(e) => { setAddTrailerAssignId(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Transport modal
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addTrailerModel}
                      onChange={(e) => { setAddTrailerModel(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    VIN
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addTrailerVin}
                      onChange={(e) => { setAddTrailerVin(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Plate number
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={addTrailerPlateNo}
                      onChange={(e) => { setAddTrailerPlateNo(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="file" className="btn btn-secondary btn-sm" style={{ minWidth: '100px' }} onClick={funcAddTrailer}>Save</button>
            <button type="button" className="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }} onClick={() => { setShowAddModal(false); clearAddTrailerData() }}>Cancel</button>

          </div>


        </Modal.Body>
      </Modal>


      {/* Edit trailer  */}
      <Modal
        size="sm"
        show={showEditModal}
        backdrop="static"
        onHide={() => setShowEditModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        ClassName="custom-modal"
        style={{ marginLeft: '-14%' }}

      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm" style={{ fontSize: '15px' }}>
            Edit Object trailer properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <div className="row align-items-start">
            <div className="col">
              <div className="" style={{ paddingBottom: '20px' }}>
                <div className="row align-items-start">
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Name
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editTrailerName}
                      onChange={(e) => { setEditTrailerName(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    RFID or iButton
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editTrailerAssignId}
                      onChange={(e) => { setEditTrailerAssignId(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Transport modal
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editTrailerModel}
                      onChange={(e) => { setEditTrailerModel(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    VIN
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editTrailerVin}
                      onChange={(e) => { setEditTrailerVin(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="row align-items-start" style={{ marginTop: '5px' }}>
                  <div className="col" style={{ marginLeft: '20px' }}>
                    Plate number
                  </div>
                  <div className="col">
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '200px' }}
                      value={editTrailerPlateNo}
                      onChange={(e) => { setEditTrailerPlateNo(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="file" className="btn btn-secondary btn-sm" style={{ minWidth: '100px' }}>Save</button>
            <button type="button" className="btn btn-secondary btn-sm" style={{ minWidth: '100px', marginLeft: '10px' }}>Cancel</button>

          </div>


        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MyAccount
