import { useEffect, useState } from 'react';
import { Button, Offcanvas, Card, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// import Button from 'react-bootstrap/Button';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Chart from "react-apexcharts";
// import { Button, Col } from 'react-bootstrap'
import "./Monitor.css";

// import ReactSpeedometer from "react-d3-speedometer";

const MonitorDataBar = ({ name, ...props }) => {

  const vehicleData = useSelector(state => state.generalTracking.generalData)



  
  const closeCard = () => {
    props.setShowMonitorDataBar(false)
  };





  return (

    <div>
      {props.showMonitorDataBar && (
        <div>
          <div style={{
            position: "fixed", top: '-54px', right: '-5px', marginBottom: '5px',
          }}>

            <div className="container" style={{ height: '10px' }}>
              <section className="mx-auto my-5 " style={{ width: '22vw', height: '10px', overflowX: '' }}>
                <div className="card overflow-scroll" style={{ overflowX: '', height: '99.5vh', }}>

                  <div className="card-body d-flex flex-row">
                    <div>
                      <h6 className="card-title font-weight-bold mb-2" style={{ fontSize: '11px' }}>{vehicleData?.object}</h6>
                      <p className="card-text" style={{ fontSize: '11px', color: 'green', marginTop: '-5px' }}><img src={require('./images/clock.png')} style={{ width: '10px' }} /> {" "}{vehicleData?.route_length}</p>
                    </div>

                    <button type="button" className="btn-close" aria-label="Close" style={{ marginLeft: '65%' }} onClick={closeCard}></button>
                  </div>
                  <div className="bg-image hover-overlay ripple rounded-0" data-mdb-ripple-color="light" style={{ marginTop: '-85%', width: '70%', marginLeft: '20px' }} >
                    <img className="img-fluid" src={require('./images/car.png')}
                      alt="Card image cap" style={{ marginTop: '-26%' }} />  <hr style={{ marginTop: '-18%' }}></hr>
                  </div>

                  <div style={{ marginTop: '-5px', marginLeft: '15px' }}>
                    <div style={{
                      color: '#2E384D',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: '22px',
                      color: 'green'

                    }}>
                      Location
                    </div>
                    <span style={{
                      color: '#8798AD',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '22px',
                      marginTop: '-5px',
                      padding: '5px',

                    }}>Ranchi Jharkhand sector || market H.E.C   colony old vidhan sabha dhuruwa..</span>
                    <span><div className="d-flex" style={{ marginTop: '6px' }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: '-5px'
                      }}>Current Trip</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',

                      }}>648.01</div>

                    </div></span>

                    <span><div className="d-flex" style={{ marginTop: '-8px' }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: ''
                      }}>Driver</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        marginLeft: '25px'

                      }}>{vehicleData?.driver}</div>

                    </div></span>

                    <span><div className="d-flex" style={{ marginTop: '-8px' }}>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontWeight: '700',

                        fontStyle: 'normal', marginLeft: ''
                      }}>Mobile No.</div>
                      <div className="p-2 flex-fill" style={{
                        color: 'green',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        marginLeft: '35px'

                      }}>8767545654</div>

                    </div></span>




                  </div>



                  <div className="d-flex" style={{ marginTop: '6px' }}>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontWeight: '700',

                      fontStyle: 'normal', marginLeft: '10px'
                    }}>{vehicleData?.route_length}</div>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',

                    }}>{vehicleData?.fuel_consumption}</div>
                    <div className="p-2 flex-fill" style={{
                      color: 'green',
                      fontSize: '11px',
                      fontStyle: 'normal',
                      fontWeight: '700',

                    }}>{vehicleData?.move_duration?.slice(0, 12)}</div>
                  </div>

                  <div className="d-flex">
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: '10px'
                    }}>Distance</div>
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: '-10px'
                    }}>fuel consumed</div>
                    <div className="p-2 flex-fill" style={{
                      fontSize: '11px',
                      marginTop: '-12px',
                      fontStyle: 'normal', marginLeft: ''
                    }}>Time</div>
                  </div>






                  <hr style={{ marginTop: "4px" }}></hr>



                  <div style={{ display: 'flex', marginTop: '20px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/gas-station.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Avg. Fuel
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/work.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Fuel Cons...
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_consumption}</span></span>
                      </div>
                    </Card>
                  </div>



                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/loading.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Stop Duration
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/routes.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Route Length
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_consumption}</span></span>
                      </div>
                    </Card>
                  </div>



                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/work.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Engine Work
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}


                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/speed.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Top Speed
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.top_speed}</span></span>
                      </div>
                    </Card>



                  </div>

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/clocks.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Stop Count
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.stop_count}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/work-time.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Engine Idle
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.engine_idle}</span></span>
                      </div>
                    </Card>



                  </div>

                  {/* move duration fuel cost  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/time.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Move Duration
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.move_duration}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/fuel.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Fuel Cost
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_cost}</span></span>
                      </div>
                    </Card>



                  </div>


                  {/* ododmeter and engine hour  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/gas.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Odometer
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.odometer}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/in-process.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Engine Hours
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.engine_hours}</span></span>
                      </div>
                    </Card>



                  </div>



                  {/*driver and trailer  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/license.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Driver
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.driver}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/truck.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Trailer
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.trailer}</span></span>
                      </div>
                    </Card>



                  </div>


                  {/*driver and trailer  */}

                  {/* <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/truck.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Avg. Speed
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData.avg_speed}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/uptime.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Overspeed Count
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData.overspeed_count}</span></span>
                      </div>
                    </Card>



                  </div> */}


                  {/*averege  and trailer  */}

                  <div style={{ display: 'flex', marginTop: '-18px' }}>
                    <Card style={{ border: 'none' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '150px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '10px',
                      }}>
                        <img src={require('./images/gas-station.png')} style={{
                          width: '20px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '20px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '8px'
                        }}>Avg. Fuel
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.avg_fuel_consumption}</span></span>
                      </div>
                    </Card>{" "}
                    <Card style={{ border: 'none', marginLeft: '4px' }}>
                      <div className="" style={{
                        display: 'flex', alignItems: 'center', width: '110px',
                        height: '50px',
                        flexShrink: '0', borderRadius: '7px',
                        background: '', marginTop: '-5px', marginLeft: '15px', marginRight: '15px',
                      }}>
                        <img src={require('./images/work.png')} style={{
                          width: '18px',
                          marginTop: '',
                          marginLeft: '8px',
                          height: '18px',
                          flexShrink: '0'
                        }} />{" "}
                        <span style={{
                          color: '',

                          fontSize: '11px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          opacity: '0.8',
                          marginLeft: '10px'
                        }}>Fuel Cons...
                          <br></br>
                          <span style={{ marginTop: '8px', fontSize: '11px' }}>{" "}{vehicleData?.fuel_consumption}</span></span>
                      </div>
                    </Card>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>





  )
}

export default MonitorDataBar;