import React, { useEffect, useState } from 'react'
import { FaChalkboardUser } from "react-icons/fa6";
import { Table, Button, Pagination, Form, Modal } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { IoMdRefresh } from "react-icons/io";
import NewLoader from './speedo-asset/Loader';





const UnusedDevice = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [unusedDeviceList, setUnusedDeviceList] = useState([]);
    const [unusedDeviceCount, setUnusedDeviceCount] = useState('');
    //pagination
    const [rows, setRows] = useState('5')
    const [pageNumber, setPageNumber] = useState('1');
    const [totalPages, setTotalPages] = useState('');
    const [searchValue, setSearchValue] = useState('');
    //delete
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [imeiForDelete, setImeiForDelete] = useState('');
    const [deviceDeleted, setDeviceDeleted] = useState(true);
    //select
    const [selectedDevices, setSelectedDevices] = useState([]);


    const funcFetchUnusedDevice = async () => {
        try {
            setIsLoading(true)
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/cpanel/unused-device?rows=${rows}&page=${pageNumber}&sort=-dt_server&search-field=all&search-value=${searchValue}`, requestOptions)
            const result = await response.json()
            setUnusedDeviceCount(result?.total)
            setTotalPages(result?.pages)
            setPageNumber(result?.page.toString())
            setUnusedDeviceList(result?.result)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        funcFetchUnusedDevice()
    }, [pageNumber, rows, searchValue])


    const funcDeleteDevice = async () => {
        try {
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch(`https://gps.speedotrack.com/api/cpanel/unused-device/${imeiForDelete}`, requestOptions)
            const result = await response.json()
            if (response.ok) {
                setDeviceDeleted(false);
                    setDeleteCheck(false);
                    funcFetchUnusedDevice();
                    setDeviceDeleted(true);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const bulkActionDelete = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "cmd": "delete",
                "devices": selectedDevices
            });

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch("https://gps.speedotrack.com/api/cpanel/unused-device/bulk", requestOptions)
            const result = await response.json()
            if(response.ok){
                setDeviceDeleted(false)
                setDeleteCheck(true)
                funcFetchUnusedDevice()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const toggleDeviceSelect = (imei) => {
        if (selectedDevices.includes(imei)) {
            setSelectedDevices(selectedDevices.filter(item => item !== imei))

        } else {
            setSelectedDevices([...selectedDevices, imei])
        }
    }

    const toggleAllDeviceSelect = () => {
        if (unusedDeviceList.length === selectedDevices.length) {
            setSelectedDevices([])
        } else {
            let allImeis = unusedDeviceList.map((device) => device.imei)
            setSelectedDevices(allImeis)
        }

    }
    
    useEffect(() =>{
        console.log(selectedDevices)
    },[selectedDevices])


    return (
        <div>
            <div class="card" style={{ marginTop: '-10px' }}>
                <div class="card-body" style={{ minHeight: '20px' }}>
                    <div class="row">
                        <div class="col-sm-6 d-flex" style={{ marginTop: '5px' }}>
                            <FaChalkboardUser style={{ fontSize: '20px' }} />
                            <span style={{ marginLeft: '8px' }}>All Unused Devices {unusedDeviceCount} </span>

                            <Form.Select aria-label="Default select example mx-5"
                                style={{ width: '65px', height: '32px', marginLeft: '25px', marginTop: '-5px' }} size="sm"
                                value={rows}
                                onChange={(e) => { setRows(e.target.value) }}
                            >
                                <option value="5">05</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Form.Select>
                        </div>



                        <div class="col-sm-6" style={{ marginTop: '5px' }}>
                            <div className="input-group rounded d-flex" >
                                <div class="form-group has-search" style={{ marginTop: '5px', minWidth: '350px', marginLeft: '-13px' }}>
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search..." style={{ marginTop: '-15px' }}
                                        value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }}
                                    />
                                </div>

                                <Button variant="outline-danger" style={{ marginTop: '-10px', marginLeft: '10px', height: '40px' }}
                                    disabled={selectedDevices.length > 0 ? false : true}
                                    onClick={bulkActionDelete}
                                >Delete</Button>
                            </div>
                            <div className="form-group">
                            </div>
                        </div>
                    </div>


                   
                </div>
               

                
            </div>
            {isLoading ? (
                <NewLoader />
            ) : (
                <>
                    <div class="card" style={{ marginTop: '-20px' }}>
                        <div class="card-body">
                            <Table striped hover size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                                        <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                            checked={unusedDeviceList.length === selectedDevices.length}
                                            onChange={toggleAllDeviceSelect}
                                        /></th>
                                        <th>IMEI</th>
                                        <th>Last Connection</th>
                                        <th>Protocol</th>
                                        <th>Net. Protocol</th>
                                        <th>Port</th>
                                        <th>Conn.. attempts</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {unusedDeviceList?.map((device, index) => (
                                        <tr key={index}>
                                            <td style={{ marginLeft: '50px' }}>{index + 1}</td>
                                            <td >
                                                <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                                    onChange={() => { toggleDeviceSelect(device.imei) }}
                                                    checked={selectedDevices.includes(device.imei)}
                                                />
                                            </td>
                                            <td>{device?.imei}</td>
                                            <td>{device?.dt_server}</td>
                                            <td>{device?.protocol}</td>
                                            <td>{device?.net_protocol}</td>
                                            <td>{device?.port}</td>
                                            <td>{device?.count}</td>
                                            <td>
                                                <a href="#" class="delete" data-toggle="modal" title="Delete"
                                                    onClick={() => { setDeleteCheck(true); setImeiForDelete(device.imei) }}
                                                ><TfiTrash style={{ fontSize: "15px" }} className='text-danger' /></a>{" "}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} className='px-2'>
                            <Button variant="link" onClick={funcFetchUnusedDevice}><IoMdRefresh /></Button>

                            <Pagination size="sm">
                                <Pagination.Prev
                                    disabled={pageNumber === '1' ? true : false}
                                    onClick={() => setPageNumber((parseInt(pageNumber) - 1).toString())}
                                />
                                {
                                    (
                                        <>
                                            {Array.from({ length: totalPages }).map((_, i) => (
                                                <Pagination.Item
                                                    key={i}
                                                    onClick={() => setPageNumber((i + 1).toString())}
                                                    active={pageNumber === (i + 1).toString() ? true : false}
                                                >{i + 1}
                                                </Pagination.Item>
                                            ))}
                                        </>
                                    )
                                }
                                <Pagination.Next
                                    disabled={pageNumber === totalPages.toString() ? true : false}
                                    onClick={() => setPageNumber((parseInt(pageNumber) + 1).toString())}
                                />

                            </Pagination>
                        </div>
                    </div>


                    <ConfirmDeleteModal
                        show={deleteCheck}
                        onHide={() => setDeleteCheck(false)}
                        funcDeleteDevice={funcDeleteDevice}
                        deviceDeleted={deviceDeleted}
                    />

                </>
            )}

        </div>
    )
}



const ConfirmDeleteModal = (props) => {

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {props.deviceDeleted ? (
                    <h6 className='text-danger'>Are you sure you want to delete this unused device ? </h6>
                ) : (
                    <h6 className='text-success'>Device deleted successfully !!</h6>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { props.funcDeleteDevice(); }}>Confirm</Button>
                <Button onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UnusedDevice;
