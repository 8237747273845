import React from 'react'
import { TbRouteAltLeft } from "react-icons/tb";
import { TbRoute } from "react-icons/tb";
import { GoStop } from "react-icons/go";
import { CiRoute } from "react-icons/ci";
import { MdOutlineAltRoute } from "react-icons/md";
import { RiSpeedUpFill } from "react-icons/ri";
import { RiSpeedLine } from "react-icons/ri";
import { LuFuel } from "react-icons/lu";
import { BsFuelPump } from "react-icons/bs";
import { SiGoogleearthengine } from "react-icons/si";
import { PiEngineBold } from "react-icons/pi";
import { SlSpeedometer } from "react-icons/sl";
import { HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { FaTrailer } from "react-icons/fa6";
import { TbClockHour3 } from "react-icons/tb";

const DeviceRoute = () => {
  
  return (
    <div>
      <div class="card">
        <div class="card-body">
          <div class="card">
            <div class="card-body" style={{ minHeight: '20px' }}>
              <div class="row">
                <div class="col">
                  <h6>Route -  Object:	10H10-9400</h6>
                </div>
                <div class="col-md-auto">
                  <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style={{ marginTop: '-20px' }}>
            <div class="col-8" >
              <div id="map-container-google-1" class="z-depth-1-half map-container" style={{ height: '250px' }}>
                <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
                  style={{ border: '0' }} allowfullscreen></iframe>
              </div>

            </div>
            <div class="col-4">
              <div class="col-4" style={{ marginTop: '20px', marginLeft: '-12px' }}>
                <div class="card" style={{ width: '28rem' }}>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><TbRouteAltLeft />{" "}Route start</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>
                    <li class="list-group-item" >
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><TbRoute />{" "}Route length</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>
                    <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><MdOutlineAltRoute />{" "}Stop duration</div>
                      <div class=" bd-highlight">6.91 km</div>

                    </div>
                    </li>
                    <li class="list-group-item" >
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><GoStop />{" "}Route end</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card" style={{ width: '28rem', marginTop: '-10px' }}>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><RiSpeedUpFill />{" "}Top speed</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>

                      </div>
                    </li>
                    <li class="list-group-item" >
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><RiSpeedLine />{" "}Overspeed count</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>

                      </div></li>
                    <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><LuFuel />{" "}Avg. fuel cons</div>
                      <div class=" bd-highlight">6.91 km</div>

                    </div></li>
                    <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><BsFuelPump />{" "}Fuel cost</div>
                      <div class=" bd-highlight">6.91 km</div>

                    </div></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4"><div class="col-4" style={{ marginTop: '20px', marginLeft: '-12px' }}>
              <div class="card" style={{ width: '28rem' }}>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><SiGoogleearthengine />{" "}Engine work</div>
                      <div class=" bd-highlight">2023-12-16 00:00:04</div>
                    </div>
                  </li>
                  <li class="list-group-item" >
                    <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                      <div class=" bd-highlight "><PiEngineBold />{" "}	Engine idle</div>
                      <div class=" bd-highlight">2023-12-16 00:00:04</div>
                    </div>
                  </li>
                  <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                    <div class=" bd-highlight "><SlSpeedometer />{" "}Odometer</div>
                    <div class=" bd-highlight">6.91 km</div>

                  </div>
                  </li>

                </ul>
              </div>

            </div></div>
            <div class="col-4">
              <div class="col-4" style={{ marginTop: '20px', marginLeft: '-12px' }}>
                <div class="card" style={{ width: '28rem' }}>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><HiMiniWrenchScrewdriver />{" "}Driver</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>
                    <li class="list-group-item" >
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><TbClockHour3 />{" "}		Engine hours</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>

                    <li class="list-group-item" >
                      <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><FaTrailer />{" "}	Trailer</div>
                        <div class=" bd-highlight">2023-12-16 00:00:04</div>
                      </div>
                    </li>
                  </ul>
                </div>

              </div>

            </div>

           

          </div>



        </div>
      </div>
    </div>
  )
}

export default DeviceRoute
