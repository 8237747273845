import { configureStore } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';

//importing of the 
import loginSlice from './login/loginSlice';
import trackingSlice from './monitor/trackingSlice';
import trackingGeneralSlice from './monitor/trackingGeneralSlice';
import historySlice from './history/historySlice';



export const store = configureStore({
  reducer: {
    login: loginSlice,
    setting: settingReducer,
    tracking: trackingSlice,
    generalTracking: trackingGeneralSlice,
    history: historySlice
  }
});
