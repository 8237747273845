import React from 'react'
import { Card } from 'react-bootstrap'
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";


const UserInterfaces = () => {
  return (
    <div>
      <Card style={{ height: '20px', marginTop: '-20px' }}>

        <div class="card" style={{ paddingBottom: '20px' }}>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Notification
          </div>
          <div class="row align-items-start">
            <div class="col" style={{ marginLeft: '20px' }}>
              Push notification
            </div>
            <div class="col">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            </div>
          </div>

          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              New chat message sound alert
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>alarm1.mp3</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <button type="button" class="btn btn-primary btn-sm" style={{ width: '15%', marginLeft: '10px' }}>Play</button>

            </div>

          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Dashboard
          </div>
          <div class="row align-items-start">
            <div class="col" style={{ marginLeft: '20px' }}>
              Open after login
            </div>
            <div class="col">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Map
          </div>
          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Map startup position
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Remember last</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Map icon size
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>100%</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>

          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              History route color
            </div>
            <div class="col d-flex">
              <input type="color" />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              History route highlight color
            </div>
            <div class="col d-flex">
              <input type="color" />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: '10px' }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Object details popup on cluster mouse hover
            </div>
            <div class="col">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Groups
          </div>
          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Collapsed
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}<span style={{ marginLeft: '5px' }}>Objects</span>
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}>Markers</span>
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}>Routes</span>
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '5px' }} /> {" "}<span style={{ marginLeft: '5px' }}>Zones</span>
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Object list
          </div>

          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Details
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Time(Position)</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              No connection color
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
              <input type="color" style={{ marginLeft: '10px' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Stopped color
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
              <input type="color" style={{ marginLeft: '10px' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Moving color
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
              <input type="color" style={{ marginLeft: '10px' }} />
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Engine idle color
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
              <input type="color" style={{ marginLeft: '10px' }} />
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Data list
          </div>
          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Position
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Bottom panel with icons</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Items
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>All selected</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="card-body text-primary" style={{ minHeight: '30px' }}>
            Other
          </div>
          <div class="row align-items-start" style={{ marginTop: "" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Language
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>English</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Unit ofdistance
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Kilometer</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Unit of capacity
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Liter</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Unit of temperature
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>Celsius</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Currency
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>00</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Time Zone
            </div>
            <div class="col d-flex">
              <select class="form-select" aria-label="Default select example" style={{ width: '50%' }}>
                <option selected>00</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="row align-items-start" style={{ marginTop: "10px" }}>
            <div class="col" style={{ marginLeft: '20px' }}>
              Daylight saving time (DST)
            </div>
            <div class="col d-flex">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
              <input type="date" style={{ marginLeft: '10px' }} />
              <select class="form-select" aria-label="Default select example" style={{ width: '20%', marginLeft: '5px', borderColor: '#D5D5D5' }}>
                <option selected>00:00</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select> -
              <input type="date" style={{ marginLeft: '10px' }} />
              <select class="form-select" aria-label="Default select example" style={{ width: '20%', marginLeft: '5px', borderColor: '#D5D5D5' }}>
                <option selected>00:00</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-sm" style={{ width: '8%', marginLeft: '20px', marginTop: '15px' }}>Save</button>

        </div>
        <div class="card-footer d-flex bg-light" style={{padding:"2px"}}>
        <button type="button" class="btn btn-primary btn-sm btn-block" title="Add"><FiPlus style={{fontSize:'15px'}}/> </button>
        <button type="button" class="btn btn- btn-sm btn-block" title="Refresh"><HiOutlineRefresh style={{fontSize:'15px'}}/> </button>
        <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{fontSize:'15px'}}/> </button>

      </div>
      </Card>
      
    </div>
  )
}

export default UserInterfaces
