import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table } from 'react-bootstrap';
import "./Style.css"


import { TfiTrash } from "react-icons/tfi";
import DeleteUsageModal from './DeleteUsageModal';




const SmsSetting = (props) => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [deleteUsage, setDeleteUsage] = useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>
      <div>
        <div>

          <div class="row " style={{ marginTop: '-10px', marginLeft: '4px', }}>

          </div>

          <div className="table-responsive" style={{ marginTop: '10px' }}>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th>Sl No.</th>
                  <th >Date</th>
                  <th>E-mail</th>
                  <th>Sms</th>
                  <th>Web hook</th>
                  <th>Whatshpp</th>
                  <th>API</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>2023-07-03</td>
                  <td>{props.userDetails?.usage_daily_count?.email}</td>
                  <td>{props.userDetails?.usage_daily_count?.sms}</td>
                  <td>{props.userDetails?.usage_daily_count?.webhook}</td>
                  <td>{props.userDetails?.usage_daily_count?.whatsApp}</td>
                  <td>{props.userDetails?.usage_daily_count?.api}</td>

                  <td>
                    <a class="delete" data-toggle="modal" title="Delete" onClick={() => setDeleteUsage(true)}><TfiTrash style={{ width: '23px' }} /></a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>




        </div>


      </div>

      <DeleteUsageModal
        show={deleteUsage}
        onHide={() => setDeleteUsage(false)}
      />

    </div>
  )
}

export default SmsSetting
