import React, { useState, useRef, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';


import { CiLocationArrow1 } from "react-icons/ci";
import { CiStreamOn } from "react-icons/ci";
import { CiAlarmOn } from "react-icons/ci";
import { CiDeliveryTruck } from "react-icons/ci";
import { CiRainbow } from "react-icons/ci";
import { CiMoneyCheck1 } from "react-icons/ci";
import { PiSimCardThin } from "react-icons/pi";
import { CiWavePulse1 } from "react-icons/ci";
import { CiAlignBottom } from "react-icons/ci";
import { PiTriangleLight } from "react-icons/pi";
import "./style.css";
import { CiLocationOn } from "react-icons/ci";
import { BsSpeedometer2 } from "react-icons/bs";
import { CiClock2 } from "react-icons/ci";
import { CiBatteryCharging } from "react-icons/ci";
import { CiBatteryEmpty } from "react-icons/ci";
import { LuFuel } from "react-icons/lu";
import { CiGps } from "react-icons/ci";
import { CiBookmarkCheck } from "react-icons/ci";
import { RiArrowUpDoubleFill } from "react-icons/ri";
import { IoArrowForwardCircle } from "react-icons/io5";
import { HiArrowCircleUp } from "react-icons/hi";
import { RiArrowUpSFill } from "react-icons/ri";
import { CiCreditCard2 } from "react-icons/ci";
import { CiHeadphones } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";






const TestCard = (props) => {
    const trackingData = useSelector(state => state.tracking.monitorData);
    const containerRef = useRef(null);

    //declaration of the state variables
    const [vehicleData, setVehicleData] = useState({})
    const [sensorData, setSensorData] = useState({})

    const handleScroll = (e) => {
        const delta = Math.max(-1, Math.min(1, e.nativeEvent.wheelDelta || -e.nativeEvent.detail));
        containerRef.current.scrollLeft -= delta * 40;
        e.preventDefault();
    };
    const [cardVisible, setCardVisible] = useState(true);

    const handleCancelClick = () => {
        setCardVisible(false);
    };

    const [isCardVisible, setIsCardVisible] = useState(true);

    const toggleCardVisibility = () => {
        setIsCardVisible(!isCardVisible); // Toggles the visibility state
    };

    const [isCardOpen, setIsCardOpen] = useState(false);

    const openCard = () => {
        setIsCardOpen(true); // Set the state to open the card
    };

    const closeCard = () => {
        setIsCardOpen(false); // Set the state to close the card
    };


    useEffect(() => {
        const vehicle = trackingData.find((vehicle) => vehicle.imei === props.selectedVehicleImei)
        setVehicleData(vehicle)

    }, [trackingData])






    return (
        <div>




            <a style={{ zIndex: '15', float: 'right', cursor: 'pointer' }} variant="" data-bs-toggle="tooltip" title="Collapse" onClick={toggleCardVisibility}
            ><RiArrowUpSFill style={{ marginBottom: '-60px', fontSize: '32px', zIndex: '10', }} className='text-primary' /></a>
            <Button style={{ zIndex: '15' }} variant="" data-bs-toggle="tooltip" title="Collapse"
            ><IoArrowForwardCircle style={{ marginLeft: '-32px', fontSize: '32px', zIndex: '10', }} className='text-primary' /></Button>
            {isCardVisible && (

                <Card style={{ width: "100vw", display: "flex", overflowX: "scroll", height: '170px', marginBottom: '0px', borderRadius: '0px', overflowY: 'hidden' }} ref={containerRef}
                    onWheel={handleScroll}>


                    <div class="row" >
                        <div class="" >
                            <div class="" style={{ width: '20rem', height: '20px', }}>
                                <div class="" style={{ backgroundColor: '#F5F5F5' }}>
                                    <h5 class="card-title" style={{ marginTop: '5px', fontSize: '14px' }}><CiLocationArrow1 /> {vehicleData?.name}</h5>
                                    <hr></hr>

                                </div>
                                <div class="card" style={{ width: '20rem', marginTop: '-10px', overflow: 'auto', height: '125px' }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5" style={{ marginTop: '50px', fontSize: '13px' }}>Address:</div>
                                                <div class="col-7" style={{ fontSize: '11px', }}>Chand Uddan, Mohammadpur, Dhaka, Dhaka Metropolitan, <br>
                                                </br>Dhaka District, Dhaka Division, 1207, Bangladesh</div>
                                            </div>
                                        </li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '11px' }}>Time  (position)</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.dt_tracker}
                                            </div>
                                        </div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '11px' }}>Time (server)	</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.dt_server}
                                            </div></div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '11px' }}>Driver:		</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}><a href="#" onClick={openCard}>Speedotrack</a>
                                            </div></div></li>
                                    </ul>
                                </div>


                            </div>

                        </div>

                        <div class="" style={{ marginLeft: "20.4%", marginTop: '-25px' }}>
                            <div class="" style={{ width: '107.8rem', height: '20px', }}>
                                <div class="" style={{ backgroundColor: '#F5F5F5' }}>
                                    <h5 class="card-title" style={{ marginTop: '5px', fontSize: '14px', marginLeft: '10px' }}><CiStreamOn /> Sensors</h5>
                                    <hr></hr>

                                </div>
                                <div class="card" style={{ width: '34rem', marginTop: '-10px', }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">

                                                {vehicleData?.sensors?.acc ? (
                                                    <>
                                                        <div class="col-3" style={{ marginTop: '', fontSize: '11px' }}><CiAlarmOn style={{ fontSize: '16px' }} />{" "}{vehicleData?.sensors?.acc?.name}</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.acc?.valueFull}</div>
                                                    </>
                                                ) : null}


                                                {vehicleData?.protocol ? (
                                                    <>
                                                        <div class="col-3" style={{ fontSize: '11px', }}><CiDeliveryTruck style={{ fontSize: '16px' }} /> {" "}Model</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.protocol}</div>

                                                    </>
                                                ) : null}

                                            </div>
                                        </li>
                                        <li class="list-group-item"><div class="row">

                                            {vehicleData?.odometer ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><CiRainbow style={{ fontSize: '16px' }} />{" "}Odometer</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.odometer}</div>

                                                </>
                                            ) : null}


                                            <div class="col-3" style={{ fontSize: '11px', }}><CiMoneyCheck1 style={{ fontSize: '16px' }} />{" "}Plate No.
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>OD 14S 1279
                                            </div>


                                        </div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-3" style={{ fontSize: '11px' }}><PiSimCardThin style={{ fontSize: '14px' }} /> SIM card</div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>5754170276368
                                            </div>

                                            {vehicleData?.status ? (
                                                <>
                                                    <div class="col-2" style={{ fontSize: '11px', }}><CiWavePulse1
                                                        style={{ fontSize: '17px' }} />{" "}Status
                                                    </div>
                                                    <div class="col-4" style={{ fontSize: '9px', }}>{vehicleData?.status}
                                                    </div>
                                                </>
                                            ) : null}

                                        </div></li>

                                        <li class="list-group-item"><div class="row">

                                            {vehicleData?.location_data?.altitude ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><CiAlignBottom style={{ fontSize: '16px' }} />{" "}Altitude</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.altitude} </div>
                                                </>
                                            ) : null}

                                            {vehicleData?.location_data?.angle ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px', }}><PiTriangleLight style={{ fontSize: '15px' }} />{" "}Angle
                                                    </div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.angle}<sup>o</sup>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div></li>
                                    </ul>
                                </div>




                            </div>

                        </div>

                        <div class="" style={{ marginLeft: "55%", marginTop: '-24px' }}>
                            <div class="" style={{ width: '34rem', height: '20px', }}>
                                <div class="" style={{ backgroundColor: '#F5F5F5' }}>


                                </div>
                                <div class="card" style={{ width: '34rem', marginTop: '40px', }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">

                                                {vehicleData?.sensors?.gps ? (
                                                    <>
                                                        <div class="col-3" style={{ marginTop: '', fontSize: '11px' }}><CiAlarmOn style={{ fontSize: '16px' }} />{" "}{vehicleData?.sensors?.gps?.name}</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.gps?.valueFull}</div>
                                                    </>
                                                ) : null}


                                                {vehicleData?.sensors?.gsm ? (
                                                    <>
                                                        <div class="col-3" style={{ fontSize: '11px', }}><CiDeliveryTruck style={{ fontSize: '16px' }} /> {" "}{vehicleData?.sensors?.gsm?.name}</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.gsm?.valueFull}</div>
                                                    </>
                                                ) : null}

                                            </div>
                                        </li>
                                        <li class="list-group-item"><div class="row">

                                            {vehicleData?.sensors?.batt ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><CiRainbow style={{ fontSize: '16px' }} />{" "}{vehicleData?.sensors?.batt?.name.length > 15 ? (vehicleData?.sensors?.batt?.name.slice(0, 13) + "...") : (vehicleData?.sensors?.batt?.name)}</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.batt?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}

                                            {vehicleData?.sensors?.batt01 ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px', }}><CiMoneyCheck1 style={{ fontSize: '16px' }} />{" "}{vehicleData?.sensors?.batt01?.name?.length > 13 ? (vehicleData?.sensors?.batt01?.name.slice(0, 13) + "...") : (vehicleData?.sensors?.batt01?.name)}
                                                    </div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.batt01?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}

                                        </div></li>
                                        <li class="list-group-item"><div class="row">

                                            {vehicleData?.sensors?.do ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><PiSimCardThin style={{ fontSize: '14px' }} />{vehicleData?.sensors?.do?.name}</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.do?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}

                                            {vehicleData?.sensors?.cust ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px', }}><CiWavePulse1 style={{ fontSize: '17px' }} />{vehicleData?.sensors?.cust?.name.length > 10 ? (vehicleData?.sensors?.cust?.name.slice(0, 12) + "...") : (vehicleData?.sensors?.cust?.valueFull)}
                                                    </div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.cust?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}

                                        </div></li>
                                        <li class="list-group-item"><div class="row">
                                            {vehicleData?.sensors?.fuel ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><LuFuel style={{ fontSize: '16px' }} />{" "}{vehicleData?.sensors?.fuel?.name}</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.fuel?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}
                                            {vehicleData?.sensors?.di ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px', }}><PiTriangleLight style={{ fontSize: '15px' }} />{" "}{vehicleData?.sensors?.di?.name}
                                                    </div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.sensors?.di?.valueFull}
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div class="" style={{ marginLeft: "89.5%", marginTop: '-34px' }}>
                            <div class="" style={{ width: '36rem', height: '20px', }}>
                                <div class="" style={{ backgroundColor: '#F5F5F5' }}>


                                </div>
                                <div class="card" style={{ width: '39rem', marginTop: '14px', }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">
                                                {vehicleData?.location_data?.lat && vehicleData?.location_data?.lng ? (
                                                    <>
                                                        <div class="col-3" style={{ marginTop: '', fontSize: '11px' }}><CiLocationOn style={{ fontSize: '16px' }} />{" "}Position</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}><a href={`https://www.google.com/maps?q=${vehicleData?.location_data?.lat},${vehicleData?.location_data?.lng}`} target="_blank">{vehicleData?.location_data?.lat},{vehicleData?.location_data?.lng}</a></div>
                                                    </>
                                                ) : null}


                                                {vehicleData?.location_data?.speed ? (
                                                    <>
                                                        <div class="col-3" style={{ fontSize: '11px', }}><BsSpeedometer2 style={{ fontSize: '16px' }} /> {" "}Speed</div>
                                                        <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.speed}</div>
                                                    </>
                                                ) : null}

                                            </div>
                                        </li>
                                        <li class="list-group-item"><div class="row">
                                            {vehicleData?.location_data?.dt_tracker ? (
                                                <>
                                                    <div class="col-3" style={{ fontSize: '11px' }}><CiClock2 style={{ fontSize: '16px' }} />{" "}Time (position)</div>
                                                    <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.dt_tracker.slice(0, 20)}
                                                    </div>
                                                </>
                                            ) : null}
                                            <div class="col-3" style={{ fontSize: '11px', }}><CiClock2 style={{ fontSize: '16px' }} />{" "}Time (server)
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>{vehicleData?.location_data?.dt_server.slice(0, 20)}
                                            </div>
                                        </div></li>
                                        {/* <li class="list-group-item"><div class="row">
                                            <div class="col-3" style={{ fontSize: '11px' }}><CiBatteryCharging style={{ fontSize: '14px' }} /> Battery Voltage</div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>28.3 Volt
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}><CiBatteryEmpty
                                                style={{ fontSize: '17px' }} />{" "}Device Battery
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>Present
                                            </div>
                                        </div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-3" style={{ fontSize: '11px' }}><LuFuel style={{ fontSize: '16px' }} />{" "}Fuel Level</div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>227.61 Liters
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}><CiGps style={{ fontSize: '15px' }} />{" "}GPS Signal
                                            </div>
                                            <div class="col-3" style={{ fontSize: '11px', }}>20
                                            </div></div></li> */}
                                    </ul>
                                </div>




                            </div>

                        </div>


                        <div class="" >
                            <div class="" style={{ width: '20rem', height: '20px', marginLeft: "276vh", marginTop: '-61px' }}>
                                <div class="" style={{ backgroundColor: '#F5F5F5' }}>
                                    <h5 class="card-title" style={{ marginTop: '5px', fontSize: '14px' }}><CiBookmarkCheck /> Events</h5>
                                    <hr></hr>

                                </div>
                                <div class="card" style={{ width: '20rem', marginTop: '-12px', overflow: 'auto', height: '125px' }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="row">
                                                <div class="col-5" style={{ marginTop: '', fontSize: '13px' }}>Address:</div>
                                                <div class="col-7" style={{ fontSize: '11px', }}>Mohammadpuh</div>
                                            </div>
                                        </li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '13px' }}>Time:</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}>24-12-2023 10:59:00 AM
                                            </div>
                                        </div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '13px' }}>Stop duration:	</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}>115h 30min 37s
                                            </div></div></li>
                                        <li class="list-group-item"><div class="row">
                                            <div class="col-5" style={{ fontSize: '13px' }}>Driver:		</div>
                                            <div class="col-7" style={{ fontSize: '11px', }}>Speedotrack
                                            </div></div></li>
                                    </ul>
                                </div>


                            </div>

                        </div>




                    </div>
                </Card>
            )}

            {isCardOpen && (
                <div
                    className="card"
                    style={{
                        position: 'fixed',
                        top: '10px',
                        right: '5px',
                        width: '300px',
                        borderRadius: '10px',
                    }}
                >
                    <div className="card-body" style={{ borderRadius: '10px' }}>
                        <h5 className="card-title bg-primary" style={{ padding: '10px', color: 'white', marginTop: '-21px', marginLeft: '-21px', marginRight: '-21px' }}>Drive information <IoIosClose style={{ marginLeft: '42%', fontSize: '20px', cursor: 'pointer' }} onClick={closeCard} /></h5>
                        <div class="row">
                            <div class="col-6"><img src={require('./images/user-blank.png')} style={{ width: '80%' }} /></div>
                            <div class="col-6" style={{ marginTop: '40px' }}>SpeedoTrack</div>
                        </div>
                        <div class="row">
                            <div class="col-6"><CiCreditCard2 style={{ fontSize: '18px' }} />{" "}ID number:</div>
                            <div class="col-6" style={{ marginTop: '' }}>123456789</div>
                        </div><hr></hr>
                        <div class="row">
                            <div class="col-6"><CiHeadphones style={{ fontSize: '18px' }} /> Phone:</div>
                            <div class="col-6" style={{ marginTop: '' }}>+123456789</div>
                        </div><hr></hr>
                        <div class="row">
                            <div class="col-6"><CiMail style={{ fontSize: '18px' }} /> E-mail:</div>
                            <div class="col-6" style={{ marginTop: '' }}>email@email.com</div>
                        </div><hr></hr>
                        <div class="row">
                            <div class="col-6"><CiDeliveryTruck style={{ fontSize: 'px' }} /> Truck driver:</div>
                            <div class="col-6" style={{ marginTop: '' }}>Truck driver</div>
                        </div>
                    </div>
                </div>
            )}



        </div>






    )
}

export default TestCard
