import React from 'react';
import { Button, Tab, Nav, ButtonGroup, Card } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaCarAlt } from "react-icons/fa";
import { FaCarSide } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { RiPoliceCarFill } from "react-icons/ri";
import { GrCar } from "react-icons/gr";
import Select from 'react-select';


import { Routes, Route, Link, NavLink } from 'react-router-dom';
// import Card from '../../../components/bootstrap/card';



//importing of the componenets
import ObjectSetting from './ObjectSetting';
import EventSetting from './EventSetting';
import TemplateSetting from './TemplateSetting';
import SmsSetting from './SmsSetting';


function EditObjectModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      top
      style={{marginLeft:'8%'}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '14px' }}>
          Edit User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form lg="12">
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Name</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Imei</label>
                <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px', marginLeft: '10px', marginRight: '5px' }} />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}> GPS Device Model</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Sim Number</label>
                <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px', marginLeft: '10px' }} />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Plate Number</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Device Expiry</label>
                <input type="password" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px', marginLeft: '10px' }} />
              </div>
            </div>
            <div class="form-row d-flex">
              <div class="form-group col-md-6">
                <label for="inputEmail4" style={{ fontSize: '13px' }}>Vehicle Model</label>
                <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
              </div>
              <div class="form-group col-md-6">
                <label for="inputPassword4" style={{ fontSize: '13px', marginLeft: '10px' }}>Vehicle Types</label>
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" style={{ marginLeft: '10px' }} >
                  <div class="btn-group mr-2" role="group" aria-label="First group" style={{ marginTop: '5px' }}>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><FaCarAlt />
                    </span>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><FaCarSide />

                    </span>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><IoCarSport />
                    </span>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><RiPoliceCarFill />

                    </span>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><GrCar />

                    </span>
                    <span type="" class="ml-2" style={{ marginLeft: '3px', fontSize: '25px' }}><i class='fas fa-truck-pickup'></i>

                    </span>

                  </div>
                </div>
              </div>
            </div>
            <Select style={{ width: '90%' }}

              isMulti
              name="colors"
              className="basic-multi-select"
              classNamePrefix="select"
            />



            <button type="submit" class="btn btn-primary" style={{ width: '98%', borderRadius: '5px', marginTop: '20px', fontSize: '13px' }}>Add Device »</button>
          </form>
      </Modal.Body>
      <Modal.Footer style={{ paddingBottom: '20px' }}>
       
      </Modal.Footer>
    </Modal>
  );
}
export default EditObjectModal;

