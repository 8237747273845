import { createSlice } from "@reduxjs/toolkit";


//declaration of the initial state
const initialState = {
    isLoggedIn: false,
    loginData: {}
}


//creation of the login slice
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoginSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.loginData = action.payload;
        },
        setLoginFailed: (state, action) => {
            state.isLoggedIn = false;
            state.loginData = null; 
        }
    }
});



//exporting of the funcitons
export default loginSlice.reducer;
export const { setLoginSuccess, setLoginFailed } = loginSlice.actions;

