import React, { useState, useRef, useEffect } from 'react'
import "./style.css";
import { Card, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';


//importing of the icons from react icons
import { FcComments } from "react-icons/fc";
import { SlCloudUpload } from "react-icons/sl";
import { IoIosEye } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineCloudUpload } from "react-icons/md";
import { FcChargeBattery } from "react-icons/fc";
import { PiBatteryEmptyThin } from "react-icons/pi";
import { PiDoorLight } from "react-icons/pi";
import { LuFuel } from "react-icons/lu";
import { CiWifiOn } from "react-icons/ci";
import { GiVibratingSmartphone } from "react-icons/gi";
import { PiEngineLight } from "react-icons/pi";
import { GrVirtualMachine } from "react-icons/gr";
import { SiGoogleearthengine } from "react-icons/si";


import { TbCarSuv } from "react-icons/tb";
import { GiNetworkBars } from "react-icons/gi";
import { TfiTime } from "react-icons/tfi";
import { TbTriangles } from "react-icons/tb";
import { MdOutlineSensors } from "react-icons/md";
import { RxCountdownTimer } from "react-icons/rx";
import { FcServices } from "react-icons/fc";
import { MdOutlineInsertComment } from "react-icons/md";
import { MdInsights } from "react-icons/md";
import { RiOilLine } from "react-icons/ri";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { TbCircleDashedNumber9 } from "react-icons/tb";
import { TbKeyboard } from "react-icons/tb";
import { CiCreditCard2 } from "react-icons/ci";
import { TbBrandSpeedtest } from "react-icons/tb";
import { TfiTimer } from "react-icons/tfi";
import { MdBatteryCharging60 } from "react-icons/md";
import { IoMdBatteryCharging } from "react-icons/io";
import { IoArrowForwardCircle } from "react-icons/io5";





const DemoCard = (props) => {

    const vehicleData = useSelector(state => state.tracking.monitorData)
    const [selectedVehicleData, setSelectedVehicleData] = useState({})
    const [selectedVehicleSensors, setSelectedVehicleSensors] = useState({})

    useEffect(() => {

        const data = vehicleData?.filter(data => data.imei === props.selectedVehicleImei);
        if (data.length === 0) {
            setSelectedVehicleData(vehicleData[0])
        } else {
            setSelectedVehicleData(data[0])
        }
    }, [vehicleData])


    useEffect(() => {
        const sensors = selectedVehicleData?.sensors;
        // console.log('sensor is ', sensors)
        setSelectedVehicleSensors(sensors)
    }, [selectedVehicleData])


    const containerRef = useRef(null);
    const [showCluster, setShowCluster] = useState(false)

    const [showMonitorDataBar, setShowMonitorDataBar] = useState(false)

    const [isCardVisible, setIsCardVisible] = useState(true); // Initialize the card as visible


    const handleScroll = (e) => {
        const delta = Math.max(-1, Math.min(1, e.nativeEvent.wheelDelta || -e.nativeEvent.detail));
        containerRef.current.scrollLeft -= delta * 40;
        e.preventDefault();
    };
    const [cardVisible, setCardVisible] = useState(true);

    const handleCancelClick = () => {
        setCardVisible(false);
    };
    return (
        <div>
            <div className="" >
                <Button style={{ zIndex: '15' }} variant="" className={`  ${showCluster ? ' ' : ''}`} data-bs-toggle="tooltip" title="Collapse" onClick={props.setFullScreen}
                ><IoArrowForwardCircle style={{ marginLeft: '-32px', fontSize: '32px', zIndex: '10', }} className='text-primary' /></Button>


                <Card style={{ width: "80vw", display: "flex", overflowX: "hidden", height: '150px', marginBottom: '0px', borderRadius: '0px', overflowY: 'hidden' }} ref={containerRef}
                    onWheel={handleScroll}>
                    <button type="button" class="btn-close" aria-label="Close" style={{
                        position: 'absolute', right: '30px', marginTop: "10px"
                    }} onClick={handleCancelClick}></button>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', overflowX: 'scroll', minWidth: '255vw' }}>
                        <div className="card" style={{ width: '24rem', marginTop: '10px', zIndex: '10' }}>
                            <ul className="list-group list-group-flush">


                                <div className="card" style={{ width: '24rem', marginTop: '10px', zIndex: '10' }}>
                                    <ul className="list-group list-group-flush">
                                        {/* ignition sensor */}
                                        {selectedVehicleSensors?.acc && (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test">
                                                    <div className="p-1">
                                                        <PiEngineLight />{" "}
                                                        {selectedVehicleSensors?.acc?.name}
                                                    </div>
                                                    <div className="p-1">
                                                        {selectedVehicleSensors?.acc?.valueFull}
                                                    </div>
                                                </div>
                                            </li>
                                        )}

                                        {/* battery voltage */}
                                        {selectedVehicleSensors?.batt && (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test">
                                                    <div className="p-1">
                                                        <FcChargeBattery />{" "}
                                                        {selectedVehicleSensors?.batt?.name}
                                                    </div>
                                                    <div className="p-1">
                                                        {selectedVehicleSensors?.batt?.valueFull}
                                                    </div>
                                                </div>
                                            </li>
                                        )}

                                        {/* internal battery voltage */}
                                        {selectedVehicleSensors?.batt01 && (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test">
                                                    <div className="p-1">
                                                        <MdBatteryCharging60 />{" "}
                                                        {selectedVehicleSensors?.batt01?.name}
                                                    </div>
                                                    <div className="p-1">
                                                        {selectedVehicleSensors?.batt01?.valueFull}
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>



                                <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', }}>
                                    <ul className="list-group list-group-flush">

                                        {/* door sensor */}
                                        {selectedVehicleSensors?.di ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><PiDoorLight />{" "}{selectedVehicleSensors?.di?.name}
                                                    </div>
                                                    <div className="p-1" >{selectedVehicleSensors?.di?.valueFull}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {/* engine status */}
                                        {selectedVehicleSensors?.do ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><PiEngineLight />{" "}{selectedVehicleSensors?.do?.name}</div>
                                                    <div className="p-1" >{selectedVehicleSensors?.do?.valueFull}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {/* fuel level */}
                                        {selectedVehicleSensors?.fuel ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><LuFuel />{" "}{selectedVehicleSensors?.fuel?.name}</div>
                                                    <div className="p-1" >162.42 Liters</div>
                                                </div>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>


                                <div className="card" style={{ width: '26rem', marginTop: '10px', marginTop: '10px', }}>
                                    <ul className="list-group list-group-flush">
                                        {selectedVehicleSensors?.gps ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><CiWifiOn /> {" "}{selectedVehicleSensors?.gps?.name}</div>
                                                    <div className="p-1" >{selectedVehicleSensors?.gps?.valueFull}</div>
                                                </div>
                                            </li>
                                        ) : null}
                                        {selectedVehicleData?.location_data?.dt_tracker ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TfiTimer style={{ color: '' }} />{" "} Time (position)</div>
                                                    <div className="p-1" >{selectedVehicleData?.location_data?.dt_tracker}</div>
                                                </div>
                                            </li>
                                        ) : null}
                                        {selectedVehicleData?.location_data?.dt_server ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TfiTime />{" "}Time (server)</div>
                                                    <div className="p-1" >{selectedVehicleData?.location_data?.dt_server}</div>
                                                </div>
                                            </li>
                                        ) : null}


                                    </ul>
                                </div>

                                <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', }}>
                                    <ul className="list-group list-group-flush">

                                        {/* speed */}
                                        {selectedVehicleData?.location_data?.speed ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TbBrandSpeedtest />{" "}Speed</div>
                                                    <div className="p-1" >{selectedVehicleData?.location_data?.speed}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {/* odometer */}
                                        {selectedVehicleData?.odometer ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TbCircleDashedNumber9 style={{ color: 'green' }} />{" "} Odometer</div>
                                                    <div className="p-1" >{selectedVehicleData?.odometer}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {/* engine hour */}
                                        {selectedVehicleData?.engine_hours ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><SiGoogleearthengine />{" "}Engine work</div>
                                                    <div className="p-1" >{selectedVehicleData?.engine_hours}</div>
                                                </div>
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                                <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', }}>
                                    <ul className="list-group list-group-flush">

                                        {/* device model */}
                                        {selectedVehicleData?.protocol ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TbCarSuv />{" "} Device Model</div>
                                                    <div className="p-1" >{selectedVehicleData?.protocol}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {/* status */}
                                        {selectedVehicleData?.status ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><GiNetworkBars style={{ color: 'green' }} />{" "} Status</div>
                                                    <div className="p-1" >{selectedVehicleData?.status}</div>
                                                </div>
                                            </li>
                                        ) : null}

                                        {selectedVehicleData?.location_data?.angle ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TbTriangles />{" "}Angle</div>
                                                    <div className="p-1" >{selectedVehicleData?.location_data?.angle}<sup>o</sup></div>
                                                </div>
                                            </li>
                                        ) : null}


                                    </ul>
                                </div>
                                <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', }}>
                                    <ul className="list-group list-group-flush">

                                        {selectedVehicleData?.location_data?.altitude ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><GrVirtualMachine />{" "}Altitude</div>
                                                    <div className="p-1" >{selectedVehicleData?.location_data?.altitude}</div>
                                                </div>
                                            </li>
                                        ) : null}
                                        {selectedVehicleData?.location_data?.lat && selectedVehicleData?.location_data?.lng ? (
                                            <li className="list-group-item">
                                                <div className="d-flex flex-row test" >
                                                    <div className="p-1"><TbKeyboard />{" "}Position</div>
                                                    <div className="p-1" ><a href={`https://www.google.com/maps?q=${selectedVehicleData?.location_data?.lat},${selectedVehicleData?.location_data?.lng}`} target="_blank">Click here...</a></div>
                                                </div>
                                            </li>
                                        ) : null}



                                        {/* <li className="list-group-item">
                                    <div className="d-flex flex-row test" >
                                        <div className="p-1"><GiVibratingSmartphone />{" "}GSM Level
                                        </div>
                                        <div className="p-1" >75%</div>
                                    </div>
                                </li> */}


                                    </ul>
                                </div>
                            </ul>

                            {/* extra items */}
                            {/* <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', }}>
                            <ul className="list-group list-group-flush">

                                <li className="list-group-item">
                                    <div className="d-flex flex-row test" >
                                        <div className="p-1"><CiCreditCard2 />{" "}SIM card number</div>
                                        <div className="p-1" >5754170137998</div>
                                    </div>
                                </li>

                                <li className="list-group-item">
                                    <div className="d-flex flex-row test" >
                                        <div className="p-1"><PiEngineLight />{" "}Ignition</div>
                                        <div className="p-1" >Off</div>
                                    </div>
                                </li>
                                <li className="list-group-item">
                                    <div className="d-flex flex-row test" >
                                        <div className="p-1"><IoMdBatteryCharging />{" "}Device Battery</div>
                                        <div className="p-1" >Present</div>
                                    </div>
                                </li>



                            </ul>
                        </div> */}

                            {/* recent event */}
                            {/* <div className="card" style={{ width: '24rem', marginTop: '10px', marginTop: '10px', height: '110px', overflow: 'scroll' }}>

                            <div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1"><FcComments /> {" "}Recent Event</div>
                                            <div className="p-1" ></div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1">{" "}09:52:52 </div>
                                            <div className="p-1" >
                                                Zone in (Heywood)</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1">{" "}09:25:29
                                            </div>
                                            <div className="p-1" >Zone out (Preston)</div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1">{" "}08:55:08
                                            </div>
                                            <div className="p-1" >Zone in (Preston)</div>
                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div> */}


                            <div className="card" style={{ width: '20rem', marginTop: '10px', height: '90px', marginRight: '5px' }}>

                                <div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">

                                            <div className="d-flex flex-row bd-highlight mb-3">
                                                <div className="p-2 bd-highlight">upload file</div>
                                                <div className="p-2 bd-highlight"><IoIosEye title="View" style={{ marginLeft: '70px', fontSize: '20px' }} /></div>
                                                <div className="p-2 bd-highlight">{" "}<a href="#" ><AiOutlineDelete title="Delete" style={{ fontSize: '18px' }} /></a></div>
                                            </div>
                                        </li>

                                        <li className="list-group-item" style={{ marginTop: '-28px' }}>
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Document Name</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <button type="button" className="btn btn-primary" style={{ marginTop: '5px', fontSize: '12px' }}><MdOutlineCloudUpload />{" "}
                                                Upload File</button>

                                        </li>



                                    </ul>
                                </div>
                            </div>

                            {/* <div className="card" style={{ width: '13.5rem', marginTop: '10px', marginTop: '10px', height: '175px', marginRight: '10px' }}> */}

                            <div>
                                <ul className="list-group list-group-flush">
                                    {/* <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1">{" "}Notes
                                            </div>
                                            <div className="p-1" ></div>
                                        </div>
                                    </li> */}
                                    {/* <li className="list-group-item">
                                        <div className="d-flex flex-row test" >
                                            <div className="p-1">{" "}
                                                <textarea type="email" className="form-control" id="exampleFormControlInput1" />
                                                <br></br>
                                                
                                            </div>
                                            <div className="d-flex flex-row bd-highlight mb-3">
                                                <div className="p-2 bd-highlight"><input type="button" value="Save" className="btn btn-success" style={{ marginTop: '-40', marginLeft: '', fontSize: '12px', width: '80px' }} /></div>
                                                <div className="p-2 bd-highlight">  <input type="button" value="Cancel" className="btn btn-success" style={{ marginTop: '-20', marginLeft: '12px', fontSize: '12px', width: '80px' }} /> </div>

                                            </div>
                                        </div>

                                    </li> */}

                                </ul>

                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default DemoCard