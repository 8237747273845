import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination } from 'react-bootstrap';
import "./Style.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import MyVerticallyModal from './EditContactview';

const ObjectSetting = () => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [showContact, setShowContact] = useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      {
        optionSelected === 'objects' ? (
          <div>
            <div>
          
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title"></div>
                </Card.Header>
               
                  <div>
                    <div>
                      <div class="d-flex bd-highlight">
                        <div class="p-2 flex-grow-1 bd-highlight">
                          <span class="" style={{ marginLeft: '1.5%', marginTop: '30px', fontSize: '15px', marginLeft: '15px' }}>Contact Information
                            <hr style={{ marginTop: '8px', width: '100px', marginLeft:'20px' }}></hr>
                            <hr style={{ marginTop: '-10px', width: '100px',marginLeft:'20px' }}></hr></span>
                        </div>
                      </div>
                    </div>
                    <form lg="12" style={{ marginTop: '10px', marginLeft:'12px' }}>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Username</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}> Company</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px',marginLeft:'10px'  }}>E-mail</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px',  }}>Phone Number 1</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'10px' }}>Phone Number 2</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px', marginLeft:'10px' }}> County/State</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />

                          
                        </div>
                      </div>

                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>City</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'10px' }}>Post code</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px', marginLeft:'10px' }} />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'18px' }}>Address</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px', marginLeft:'18px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-12">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Comments</label>
                          <textarea type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',  }} />
                        </div>
                        
                      </div>
                      <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary" onClick={() => setShowContact(true)}>Edit</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div> 

                    </form>
                    
                    
               
                  
                </div>
        


          
          </div>


          </div>







  ) : (
    <h4>Unknown</h4>
  )
}


<MyVerticallyModal
    show={showContact}
     onHide={() => setShowContact(false)}
  />





    </div >
  )
}

export default ObjectSetting
