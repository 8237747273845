import React, { useEffect, useState } from 'react'
import { Table, Card } from "react-bootstrap";
import NewLoader from '../../speedo-asset/Loader';

// importing of the icons from react icons
import { TbRouteAltLeft } from "react-icons/tb";
import { TbRouteAltRight } from "react-icons/tb";
import { TbClockStop } from "react-icons/tb";
import { TbRouteSquare } from "react-icons/tb";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { TbRouteSquare2 } from "react-icons/tb";
import { IoSpeedometerOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { PiClockCounterClockwiseLight } from "react-icons/pi";
import { LuFuel } from "react-icons/lu";
import { BsFuelPump } from "react-icons/bs";
import { PiEngineBold } from "react-icons/pi";
import { SiGoogleearthengine } from "react-icons/si";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaTrailer } from "react-icons/fa";

//react scroll
import { animateScroll as scroll } from 'react-scroll';



const GeneralInformationMerged = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [generalMergedData, setGeneralMergedData] = useState({});


    const funcFetchGeneralInfoMerged = async () => {
        try {
            setIsLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("devices_imei", "350612076585973");
            urlencoded.append("data_items", "route_start,route_end,route_length,move_duration,stop_duration,stop_count,top_speed,avg_speed,overspeed_count,fuel_consumption,avg_fuel_consumption,fuel_cost,engine_work,engine_idle,odometer,engine_hours,driver,trailer,total");
            urlencoded.append("date_time_from", "2023-02-22 05:30:00");
            urlencoded.append("date_time_to", "2023-12-25 05:30:00");
            urlencoded.append("speed_limit", "90");
            urlencoded.append("stop_duration", "1");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            const response = await fetch("https://gps.speedotrack.com/api/reports/general-info-merged", requestOptions)
            const result = await response.json()
            setGeneralMergedData(result)

            console.log(result)
            setIsLoading(false)
            scroll.scrollTo(330);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        funcFetchGeneralInfoMerged()
    }, [])





    return (
        <div>
            {isLoading ? (
                <NewLoader />
            ) : (
                <div>

                    <div class="card">
                        <div class="card-body" style={{ minHeight: '40px' }}>
                            <div class="row">
                                <div class="col">
                                    <h6>General Information Merged -  Object:	Vehicle Name</h6>
                                </div>
                                <div class="col-md-auto">
                                    <h6>Period:	9023840923</h6>
                                </div>
                            </div>
                        </div>
                        <Card style={{ marginTop: '-18px' }}>
                            <Card.Body >
                                <div className="table-responsive" style={{ marginTop: '10px' }}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Object</th>
                                                <th>Route start</th>
                                                <th>Route end</th>
                                                <th>Route length</th>
                                                <th>Move duration</th>
                                                <th>Stop duration</th>
                                                <th>Stop count</th>
                                                <th>Overspeed count</th>
                                                <th>Fuel consumption</th>
                                                <th>Fuel consumption (Km/l)</th>
                                                <th>Fuel cost</th>
                                                <th>Odometer</th>
                                                <th>Engine work</th>
                                                <th>Engine idle</th>
                                                <th>Engine hours</th>
                                                <th>Driver</th>
                                                <th>Trailer</th>
                                                <th>Top Speed</th>
                                                <th>Avg Speed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {generalMergedData?.gen_info?.map((report, index) => (
                                                <tr>
                                                    <td style={{ marginLeft: '50px' }}>{report?.object_name}</td>
                                                    <td>{report?.route_start}</td>
                                                    <td>{report?.route_end}</td>
                                                    <td>{report?.route_length}</td>
                                                    <td>{report?.move_duration}</td>
                                                    <td>{report?.stop_duration}</td>
                                                    <td>{report?.stop_count}</td>
                                                    <td>{report?.overspeed_count}</td>
                                                    <td>{report?.fuel_consumption}</td>
                                                    <td>{report?.avg_fuel_consumption}</td>
                                                    <td>{report?.fuel_cost}</td>
                                                    <td>{report?.odometer}</td>
                                                    <td>{report?.engine_work}</td>
                                                    <td>{report?.engine_idle}</td>
                                                    <td>{report?.engine_hours}</td>
                                                    <td>{report?.driver}</td>
                                                    <td>{report?.trailer}</td>
                                                    <td>{report?.top_speed}</td>
                                                    <td>{report?.avg_speed}</td>

                                                </tr>
                                            ))}
                                            <tr>
                                                <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                                <td>2023-12-16 00:00:02</td>
                                                <td>2023-12-16 12:17:37</td>
                                                <td>14.37</td>
                                                <td>01:17</td>
                                                <td>11:00</td>
                                                <td>38</td>
                                                <td>0</td>
                                                <td>94.88</td>
                                                <td>0.15</td>
                                                <td>04:27</td>
                                                <td>03:09</td>
                                                <td>4877:05	</td>
                                                <td>4877:05	</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>

                                            </tr>

                                            <tr>
                                                <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                                                <td>2023-12-16 00:00:02</td>
                                                <td>2023-12-16 12:17:37</td>
                                                <td>14.37</td>
                                                <td>01:17</td>
                                                <td>11:00</td>
                                                <td>38</td>
                                                <td>0</td>
                                                <td>94.88</td>
                                                <td>0.15</td>
                                                <td>04:27</td>
                                                <td>03:09</td>
                                                <td>4877:05	</td>
                                                <td>4877:05	</td>
                                                <td>0</td>
                                                <td>0</td>
                                                <td>0</td>

                                            </tr>



                                        </tbody>
                                    </Table>
                                </div>


                            </Card.Body>
                        </Card>

                    </div>


                    <Card>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><TbRouteAltLeft />{" "}Route length</div>
                                                <div class=" bd-highlight">{generalMergedData?.total?.route_length}</div>
                                            </div>
                                        </li>
                                        <li class="list-group-item" >
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><LiaTruckMovingSolid />{" "}Move duration</div>
                                                <div class=" bd-highlight">{generalMergedData?.total?.move_duration}</div>
                                            </div></li>
                                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                            <div class=" bd-highlight "><AiOutlineStop />{" "}Stop duration</div>
                                            <div class=" bd-highlight">{generalMergedData?.total?.stop_duration}</div>
                                        </div></li>
                                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                            <div class=" bd-highlight "><BsSpeedometer2 />{" "}Odometer</div>
                                            <div class=" bd-highlight">{generalMergedData?.total?.odometer}</div>
                                        </div></li>
                                    </ul>
                                </div></div>
                                <div class="col-4" >
                                    <div class="card" >
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><TbRouteSquare />{" "}Stop Count</div>
                                                    <div class=" bd-highlight">{generalMergedData?.total?.stop_count}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item" >
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><TbRouteSquare2 />{" "}Overspeed Count</div>
                                                    <div class=" bd-highlight">{generalMergedData?.total?.overspeed_count}</div>
                                                </div></li>
                                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Fuel consumption</div>
                                                <div class=" bd-highlight">{generalMergedData?.total?.fuel_consumption}</div>
                                            </div></li>
                                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Engine hours</div>
                                                <div class=" bd-highlight">{generalMergedData?.total?.engine_hours}</div>
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="card" style={{ width: '27rem' }}>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><TbClockStop />{" "}Fuel cost</div>
                                                    <div class=" bd-highlight">{generalMergedData?.total?.fuel_cost}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item" >
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><IoSpeedometerOutline />{" "}Engine work</div>
                                                    <div class=" bd-highlight">{generalMergedData?.total?.engine_work}</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><LuFuel />{" "}Engine Idle</div>
                                                <div class=" bd-highlight">{generalMergedData?.total?.engine_idle}</div>
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><BsFuelPump />{" "}Avg. fuel cons. (100 km)</div>
                                                <div class=" bd-highlight">2023-12-16 00:00:04</div>
                                            </div>
                                        </li>
                                        <li class="list-group-item" >
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><LuFuel />{" "}Fuel cost</div>
                                                <div class=" bd-highlight">2023-12-16 00:00:04</div>
                                            </div></li>
                                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                            <div class=" bd-highlight "><SiGoogleearthengine />{" "}Engine work</div>
                                            <div class=" bd-highlight">6.91 km</div>
                                        </div></li>
                                    </ul>
                                </div></div>
                                <div class="col-4">
                                    <div class="card" style={{ width: '27rem' }}>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><PiEngineBold />{" "}Engine idle</div>
                                                    <div class=" bd-highlight">2023-12-16 10:45:03</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item" >
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><BsSpeedometer2 />{" "}Odometer</div>
                                                    <div class=" bd-highlight">6.91 km</div>
                                                </div></li>
                                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Engine hours</div>
                                                <div class=" bd-highlight">6.91 km</div>
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="card" style={{ width: '27rem' }}>
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><TbClockStop />{" "}Driver</div>
                                                    <div class=" bd-highlight">10 h 6 min 17 s</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item" >
                                                <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                    <div class=" bd-highlight "><FaTrailer />{" "}Trailer</div>
                                                    <div class=" bd-highlight">6.91 km</div>
                                                </div>
                                            </li>
                                            <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><LuFuel />{" "}Fuel consumption</div>
                                                <div class=" bd-highlight">6.91 km</div>
                                            </div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </Card>
                </div>
            )}
        </div>
    )
}

export default GeneralInformationMerged
