import React from 'react'
import { Card, Table, Modal, Button } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";



const Main = () => {
  return (
    <div>
      <div class="" style={{ marginTop: '15px', marginLeft: '15px' }}>
        <div class="card-body text-primary" style={{ minHeight: '40px' }}>
          <div class="row">
            <div class="col-sm">
              Event
            </div>
            <div class="col-sm">
              Parameters and sensors
            </div>
          </div>
        </div>
        <div class="row" style={{ marginTop: '' }}>
          <div class="col-sm-8">
            <div class="row align-items-start">
              <div class="col">
                Active
              </div>
              <div class="col">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ marginLeft: '-30%' }} />
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col" style={{ marginTop: '5px' }}>
                Name
              </div>
              <div class="col" >
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col" style={{ marginTop: '5px' }}>
                Type
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>SOS</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col" style={{ marginTop: '5px' }}>
                Depending on routes
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>Off</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col">
                Objects
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>Nothing selected</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col">
                Routes
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>Nothing selected</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col">
                Depending on zones
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>In selected zone</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col">
                zone
              </div>
              <div class="col" >
                <select class="form-select" aria-label="Default select example" style={{ marginLeft: '-30%', width: '70%' }}>
                  <option selected>Nothing selected</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col" style={{ marginTop: '5px' }}>
                Time period (min)
              </div>
              <div class="col" >
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
              </div>
            </div>
            <div class="row align-items-start" style={{ marginTop: '3px' }}>
              <div class="col" style={{ marginTop: '5px' }}>
                Speed limit (mph)
              </div>
              <div class="col" >
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '70%' }} />
              </div>
            </div>
          </div>
          <div class="col-sm-4" style={{ marginLeft: '-17%' }}>
            <div className="table-responsive" style={{ marginTop: '10px', minWidth: '500px', height: '335px' }}>
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2023-07-03 07:38:17</td>
                    <td>2023-07-03</td>
                    <td><IoIosClose style={{ fontSize: '15px', color: 'red', marginLeft: '12px' }} /></td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div class="card-footer d-flex">
              <select class="form-select" aria-label="Default select example" style={{ minWidth: '170px' }}>
                <option selected></option>
              </select>
              <select class="form-select" aria-label="Default select example" style={{ minWidth: '140px', marginLeft: '5px' }}>
                <option selected></option>
              </select>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ minWidth: '150px', marginLeft: '5px' }} />
              <button type="button" class="btn btn-primary btn-sm btn-block" style={{ marginLeft: '5px', width: '30%' }}><GoPlus />
              </button>
            </div>
          </div>

        </div>
      </div>
      <Modal.Footer>
                <Button type="button" class="btn btn-secondary">Cancel</Button>
                <Button type="button" >Save</Button>
            </Modal.Footer>

    </div>

  )
}

export default Main
