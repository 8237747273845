import React from 'react'
import { Table, Card } from "react-bootstrap";


const DrivesAndStops = () => {
    return (
        <div>
            <div>
                <div>
                    <div class="card">
                        <div class="card-body" style={{ minHeight: '40px' }}>
                            <div class="row">
                                <div class="col">
                                    <h6>Driver Behaviour Rag -  Object:	10H10-9400</h6>
                                </div>
                                <div class="col-md-auto">
                                    <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                                </div>
                            </div>
                        </div>
                        <Card style={{ marginTop: '-18px' }}>
                            <Card.Body >
                                <div className="table-responsive" style={{ marginTop: '10px' }}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th >Driver Object</th>
                                                <th  >Route length</th>
                                                <th>Overspeed duration</th>
                                                <th>Overspeed score</th>
                                                <th>Harsh acceleration count</th>
                                                <th>Harsh acceleration score</th>
                                                <th>Harsh braking count</th>
                                                <th>Harsh braking score</th>
                                                <th>Harsh cornering count</th>
                                                <th>Harsh cornering score</th>
                                                <th>RAG</th>
                                               

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                          
                                               


                                            </tr>

                                            <tr>
                                              


                                            </tr>

                                            <tr>
                                          



                                            </tr>



                                        </tbody>
                                    </Table>
                                </div>


                            </Card.Body>
                        </Card>

                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrivesAndStops
