import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function DeleteUsageModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
     
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontSize:'14px'}}>
        Delete User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      
        <p style={{color:'red',fontSize:'14px'}}>
         Are you sure you want to delete?
        </p>
      </Modal.Body>
      <Modal.Footer> 
        <Button variant='danger' onClick={props.onHide}  style={{fontSize:'14px'}}>Yes</Button>
        <Button variant='secondary' onClick={props.onHide}  style={{fontSize:'14px'}}>No</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteUsageModal;

