import React, { useState } from 'react';
import { Button, ButtonGroup, Card, Pagination, Table } from 'react-bootstrap';
import "./DeviceStyle.css"

//importing of the icons from react icons
import { FiEdit } from "react-icons/fi";
import { GrDuplicate } from "react-icons/gr";
import { AiOutlineClear } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAddBox } from "react-icons/md";
import { BiRefresh } from "react-icons/bi";
import { FcCheckmark } from "react-icons/fc";
import { TfiEye } from "react-icons/tfi";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import DeleteUsageModal from './DeleteUsageModal';




const SmsSetting = (props) => {
  const [optionSelected, setOptionSelected] = useState('objects');
  const [activePage, setActivePage] = useState('1')
  const [showDetails, setShowDetails] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [deleteUsage, setDeleteUsage] = useState(false);


  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };


  return (
    <div>

      {
        optionSelected === 'objects' ? (
          <div>
            <div>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title"></div>
                </Card.Header>
                <Card.Body className="p-0">
                  <div class="row " style={{ marginTop: '-10px', marginLeft: '4px', }}>

                  </div>

                  <div className="table-responsive" style={{ marginTop: '10px' }}>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th >Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Position</td>
                          <td>{props.deviceData?.device?.lat}, {props.deviceData?.device?.lng}</td>
                        </tr>
                        <tr>
                          <td>Speed</td>
                          <td>{props.deviceData?.device?.speed} km/h</td>
                        </tr>
                        <tr>
                          <td>Angle</td>
                          <td>{props.deviceData?.device?.angle}<sup>o</sup></td>
                        </tr>
                        <tr>
                          <td>Altitude</td>
                          <td>{props.deviceData?.device?.altitude} m</td>
                        </tr>
                        <tr>
                          <td>Time Position</td>
                          <td>{props.deviceData?.device?.dt_tracker}</td>
                        </tr>
                        <tr>
                          <td>Time Server</td>
                          <td>{props.deviceData?.device?.dt_tracker}</td>
                        </tr>
                        <tr>
                          <td>Odometer</td>
                          <td>{props.deviceData?.device?.odometer}</td>
                        </tr>
                        <tr>
                          <td>Parameters</td>
                          <td>{JSON.stringify(props.deviceData?.device?.params)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>


              </Card>
            </div>


          </div>







        ) : (
          <h4>Unknown</h4>
        )
      }

      <DeleteUsageModal
        show={deleteUsage}
        onHide={() => setDeleteUsage(false)}
      />



    </div>
  )
}

export default SmsSetting
