import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{marginLeft:'12%', }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form lg="12" style={{ marginTop: '10px', marginLeft:'12px' }}>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Username</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}> Company</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px',marginLeft:'10px'  }}>E-mail</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px',  }}>Phone Number 1</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'10px' }}>Phone Number 2</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px', marginLeft:'10px' }}> County/State</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',marginLeft:'10px' }} />

                          
                        </div>
                      </div>

                      <div class="form-row d-flex">
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>City</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                        </div>
                        
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'10px' }}>Post code</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px', marginLeft:'10px' }} />
                        </div>
                        <div class="form-group col-md-4">
                          <label for="inputEmail4" style={{ fontSize: '13px', marginLeft:'18px' }}>Address</label>
                          <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px', marginLeft:'18px' }} />
                        </div>
                      </div>
                      <div class="form-row d-flex">
                        <div class="form-group col-md-12">
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Comments</label>
                          <textarea type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px',  }} />
                        </div>
                        
                      </div>
                      <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary" >Save</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div> 

                    </form>
            
               
                
              
            </Modal.Body>

        </Modal>
    );
}



export default MyVerticallyModal;