import React from 'react'

const SingleGenerator = () => {
  return (
    <div>
      <div class="card">
          <div class="card-body" style={{ minHeight: "20px" }}>
            <div class="row">
              <div class="col">
                <h6>Single Generator -  Device:	10H10-9400</h6>
              </div>
              <div class="col-md-auto">
                <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
              </div>
            </div>
          </div>


        </div>

        <div class="card" style={{ marginTop:'-20px'}}>
          <div class="card-body text-center" style={{ minHeight: "100px" ,}}>
            <div class="row text-center">
           
            Nothing has been found on your request.

            </div>
          </div>


        </div>
    </div>
  )
}

export default SingleGenerator
