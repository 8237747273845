import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{marginLeft:'12%', }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <div class="row row-cols-2 g-3">
                <form lg="12" style={{ marginTop: '20px' }}>
                  <div class="form-row d-flex">
                    <div class="form-group col-md-6">
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>Active</label>
                      <select class="form-select" aria-label="Default select example"
                        style={{ width: '98%' }}

                      >
                        <option selected>Manager</option>
                        <option value="calibration">Calibration</option>

                      </select>                  </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}> Username</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />


                    </div>
                  </div>
                  <div class="form-row d-flex" >
                    <div class="form-group col-md-6" >
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>E-mail</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />

                    </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}>Password</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />


                    </div>
                  </div>

                  <div class="form-row d-flex" >
                    <div class="form-group col-md-6" >
                      <label for="inputEmail4" style={{ fontSize: '13px' }}>Privileges</label>
                      <input type="email" class="form-control" id="inputEmail4" placeholder="" style={{ borderRadius: '5px' }} />
                    </div>
                    <div class="form-group col-md-6" style={{ marginLeft: '8px' }}>
                      <label for="inputPassword4" style={{ fontSize: '13px' }}>Expiry on</label>
                      <input type="date" class="form-control" id="inputPassword4" placeholder="" style={{ borderRadius: '5px' }} />
                    </div>
                  </div>





                </form>
                <div class="col scroll">
                  <div class="scroll">
                    <form lg="12" style={{ marginTop: '4px' }}>
                      <div class="form-row d-flex ">
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>OSM Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select></div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}> Bing Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>


                        </div>
                      </div>


                      <div class="form-row d-flex" >
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Google Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>

                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}>Google Maps Street View </label>
                          <select class="form-select" aria-label="Default selGoogleect example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>


                        </div>
                      </div>

                      <div class="form-row d-flex" >
                        <div class="form-group col-md-4" style={{ marginLeft: "100px" }}>
                          <label for="inputEmail4" style={{ fontSize: '13px' }}>Mapbox Maps</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4" style={{ marginLeft: '8px' }}>
                          <label for="inputPassword4" style={{ fontSize: '13px' }}>Yandex Map</label>
                          <select class="form-select" aria-label="Default select example"
                            style={{ width: '98%' }}
                          >
                            <option selected>Yes</option>
                            <option value="calibration">No</option>
                          </select>

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="btn-group" style={{ float: 'right', marginTop: '20px' }}>
                  <button type="button" class="btn btn-primary" >Save</button>
                  <button type="button" class="btn btn-secondary" style={{ marginLeft: '5px' }}>Cancel</button>

                </div>
              </div>
            </Modal.Body>

        </Modal>
    );
}



export default MyVerticallyCenteredModal;