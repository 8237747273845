import React from 'react'

// auth
import ConfirmMail from '../views/dashboard/auth/confirm-mail'
import LockScreen from '../views/dashboard/auth/lock-screen'
import Forgot from '../views/dashboard/auth/Forgot';
import SignIn from '../views/dashboard/auth/sign-in'
import Register from '../views/dashboard/auth/Register';
// errors
import Error404 from '../views/dashboard/errors/error404'
import Error500 from '../views/dashboard/errors/error500'
import Maintenance from '../views/dashboard/errors/maintenance'

import Default from '../layouts/dashboard/default';



export const SimpleRouter = [

    {
        path: '/',
        element: <SignIn />
    },
    {
        path: 'register',
        element: <Register />
    },


    // {
    //     path: 'default/auth/confirm-mail',
    //     element: <ConfirmMail />
    // },
    // {
    //     path: 'default/auth/lock-screen',
    //     element: <LockScreen />
    // },
    {
        path: 'forgot',
        element: <Forgot />
    },
    {
        path: 'errors/error404',
        element: <Error404 />
    },
    // Utilities
    {
        path: 'errors/error500',
        element: <Error500 />
    },
    {
        path: 'errors/maintenance',
        element: <Maintenance />
    },
]