import React, { useState } from 'react'
import { Table, Modal, Button } from "react-bootstrap";
import { TfiPencil } from "react-icons/tfi";
import { TfiTrash } from "react-icons/tfi";
import { CiEraser } from "react-icons/ci";
import { FcCheckmark } from "react-icons/fc";
import { GoDiscussionDuplicate } from "react-icons/go";
import { FiPlus } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { TbSettingsCog } from "react-icons/tb";
import { IoIosClose } from "react-icons/io";
import { GoPlus } from "react-icons/go";

const Templates = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div class="card" style={{ marginTop: "-18px" }}>
        <div class="card-body">
          <div className="input-group rounded" >
            <div class="form-group has-search" style={{ marginTop: '25px', minWidth: '1190px', }}>
              <span class="fa fa-search form-control-feedback"></span>
              <input type="text" class="form-control" placeholder="Search" style={{ marginTop: '-15px' }} />
            </div>
            <Table striped hover size="sm">
              <thead>
                <tr>
                  <th style={{ paddingRight: '0px', paddingLeft: '10px' }}>S.No</th>
                  <th style={{ paddingRight: '0px', }} ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ marginLeft: '50px' }}>1</td>
                  <td ><input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                  <td>SpeedoTrack</td>
                  <td>352093084881159</td>
                  <td>
                    <a href="#editEmployeeModal" class="edit" data-toggle="modal" title="Duplicate" >
                      <GoDiscussionDuplicate style={{ fontSize: "15px" }} />
                    </a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Edit"  ><TfiPencil style={{ fontSize: "15px" }} /></a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Delete" ><TfiTrash style={{ fontSize: "15px" }} /></a>{" "}

                    <a href="#" class="delete" data-toggle="modal" title="Clear History"  ><CiEraser style={{ fontSize: "18px" }} /></a>{" "}


                  </td>
                </tr>



              </tbody>
            </Table>
          </div>
        </div>
        <div class="card-footer d-flex bg-light" style={{ padding: "2px" }}>
          <button type="button" class="btn btn-primary btn-sm btn-block" title="Add" onClick={handleShow}> <FiPlus style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Refresh"><HiOutlineRefresh style={{ fontSize: '15px' }} /> </button>
          <button type="button" class="btn btn- btn-sm btn-block" title="Action"><TbSettingsCog style={{ fontSize: '15px' }} /> </button>

        </div>

      </div>

      <Modal show={show} onHide={handleClose} animation={false} style={{ marginLeft: '-6%' }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '14px' }}>Template properties</Modal.Title>
        </Modal.Header>
        <Modal.Body><div class="container">

          <div class="" style={{ marginTop: '15px', marginLeft: '15px' }}>
            <div class="card-body text-primary" style={{ minHeight: '40px' }}>
              <div class="row">
                <div class="col-sm">
                  Template
                </div>
                <div class="col-sm" style={{ marginLeft: '130px' }}>
                  Variables
                </div>
              </div>
            </div>
            <div class="row" style={{ marginTop: '' }}>
              <div class="col-sm-8">
                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                  <div class="col" style={{ marginTop: '5px' }}>
                    Name
                  </div>
                  <div class="col" >
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '100%' }} />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                  <div class="col" style={{ marginTop: '5px' }}>
                    Description
                  </div>
                  <div class="col" >
                    <textarea type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '100%' }} />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                  <div class="col" style={{ marginTop: '5px' }}>
                    Subject
                  </div>
                  <div class="col" >
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '100%' }} />
                  </div>
                </div>
                <div class="row align-items-start" style={{ marginTop: '3px' }}>
                  <div class="col" style={{ marginTop: '5px' }}>
                    Message
                  </div>
                  <div class="col" >
                    <textarea type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style={{ marginLeft: '-30%', width: '100%' }} />
                  </div>
                </div>
              </div>
              <div class="col-sm-4" style={{ marginLeft: '-60px' }}>
                <div class="" style={{width: '18rem', height:'200px', overflow: 'scroll'}}>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">%NAME% - Object name</li>
                    <li class="list-group-item">%IMEI% - Object IMEI</li>
                    <li class="list-group-item">%EVENT% - Event name</li>
                    <li class="list-group-item">%ROUTE% - Route name</li>
                    <li class="list-group-item">%ZONE% - Zone name</li>
                    <li class="list-group-item">%LAT% - Position latitude</li>
                    <li class="list-group-item">%ROUTE% - Route name</li>
                    <li class="list-group-item">%ZONE% - Zone name</li>
                    <li class="list-group-item">%LAT% - Position latitude</li>


                  </ul>
                </div>

              </div>

            </div>
          </div>
        </div></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Templates
