import React, { useEffect, useState } from 'react'
import { Table, Card } from "react-bootstrap";

// importing of the icons from react icons
import { TbRouteAltLeft } from "react-icons/tb";
import { TbRouteAltRight } from "react-icons/tb";
import { TbClockStop } from "react-icons/tb";
import { TbRouteSquare } from "react-icons/tb";
import { LiaTruckMovingSolid } from "react-icons/lia";
import { TbRouteSquare2 } from "react-icons/tb";
import { IoSpeedometerOutline } from "react-icons/io5";
import { AiOutlineStop } from "react-icons/ai";
import { PiClockCounterClockwiseLight } from "react-icons/pi";
import { LuFuel } from "react-icons/lu";
import { BsFuelPump } from "react-icons/bs";
import { PiEngineBold } from "react-icons/pi";
import { SiGoogleearthengine } from "react-icons/si";
import { BsSpeedometer2 } from "react-icons/bs";
import { FaTrailer } from "react-icons/fa";

//react scroll
import { animateScroll as scroll } from 'react-scroll';

//importing of the loader component
import NewLoader from '../../speedo-asset/Loader';




const DrivesAndStops = () => {
  const [drivesAndStopsData, setDrivesAndStopsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const funcFetchDrivesAndStops = async () => {
    try {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("devices_imei", "350612076585973");
      urlencoded.append("date_time_from", "2023-02-22 05:30:00");
      urlencoded.append("date_time_to", "2023-12-22 06:00:00");
      urlencoded.append("stop_duration", "1");
      urlencoded.append("show_coordinates", "true");
      urlencoded.append("show_addresses", "false");
      urlencoded.append("zones_addresses", "false");
      urlencoded.append("data_items", "status,start,end,duration,move_duration,stop_duration,route_length,top_speed,avg_speed,fuel_consumption,avg_fuel_consumption,fuel_cost,engine_work,engine_idle,driver,trailer");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      const response = await fetch("https://gps.speedotrack.com/api/reports/drives-and-stops", requestOptions)
      const result = await response.json()
      console.log(result);
      setDrivesAndStopsData(result);
      setIsLoading(false);
      scroll.scrollTo(330);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    funcFetchDrivesAndStops()
  }, [])



  return (
    <div>
      {isLoading ? (
        <NewLoader />
      ) : (
        <div>
          {drivesAndStopsData?.map((report, index) => (
            <div class="card" key={index}>
              <div class="card-body" style={{ minHeight: '40px' }}>
                <div class="row">
                  <div class="col">
                    <h6>Drives And Stops -  Object:	{report?.object}</h6>
                  </div>
                  <div class="col-md-auto">
                    <h6>Period:	{report?.period}</h6>
                  </div>
                </div>
              </div>
              <Card style={{ marginTop: '-18px' }}>
                <Card.Body >
                  <div className="table-responsive" style={{ marginTop: '10px' }}>
                    <Table striped hover size="sm">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>Start</th>
                          <th>End</th>
                          <th>Duration</th>
                          <th>Position</th>
                          <th>Fuel Consumption</th>
                          <th>Fuel Cost</th>
                          <th>Engine Idle</th>
                          <th>Driver</th>
                          <th>Trailer</th>

                        </tr>
                      </thead>
                      <tbody>
                        {report?.object_status?.map((item, index) => (
                          <tr key={index}>
                            <td style={{ marginLeft: '50px' }}>{item?.status}</td>
                            <td>{item?.start}</td>
                            <td>{item?.end}</td>
                            <td>{item?.duration}</td>
                            <td>
                              {item?.stop_position ? (
                                <a href={`https://www.google.com/maps?q=${item?.stop_position?.lat},${item?.stop_position?.lng}`} target="_blank">Click here...</a>
                              ) : (
                                'n/a'
                              )}
                            </td>
                            <td>{item?.fuel_consumption}</td>
                            <td>{item?.fuel_cost}</td>
                            <td>{item?.engine_idle}</td>
                            <td>{item?.driver}</td>
                            <td>{item?.trailer}</td>


                          </tr>
                        ))}

                        <tr>
                          <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                          <td>2023-12-16 00:00:02</td>
                          <td>2023-12-16 12:17:37</td>
                          <td>14.37</td>
                          <td>01:17</td>
                          <td>11:00</td>
                          <td>38</td>
                          <td>0</td>
                          <td>11:00</td>



                        </tr>

                        <tr>
                          <td style={{ marginLeft: '50px' }}>14 (Hyva) 3-2686</td>
                          <td>2023-12-16 00:00:02</td>
                          <td>2023-12-16 12:17:37</td>
                          <td>14.37</td>
                          <td>01:17</td>
                          <td>11:00</td>
                          <td>38</td>
                          <td>0</td>
                          <td>11:00</td>



                        </tr>



                      </tbody>
                    </Table>
                  </div>


                </Card.Body>
              </Card>

              <div class="card-body">
                <div class="row">
                  <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><TbRouteAltLeft />{" "}Route start</div>
                          <div class=" bd-highlight">2023-12-16 00:00:04</div>
                        </div>
                      </li>
                      <li class="list-group-item" >
                        <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><LiaTruckMovingSolid />{" "}Move duration</div>
                          <div class=" bd-highlight">2023-12-16 00:00:04</div>
                        </div></li>
                      <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><AiOutlineStop />{" "}Stop count</div>
                        <div class=" bd-highlight">6.91 km</div>
                      </div></li>
                    </ul>
                  </div></div>
                  <div class="col-4" >
                    <div class="card" >
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbRouteSquare />{" "}Route end</div>
                            <div class=" bd-highlight">2023-12-16 10:45:03</div>
                          </div>
                        </li>
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbRouteSquare2 />{" "}Route length</div>
                            <div class=" bd-highlight">6.91 km</div>
                          </div></li>
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Overspeed count</div>
                          <div class=" bd-highlight">6.91 km</div>
                        </div></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbClockStop />{" "}Average speed</div>
                            <div class=" bd-highlight">10 h 6 min 17 s</div>
                          </div>
                        </li>
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><IoSpeedometerOutline />{" "}Top speed</div>
                            <div class=" bd-highlight">6.91 km</div>
                          </div>
                        </li>
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><LuFuel />{" "}Fuel consumption</div>
                          <div class=" bd-highlight">6.91 km</div>
                        </div></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4"><div class="card" style={{ width: '27rem' }}>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">
                        <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><BsFuelPump />{" "}Avg. fuel cons. (100 km)</div>
                          <div class=" bd-highlight">2023-12-16 00:00:04</div>
                        </div>
                      </li>
                      <li class="list-group-item" >
                        <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><LuFuel />{" "}Fuel cost</div>
                          <div class=" bd-highlight">2023-12-16 00:00:04</div>
                        </div></li>
                      <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                        <div class=" bd-highlight "><SiGoogleearthengine />{" "}Engine work</div>
                        <div class=" bd-highlight">6.91 km</div>
                      </div></li>
                    </ul>
                  </div></div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><PiEngineBold />{" "}Engine idle</div>
                            <div class=" bd-highlight">2023-12-16 10:45:03</div>
                          </div>
                        </li>
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><BsSpeedometer2 />{" "}Odometer</div>
                            <div class=" bd-highlight">6.91 km</div>
                          </div></li>
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Engine hours</div>
                          <div class=" bd-highlight">6.91 km</div>
                        </div></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card" style={{ width: '27rem' }}>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><TbClockStop />{" "}Driver</div>
                            <div class=" bd-highlight">10 h 6 min 17 s</div>
                          </div>
                        </li>
                        <li class="list-group-item" >
                          <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                            <div class=" bd-highlight "><FaTrailer />{" "}Trailer</div>
                            <div class=" bd-highlight">6.91 km</div>
                          </div>
                        </li>
                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                          <div class=" bd-highlight "><LuFuel />{" "}Fuel consumption</div>
                          <div class=" bd-highlight">6.91 km</div>
                        </div></li>
                      </ul>
                    </div>
                  </div>
                </div>


              </div>


            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DrivesAndStops
