import React from 'react'
import { Table, Card } from "react-bootstrap";
import { PiClockCounterClockwiseLight } from "react-icons/pi";
import { TbRouteSquare } from "react-icons/tb";
import { TbRouteSquare2 } from "react-icons/tb";



const TravelSheet = () => {
    return (
        <div>
            <div>
                <div>
                    <div class="card">
                        <div class="card-body" style={{ minHeight: '40px' }}>
                            <div class="row">
                                <div class="col">
                                    <h6>Travel Sheet -  Object:	10H10-9400</h6>
                                </div>
                                <div class="col-md-auto">
                                    <h6>Period:	2023-12-16 00:00:00 - 2023-12-17 00:00:00</h6>
                                </div>
                            </div>
                        </div>
                        <Card style={{ marginTop: '-18px' }}>
                            <Card.Body >
                                <div className="table-responsive" style={{ marginTop: '10px' }}>
                                    <Table striped hover size="sm">
                                        <thead>
                                            <tr>
                                                <th >Date</th>
                                                <th  >Duration</th>
                                                <th>Time</th>

                                                <th>position A</th>
                                                <th>position B</th>
                                                <th>Route Length</th>
                                                <th>Driver</th>



                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>18-12-2023 10:28:04 AM</td>
                                                <td>13min 48s</td>
                                                <td>23.756914, 90.354864</td>
                                                <td>	23.752801, 90.384205</td>
                                                <td>3.64 Km</td>
                                                <td>SpeedoTrack</td>




                                            </tr>

                                            <tr>



                                            </tr>

                                            <tr>




                                            </tr>



                                        </tbody>
                                    </Table>
                                </div>


                            </Card.Body>
                            
                        </Card>



                    </div>

                    <div class="col-4" >
                                <div class="card" >
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><TbRouteSquare />{" "}Route end</div>
                                                <div class=" bd-highlight">2023-12-16 10:45:03</div>
                                            </div>
                                        </li>
                                        <li class="list-group-item" >
                                            <div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                                <div class=" bd-highlight "><TbRouteSquare2 />{" "}Route length</div>
                                                <div class=" bd-highlight">6.91 km</div>
                                            </div></li>
                                        <li class="list-group-item"><div class="d-flex flex-row bd-highlight mb-3 justify-content-between">
                                            <div class=" bd-highlight "><PiClockCounterClockwiseLight />{" "}Overspeed count</div>
                                            <div class=" bd-highlight">6.91 km</div>
                                        </div></li>
                                    </ul>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    )
}

export default TravelSheet
