import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Main from '../Events/Main';
import Time from '../Events/Time';
import Notification from '../Events/Notification';
import ObjectControl from '../Events/ObjectControl';
import WebHook from '../Events/WebHook';


function EventsModal(props) {
    const [modalRoute, setModalRoute] = useState('');

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            style={{ marginLeft: '8%' }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '15px' }}>
                    Event properties
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="topnav">
                    <Link onClick={() => setModalRoute('')} class="active">Main</Link>
                    <Link onClick={(() => setModalRoute('TIME'))}>Time</Link>
                    <Link onClick={(() => setModalRoute('NOTIFICATION'))}>Notification</Link>
                    <Link onClick={(() => setModalRoute('WEBHOOK'))}>WebHook</Link>
                    <Link onClick={(() => setModalRoute('OBJECTCONTROL'))}>Object Control</Link>
                </div>
               

                {
                    modalRoute === 'TIME' ? (
                        <Time />
                    ) : modalRoute === 'NOTIFICATION' ? (
                        <Notification />
                    ) : modalRoute === 'OBJECTCONTROL' ? (
                        <ObjectControl />
                    ) : modalRoute === 'WEBHOOK' ? (
                        <WebHook />
                    ) : (
                        <Main />
                    )
                }

            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide} class="btn btn-secondary">Cancel</Button>
                <Button onClick={props.onHide}>Save</Button>
            </Modal.Footer> */}
        </Modal>
    );
}



export default EventsModal;