import React from 'react'
import { Modal, Button } from "react-bootstrap";


const WebHook = () => {
  return (
    <div>
     <div class="card-body text-primary" style={{ minHeight: '30px', marginLeft: '15px', marginTop: '15px' }}>
          Notifications
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Send webhook
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Webhook URL
          </div>
          <div class="col d-flex">
            <textarea class="" type="text" value="" id=""  placeholder="ex. http://full_address_here" style={{width:'80%', height:'100px'}}/> {" "}
          </div>
        </div>
        <Modal.Footer style={{ marginTop: '10px' }}>
          <Button type="button" class="btn btn-secondary">Cancel</Button>
          <Button type="button">Save</Button>
        </Modal.Footer>
    </div>
  )
}

export default WebHook
