import React, { useState } from 'react'
import Gprs from './Gprs';
import Templates from './Templates';
import Schedule from './Schedule';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Style.css'




const ObjectControl = () => {
    const [gprsRoute, setGprsRoute] = useState('');

    


    return (
        <>
            <div>
                <div className="card">
                    <div className="card-body" style={{ minHeight: '30px', width: '500px' }}>
                        <div className="topnav">
                            <Button variant="link" onClick={() => setGprsRoute('GPRS')} class="active" style={{
                                borderBottom: '3px solid green'
                            }}>Gprs</Button>
                            <Button variant="link" onClick={() => setGprsRoute('SCHEDULE')}>Schedule</Button>
                            <Button variant="link" onClick={() => setGprsRoute('TEMPLATES')}>Templates</Button>
                        </div>
                    </div>
                </div>

            </div >
            <div>
            </div>
            {
                gprsRoute === 'GPRS' ? (
                    <Gprs />
                ) : gprsRoute === 'SCHEDULE' ? (
                    <Schedule />
                ) : gprsRoute === 'TEMPLATES' ? (
                    <Templates />
                ) : (
                    <Gprs />
                )
            }
        </>
    )
}

export default ObjectControl
