import React from 'react'
import { Modal, Button } from "react-bootstrap";


const Notification = () => {
  return (
    <div>
      <div>
        <div class="card-body text-primary" style={{ minHeight: '30px', marginLeft: '15px', marginTop: '15px' }}>
          Notifications
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
            System message
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Auto hide
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Push notification
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "15px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Sound alert
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
            <select class="form-select" aria-label="Default select example" style={{ width: '28%', marginLeft: '10px' }}>
              <option selected>00:00</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>
                <Button type="button" class="btn btn-secondary" style={{marginLeft:'8px', width:'20%'}}>Play</Button>
          </div>


        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Message to e-mail, for multiple e-mails separate them by comma
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"  style={{marginLeft:'10px', width: '57.8%'}}/>
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          SMS to mobile phone, for multiple phone numbers separate them by comma
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter phone number with code "  style={{marginLeft:'10px', width: '57.8%'}}/>
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          E-mail template
          </div>
          <div class="col d-flex">
            <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '24px' }}>
              <option selected>Default</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          SMS template
          </div>
          <div class="col d-flex">
            <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '24px' }}>
              <option selected>Default</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>
          </div>
        </div>
        <div class="card-body text-primary" style={{ minHeight: '30px', marginLeft: '15px', marginTop: '15px' }}>
          Colors
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Object arrow color
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
            <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '10px' }}>
              <option selected>Yellow</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Object list color
          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
            <input type="color" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"  style={{marginLeft:'10px', width: '20%'}}/>
           
          </div>
        </div>
       
        <Modal.Footer style={{ marginTop: '10px' }}>
          <Button type="button" class="btn btn-secondary">Cancel</Button>
          <Button type="button">Save</Button>
        </Modal.Footer>
      </div>
    </div>
  )
}

export default Notification
