import React from 'react'
import { Modal, Button } from "react-bootstrap";


const ObjectControl = () => {
  return (
    <div>
      <div class="card-body text-primary" style={{ minHeight: '30px', marginLeft: '15px', marginTop: '15px' }}>
      Object control
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Send command          </div>
          <div class="col d-flex">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> {" "}
          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "10px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Template
          </div>
          <div class="col d-flex">
          <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '' }}>
              <option selected>Custom</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Gateway
          </div>
          <div class="col d-flex">
          <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '' }}>
              <option selected>GPRS</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Type
          </div>
          <div class="col d-flex">
          <select class="form-select" aria-label="Default select example" style={{ width: '58%', marginLeft: '' }}>
              <option selected>ASCII</option>
              <option value="1">00:15</option>
              <option value="2">00:30</option>
              <option value="3">00:45</option>
              <option value="3">01:00</option>
            </select>          </div>
        </div>
        <div class="row align-items-start" style={{ marginTop: "5px" }}>
          <div class="col" style={{ marginLeft: '20px' }}>
          Command
          </div>
          <div class="col d-flex">
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"  style={{marginLeft:'', width: '57.8%'}}/>
         
             </div>
        </div>
        <Modal.Footer style={{ marginTop: '10px' }}>
          <Button type="button" class="btn btn-secondary">Cancel</Button>
          <Button type="button">Save</Button>
        </Modal.Footer>
    </div>

  )
}

export default ObjectControl
